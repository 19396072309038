import { Injectable } from '@angular/core';
import { PagesService } from './pages.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private pagesService: PagesService) {}

}
