import {NgBlockUI} from 'ng-block-ui';
import {EndpointsMerge} from '../common/endpointsmerge';
import {ApiService, Hosts, Response, UtilsToken} from 'merge-common-ng';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IVenda} from '../../pages/meu-negocio/vendas-a-receber/models/extrato-vendas';
import {HttpParams} from '@angular/common/http';
import {
  VolumeVendasOperadoraModel
} from '../../pages/meu-negocio/vendas-a-receber/models/volume-vendas-operadora.model';
import {
  ListaModalidadeAdquirenteGraficoModel
} from '../../pages/meu-negocio/vendas-a-receber/models/lista-modalidade-adquirente-grafico.model';
import {IGraficosRecebimentosDespesas} from '../common/interfaces/graficos-recebimentos-despesas';
import {ContaCorrenteDto} from '../../pages/extrato/components/extrato-conta-corrente/interfaces/conta-corrente-dto';
import {
  DadosRespostaExtratoLancamentoContaCorrenteDTO,
  LancamentoExtratoDto,
} from '../../pages/extrato/components/extrato-conta-corrente/interfaces/lancamento-extrato-dto';
import {
  IExtratoTitulosCobranca
} from '../../pages/meu-negocio/recebimentos/components/titulos-cobranca/interfaces/extrato-titulos-cobranca';
import {IExtratoPix} from '../../pages/meu-negocio/recebimentos/components/pix/interfaces/extrato-pix';
import {
  ContaEmpresarialDto
} from '../../pages/extrato/components/extrato-cartao-credito/interfaces/conta-empresarial-dto';
import {
  DetalhamentoVendaModel
} from '../../pages/cartoes/minhas-vendas/components/modal-detalhe-venda/model/detalhamento-venda.model';
import { IExtratoOrdens } from '../../pages/meu-negocio/recebimentos/components/ordens-bancarias/interfaces/extrato-ordens-bancarias';
import { DadosTarifaDto } from '../../pages/meu-negocio/despesas/models/dados-tarifa-dto';
import { PixOutputDTO } from '../../pages/meu-negocio/despesas/models/pix-output-dto';
import { DadosOperacaoCreditoDto } from '../../pages/meu-negocio/despesas/models/dados-operacao-credito-dto';
import { DadosTedDocDto } from '../../pages/meu-negocio/despesas/models/dados-ted-doc-tdo';
import { ITransferenciaTedDoc } from '../common/interfaces/transferencia-ted-doc';
import { ITituloCobranca } from '../../pages/meu-negocio/recebimentos/components/titulos-cobranca/interfaces/titulo-cobranca';
@Injectable({
  providedIn: 'root',
})
export class PagesService {
  constructor(public apiService: ApiService) {}

  getListaSaldos(ngBlockUI?: NgBlockUI): any {
    return this.apiService.get(
      EndpointsMerge.CONSULTA_SALDO,
      null,
      ngBlockUI,
      true,
      Hosts.HOST_ITEBB
    );
  }

  getListarContasCorrentesOPB(
    ngBlockUI?: NgBlockUI
  ): Observable<Response<ContaCorrenteDto[]>> {
    return this.apiService.get(
      EndpointsMerge.CONSULTA_CONTAS_EXTERNAS,
      null,
      ngBlockUI,
      true,
      Hosts.HOST_OPF
    );
  }

  getListaSaldoContasExternas(ngBlockUI?: NgBlockUI): any {
    return this.apiService.get(
      EndpointsMerge.CONSULTA_SALDOS_CONTAS_EXTERNAS,
      null,
      ngBlockUI,
      true,
      Hosts.HOST_OPF
    );
  }

  getListaDespesasCartaoCredito(params, ngBlockUi?: NgBlockUI): any {
    return this.apiService.get(
      EndpointsMerge.LISTAR_DESPESAS_CARTOES_CREDITO,
      params,
      ngBlockUi,
      true,
      Hosts.HOST_OPF
    );
  }

  getListaDespesasEmprestimos(params, ngBlockUI?: NgBlockUI): any {
    return this.apiService.get(
      EndpointsMerge.LISTAR_DESPESAS_EMPRESTIMOS,
      params,
      ngBlockUI,
      true,
      Hosts.HOST_OPF
    );
  }

  getListaDespesasFinanciamentos(params, ngBlockUi?: NgBlockUI): any {
    return this.apiService.get(
      EndpointsMerge.LISTAR_DESPESAS_FINANCIAMENTOS,
      params,
      ngBlockUi,
      true,
      Hosts.HOST_OPF
    );
  }

  getListaVendasRealizadasCartao(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IVenda>> {
    return this.apiService.post(
      EndpointsMerge.LISTAR_VENDAS_REALIZADAS_CARTAO,
      params,
      null,
      ngBlockUI
    );
  }

  getListaVendasRealizadasPix(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IVenda>> {
    return this.apiService.post(
      EndpointsMerge.LISTAR_VENDAS_REALIZADAS_PIX,
      params,
      null,
      ngBlockUI
    );
  }

  getListaRecebimentosCartao(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IVenda>> {
    return this.apiService.post(
      EndpointsMerge.LISTAR_RECEBIMENTOS_CARTAO,
      params,
      null,
      ngBlockUI
    );
  }
  getListaRecebimentosPix(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IVenda>> {
    return this.apiService.post(
      EndpointsMerge.LISTAR_RECEBIMENTOS_PIX,
      params,
      null,
      ngBlockUI
    );
  }

  getListaPrevisaoRecebimentos(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IVenda>> {
    return this.apiService.post(
      EndpointsMerge.LISTAR_PREVISAO_RECEBIMENTOS,
      params,
      null,
      ngBlockUI
    );
  }

  getDetalheVendaExtrato(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<DetalhamentoVendaModel>> {
    return this.apiService.get(
      EndpointsMerge.DETALHE_VENDAS_EXTRATO,
      params,
      ngBlockUI
    );
  }

  setConciliarVendas(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IVenda>> {
    return this.apiService.post(
      EndpointsMerge.CONCILIAR_VENDAS,
      params,
      null,
      ngBlockUI
    );
  }

  getListaAdquerentes(ngBlockUI?: NgBlockUI): any {
    return this.apiService.get(
      EndpointsMerge.CONSULTA_ADQUIRENTE,
      null,
      ngBlockUI
    );
  }

  getConsultaDadosAdquirente(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<VolumeVendasOperadoraModel[]>> {
    return this.apiService.post(
      EndpointsMerge.CONSULTA_DADOS_GRAFICO_ADQUIRENTE,
      params,
      null,
      ngBlockUI
    );
  }
  getConsultaVolumeVendasAdquirente(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<VolumeVendasOperadoraModel[]>> {
    return this.apiService.post(
      EndpointsMerge.CONSULTA_VOLUME_VENDAS_ADQUIRENTE,
      params,
      null,
      ngBlockUI
    );
  }

  getConsultaModalidadeAdquirente(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<ListaModalidadeAdquirenteGraficoModel[]>> {
    return this.apiService.post(
      EndpointsMerge.CONSULTA_MODALIDADE_GRAFICO_ADQUIRENTE,
      params,
      null,
      ngBlockUI
    );
  }

  getExtratoPix(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IExtratoPix>> {
    return this.apiService.get(EndpointsMerge.EXTRATO_PIX, params, ngBlockUI);
  }

  getVendasSintetizadasComparativoMes(params): any {
    return this.apiService.get(EndpointsMerge.VENDAS_COMPARATIVO_MES, params);
  }

  getMaioresTaxasTipo(params): any {
    return this.apiService.get(EndpointsMerge.MAIORES_TAXAS_TIPO, params);
  }

  getListaBoletos(params, ngBlockUI?: NgBlockUI): any {
    return this.apiService.post(
      EndpointsMerge.LISTA_BOLETOS,
      params,
      UtilsToken.getHeadersToken(),
      ngBlockUI
    );
  }

  getListaLancamentosCartao(body): any {
    return this.apiService.post(
      EndpointsMerge.CONSULTA_LANCAMENTOS_CARTAO,
      body,
      undefined,
      null,
      Hosts.HOST_ITEBB
    );
  }

  getListaLancamentosEmprestimo(body): any {
    return this.apiService.post(
      EndpointsMerge.CONSULTA_LANCAMENTOS_EMPRESTIMOS,
      body,
      undefined,
      null,
      Hosts.HOST_ITEBB
    );
  }

  getListaLancamentosCartaoPeriodo(body, ngBlockUI?: NgBlockUI): any {
    return this.apiService.post(
      EndpointsMerge.CONSULTA_LANCAMENTOS_CARTAO_PERIODO,
      body,
      undefined,
      ngBlockUI,
      Hosts.HOST_ITEBB
    );
  }

  getListaLancamentosEmprestimoPeriodo(body, ngBlockUI?: NgBlockUI): any {
    return this.apiService.post(
      EndpointsMerge.CONSULTA_LANCAMENTOS_EMPRESTIMOS_PERIODO,
      body,
      undefined,
      ngBlockUI,
      Hosts.HOST_ITEBB
    );
  }

  getResumoDeVendasCartao(params, ngBlockUI?: NgBlockUI): any {
    return this.apiService.post(
      EndpointsMerge.VENDAS_REALIZADAS_CARTAO,
      params,
      null,
      ngBlockUI
    );
  }

  getResumoDeVendasPix(params, ngBlockUI?: NgBlockUI): any {
    return this.apiService.post(
      EndpointsMerge.VENDAS_REALIZADAS_PIX,
      params,
      null,
      ngBlockUI
    );
  }

  getResultadosFluxoCaixa(params: HttpParams): any {
    return this.apiService.get(EndpointsMerge.RESULTADO_FLUXO_CAIXA, params);
  }

  getConsultaGraficoRecebimentosPeriodo(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IGraficosRecebimentosDespesas>> {
    return this.apiService.get(
      EndpointsMerge.CONSULTA_GRAFICO_RECEBIMENTOS_PERIODO,
      params,
      ngBlockUI
    );
  }

  getConsultaGraficoDespesasPeriodo(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IGraficosRecebimentosDespesas>> {
    return this.apiService.get(
      EndpointsMerge.CONSULTA_GRAFICO_DESPESAS_PERIODO,
      params,
      ngBlockUI
    );
  }

  listarMovimentacoes(params: any): any {
    return this.apiService.get(EndpointsMerge.CONSULTA_MOVIMENTACOES, params);
  }

  getConsultaGraficoFluxoCaixa(params: any, ngBlockUI?: NgBlockUI) {
    return this.apiService.get(
      EndpointsMerge.CONSULTA_GRAFICO_FLUXO_CAIXA,
      params,
      ngBlockUI
    );
  }

  atualizarContas() {
    return this.apiService.post(EndpointsMerge.ATUALIZA_CONTA_CORRENTE);
  }

  getConsultarTermosUsuario(): any {
    return this.apiService.post(EndpointsMerge.CONSULTA_TERMOS_USUARIO);
  }

  setAceiteTermosUsuario(params): any {
    return this.apiService.post(EndpointsMerge.ACEITE_TERMOS_USUARIO, params);
  }

  enviarAvaliacao(params, ngBlockUI: NgBlockUI): any {
    return this.apiService.postOptions(
      EndpointsMerge.PESQUISA_SATISFACAO_USUARIO,
      params,
      UtilsToken.getHeadersToken(),
      false,
      Hosts.HOST_API,
      ngBlockUI
    );
  }

  getListarContasCorrentesBB(
    ngBlockUI?: NgBlockUI
  ): Observable<Response<ContaCorrenteDto[]>> {
    return this.apiService.get(
      EndpointsMerge.LISTAR_CONTAS_POR_EMPRESA_LOGADA,
      null,
      ngBlockUI,
      true,
      Hosts.HOST_ITEBB
    );
  }

  getListaContasEmpresariais(
    ngBlockUI?: NgBlockUI
  ): Observable<Response<ContaEmpresarialDto>> {
    return this.apiService.get(
      EndpointsMerge.LISTAR_CARTAO_EMPRESA_LOGADA,
      null,
      ngBlockUI
    );
  }

  getListarTitulosCobranca(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IExtratoTitulosCobranca>> {
    return this.apiService.get(
      EndpointsMerge.EXTRATO_TITULOS_COBRANCA,
      params,
      ngBlockUI,
      true,
      Hosts.HOST_ITEBB
    );
  }

  getListarTitulosCobrancaEmAberto(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<ITituloCobranca[]>> {
    return this.apiService.get(
      EndpointsMerge.TITULOS_COBRANCA_EM_ABERTO,
      params,
      ngBlockUI,
      true,
      Hosts.HOST_ITEBB
    );
  }

  registrarEmpresaConciliadora(): Observable<Response<LancamentoExtratoDto[]>> {
    return this.apiService.get(EndpointsMerge.REGISTRAR_EMPRESA_CONCILIADORA);
  }

  getListarExtratoPeriodoBB(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<DadosRespostaExtratoLancamentoContaCorrenteDTO>> {
    return this.apiService.post(
      EndpointsMerge.LISTA_EXTRATO_PERIODO_BB,
      params,
      null,
      ngBlockUI,
      Hosts.HOST_ITEBB
    );
  }

  getListarExtratoPeriodoOPB(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<DadosRespostaExtratoLancamentoContaCorrenteDTO>> {
    return this.apiService.get(
      EndpointsMerge.LISTA_EXTRATO_PERIODO_OPB,
      params,
      ngBlockUI,
      true,
      Hosts.HOST_OPF
    );
  }
  getExtratoOrdens(
    body,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IExtratoOrdens>> {
    return this.apiService.post(
      EndpointsMerge.EXTRATO_ORDENS_BANCARIAS,
      body,
      undefined,
      ngBlockUI,
      Hosts.HOST_ITEBB);
  }
  listarTarifas(body, ngBlockUI?: NgBlockUI): Observable<Response<DadosTarifaDto>> {
    return this.apiService.post(
      EndpointsMerge.LISTAR_TARIFAS,
      body,
      null,
      ngBlockUI,
      Hosts.HOST_ITEBB
    );
  }
  getListaPixPorTipo(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<PixOutputDTO>> {
    return this.apiService.post(
      EndpointsMerge.PIX_CONSULTAR_POR_TIPO,
      params,
      null,
      ngBlockUI
    );
  }
  getListaOperacoesCreditoNoPeriodo(
    params: any,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<DadosOperacaoCreditoDto>> {
    return this.apiService.get(
      EndpointsMerge.LISTAR_OPERACOES_CREDITO_PERIODO,
      params,
      ngBlockUI,
      true,
      Hosts.HOST_OPF
    );
  }
  listarDespesasTedDoc(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<DadosTedDocDto>> {
    return this.apiService.get(
      EndpointsMerge.LISTAR_TED_DOC_DESPESAS,
      params,
      ngBlockUI
    );
  }
  getListaDespesasCartaoCreditoOPF(params, ngBlockUi?: NgBlockUI): any {
    return this.apiService.get(
      EndpointsMerge.LISTAR_DESPESAS_CARTOES_CREDITO_PERIODO,
      params,
      ngBlockUi,
      true,
      Hosts.HOST_OPF
    );
  }

  getListaDespesasCartaoCreditoITE(params, ngBlockUi?: NgBlockUI): any {
    return this.apiService.post(
      EndpointsMerge.CONSULTA_DESPESAS_CARTAO_PERIODO,
      params,
      null,
      ngBlockUi,
      Hosts.HOST_ITEBB
    );
  }
  listarTransferenciasRecebidas(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<ITransferenciaTedDoc[]>> {
    return this.apiService.get(
      EndpointsMerge.LISTAR_TED_DOC_RECEBIDOS,
      params,
      ngBlockUI
    );
  }
}
