import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ListaModalidadeAdquirenteGraficoModel } from '../../../../meu-negocio/vendas-a-receber/models/lista-modalidade-adquirente-grafico.model';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { PagesService } from '../../../../../shared/services/pages.service';
import { AdquirentesModel } from '../../../../meu-negocio/vendas-a-receber/models/adquirentes.model';
import { VolumeVendasOperadoraModel } from '../../../../meu-negocio/vendas-a-receber/models/volume-vendas-operadora.model';
import { IFiltroDatasSemRegra } from '../../../../../shared/common/interfaces/filtro-datas-sem-regra';
import { AbasMinhasVendasEnum } from '../../model/abas-minhas-vendas.enum';

@Component({
  selector: 'app-grafico-volume-vendas',
  templateUrl: './grafico-volume-vendas.component.html',
  styleUrls: ['./grafico-volume-vendas.component.css'],
})
export class GraficoVolumeVendasComponent implements OnChanges {
  adquirentes: AdquirentesModel[] = new Array<AdquirentesModel>();
  adquirenteSelected: AdquirentesModel;
  listaVolumeVendas: VolumeVendasOperadoraModel[] =
    new Array<VolumeVendasOperadoraModel>();
  params: any;

  @Input() estabelecimento: Empresa;
  @Input() filtroDatas: IFiltroDatasSemRegra;
  @Input() datesChanged: boolean;
  @Input() modalidades: Array<any>;
  @Input() modalidadeVendaSelecionada: AbasMinhasVendasEnum;
  abasMinhasVendasEnum = AbasMinhasVendasEnum;
  @BlockUI('bui-grafico-volume-vendas')
  buiGraficoVolumeVendas: NgBlockUI;

  modalidadesAdquirentes = new Array<ListaModalidadeAdquirenteGraficoModel>();

  hightChartsGraficoVolumeVendas: any;
  chartOptionsGraficoVolumeVendas: any;
  apresentaGrafico: boolean;
  tipoVenda: number;
  tipoVendaSelected: number = null;

  constructor(
    private pagesService: PagesService,
  ) {
    this.hightChartsGraficoVolumeVendas = Highcharts;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getListaAdquerentes();
  }

  getListaAdquerentes() {
    this.pagesService
      .getListaAdquerentes(this.buiGraficoVolumeVendas)
      .subscribe((result) => {
        this.adquirentes = result.dados;
        if (this.adquirentes.length > 0) {
          this.adquirenteSelected = this.adquirentes[0];
          this.adquirentes[0].botao = true;
          if (this.modalidadeVendaSelecionada !== AbasMinhasVendasEnum.PIX){
            this.getConsultaModalidadeAdquirente();
          }
          this.montarParametrosConsultaGrafico(
            this.adquirenteSelected.id,
            null
          );
        }
      });
  }

  selecionaAdquirente(adquirente: AdquirentesModel) {
    this.adquirentes.forEach((ad) => {
      if (ad.botao) {
        ad.botao = false;
      }
      if (ad.id === adquirente.id) {
        ad.botao = true;
      }
    });

    this.adquirenteSelected = adquirente;
    if (this.modalidadeVendaSelecionada !== AbasMinhasVendasEnum.PIX){
      this.getConsultaModalidadeAdquirente();
    }
  }

  selecionaTipoVenda() {
    this.montarParametrosConsultaGrafico(
      this.adquirenteSelected.id,
      this.tipoVendaSelected
    );
  }

  getConsultaModalidadeAdquirente() {
    this.modalidadesAdquirentes = [];

    const params = {
      dataInicio: this.filtroDatas.dataInicial.toLocaleDateString('pt'),
      dataFinal: this.filtroDatas.dataFinal.toLocaleDateString('pt'),
      idAdquirente: this.adquirenteSelected.id,
      tipoVenda: null,
    };
    this.pagesService
      .getConsultaModalidadeAdquirente(params, this.buiGraficoVolumeVendas)
      .subscribe((result) => {
        this.modalidadesAdquirentes = result.dados;
        if (this.modalidadeVendaSelecionada !== AbasMinhasVendasEnum.PIX){
          this.modalidadesAdquirentes = this.modalidadesAdquirentes.filter((item) => item.codigo !== 7);
        }
        if (this.modalidadesAdquirentes.length > 0) {
          this.tipoVendaSelected = this.modalidadesAdquirentes[0]?.codigo;
        } else {
          this.tipoVendaSelected = null;
        }
        this.montarParametrosConsultaGrafico(this.adquirenteSelected.id, this.tipoVendaSelected);
      });
  }

  montarParametrosConsultaGrafico(idAdquirente: number, tipoVenda: number) {
    this.params = {
      dataInicio: this.filtroDatas.dataInicial.toLocaleDateString('pt'),
      dataFinal: this.filtroDatas.dataFinal.toLocaleDateString('pt'),
      idAdquirente,
      tipoVenda,
    };
    if (this.modalidadeVendaSelecionada === AbasMinhasVendasEnum.PIX){
      return this.getConsultaVolumeVendasAdquirente();
    }
    this.getConsultaDadosAdquirente();
  }

  getConsultaDadosAdquirente() {
    this.apresentaGrafico = false;
    this.listaVolumeVendas = [];
    this.pagesService
      .getConsultaDadosAdquirente(this.params, this.buiGraficoVolumeVendas)
      .subscribe(
        (result) => {
          this.apresentaGrafico = true;
          if (result.dados.length > 0) {
            this.listaVolumeVendas = result.dados;
            for (let i = 0; i < result.dados.length; i++) {
              this.listaVolumeVendas[i].name =
                this.listaVolumeVendas[i].bandeira +
                ' ' +
                this.listaVolumeVendas[i].percentualVendas +
                '%';
              this.listaVolumeVendas[i].y =
                this.listaVolumeVendas[i].valorTotalBandeira;
              switch (this.listaVolumeVendas[i].bandeira) {
                case 'AMEX':
                  this.listaVolumeVendas[i].color = '#54DCFC';
                  this.listaVolumeVendas[i].icone = 'button-amex';
                  break;
                case 'ELO':
                  this.listaVolumeVendas[i].color = '#000000';
                  this.listaVolumeVendas[i].icone = 'button-elo';
                  break;
                case 'VISA':
                  this.listaVolumeVendas[i].color = '#465EFF';
                  this.listaVolumeVendas[i].icone = 'button-visa';
                  break;
                case 'MASTER':
                  this.listaVolumeVendas[i].color = '#FF7723';
                  this.listaVolumeVendas[i].icone = 'button-master-card';
                  break;
                case 'HIPERCARD':
                  this.listaVolumeVendas[i].color = '#F97A70';
                  this.listaVolumeVendas[i].icone = 'button-hipercard';
                  break;
                case 'OUTROS':
                  this.listaVolumeVendas[i].color = '#155724';
                  this.listaVolumeVendas[i].icone = 'button-outros';
                  break;
              }
            }
            this.createGraficoVolumeVendas();
          } else {
            this.apresentaGrafico = false;
          }
        },
        (error) => {
          console.error(error);
          this.apresentaGrafico = false;
        }
      );
  }


  getConsultaVolumeVendasAdquirente() {
    this.apresentaGrafico = false;
    this.listaVolumeVendas = [];
    this.pagesService
      .getConsultaVolumeVendasAdquirente(this.params, this.buiGraficoVolumeVendas)
      .subscribe(
        (result) => {
          this.apresentaGrafico = true;
          if (result.dados.length > 0) {
            this.listaVolumeVendas = result.dados;
            for (let i = 0; i < result.dados.length; i++) {
              this.listaVolumeVendas[i].name =
                this.listaVolumeVendas[i].adquirente;
              this.listaVolumeVendas[i].y =
                this.listaVolumeVendas[i].valorTotalBandeira;
              switch (this.listaVolumeVendas[i].adquirente) {
                case 'CIELO':
                  this.listaVolumeVendas[i].color = '#54DCFC';
                  this.listaVolumeVendas[i].icone = 'button-amex';
                  break;
                case 'GETNET':
                  this.listaVolumeVendas[i].color = '#c82215';
                  this.listaVolumeVendas[i].icone = 'button-visa';
                  break;
                case 'REDE':
                  this.listaVolumeVendas[i].color = '#FF7723';
                  this.listaVolumeVendas[i].icone = 'button-master-card';
                  break;
                case 'IFOOD':
                  this.listaVolumeVendas[i].color = '#F97A70';
                  this.listaVolumeVendas[i].icone = 'button-hipercard';
                  break;
                case 'STONE':
                  this.listaVolumeVendas[i].color = '#155724';
                  this.listaVolumeVendas[i].icone = 'button-outros';
                  break;
              }
            }
            this.createGraficoVolumeVendas();
          } else {
            this.apresentaGrafico = false;
          }
        },
        (error) => {
          console.error(error);
          this.apresentaGrafico = false;
        }
      );
  }


  createGraficoVolumeVendas() {
    this.chartOptionsGraficoVolumeVendas = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        width: 250,
        height: 250,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      tooltip: {
        // pointFormat: '{series.name}: <strong>{point.percentage:.1f}%</strong>'
      },
      accessibility: {
        /*point: {
          valueSuffix: '%'
        }*/
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
        },
      },
      series: [
        {
          name: 'R$',
          colorByPoint: true,
          innerSize: '40%',
          data: this.listaVolumeVendas,
        },
      ],
    };
  }
}
