import { DataTableModule } from './../../../../../shared/common/components/data-table/data-table.module';
import { BlockUIModule } from 'ng-block-ui';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TedDocComponent } from './ted-doc.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ComponentsCommonModule } from '../../../../../shared/common/components-commom.module';
import { FormsModule } from '@angular/forms';
import { TedDocRoutingModule } from './ted-doc-routing.module';
import { NavbarRecebimentosModule } from '../navbar-recebimentos/navbar-recebimentos.module';
import { DatepickerModuleEnum } from 'merge-common-ng';

@NgModule({
  declarations: [TedDocComponent],
  exports: [TedDocComponent],
  imports: [
    CommonModule,
    TedDocRoutingModule,
    NgxPaginationModule,
    ComponentsCommonModule,
    FormsModule,
    BlockUIModule.forRoot(),
    NavbarRecebimentosModule,
    DatepickerModuleEnum,
    DataTableModule,
  ],
})
export class TedDocModule {}
