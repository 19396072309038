import { BlockUIModule } from 'ng-block-ui';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConciliacaoVendasAtualizarComponent } from './conciliacao-vendas-atualizar.component';

@NgModule({
  declarations: [ConciliacaoVendasAtualizarComponent],
  imports: [CommonModule, BlockUIModule.forRoot()],
  exports: [ConciliacaoVendasAtualizarComponent],
})
export class ConciliacaoVendasAtualizarModule {}
