import {Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';

@Component({
  selector: 'app-extrato-fatura-tabela',
  templateUrl: './extrato-fatura-tabela.component.html',
  styleUrls: ['./extrato-fatura-tabela.component.css']
})
export class ExtratoFaturaTabelaComponent implements OnInit, OnChanges {

  @Input() dadosExtrato: Array<DadosTabela>;
  @ViewChild('extratoFaturaContainer') containerExtrato;
  index: number;
  lastPage: number;
  extratoPage: Array<DadosTabela> = [];
  sum: number;
  carregandoItns: boolean;
  constructor() {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    this.carregarItems();
  }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.index = 0;
    this.lastPage = 20;
    this.carregandoItns = false;
    this.extratoPage = [];
    this._mockDados();
  }

  private _mockDados() {
    this.extratoPage = this.extratoPage.concat(this.proximaPaginaExtrato(this.index, this.lastPage));
  }
  carregarItems() {
    const {
      scrollTop,
      scrollHeight,
      clientHeight
    } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      this.carregandoItns = true;
      setInterval(() => {
        this.index = this.lastPage;
        this.lastPage += 20;
        const l = this.proximaPaginaExtrato(this.index, this.lastPage);
        this.carregandoItns = l.length > 0;
        this.extratoPage = this.extratoPage.concat(l);
        this.carregandoItns = false;
      }, 5000);
    }
  }

  proximaPaginaExtrato(first: number, end: number): DadosTabela[] {
    return this.dadosExtrato.slice(first, end);
  }
  capitalizeFirstLetter(string) {
    return string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }

  format(value: string, formatType: number) {
    if (formatType === 1) {
      const d = value.split('.');
      return `${d[0]}/${d[1]}/${d[2]}`;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this._mockDados();
  }
}
export interface DadosTabela {
  dataLancamento: string;
  textoLancamento: string;
  valorLancamento: BigInteger | number;
  numeroPlastico: number;
  valorSaldoAnterior: BigInteger | number;
  valorSaldoTotal: BigInteger | number;
}
