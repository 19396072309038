import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraficoFluxoCaixaComponent } from './grafico-fluxo-caixa.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MaisInfoModule } from '../mais-info/mais-info.module';

@NgModule({
  declarations: [GraficoFluxoCaixaComponent],
  imports: [CommonModule, HighchartsChartModule, MaisInfoModule],
  exports: [GraficoFluxoCaixaComponent],
})
export class GraficoFluxoCaixaModule {}
