import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginDevComponent} from './login-dev.component';
import {LoginDevRoutingModule} from './login-dev-routing.module';

@NgModule({
  declarations: [LoginDevComponent],
  imports: [CommonModule, LoginDevRoutingModule],
  exports: [LoginDevComponent],
})
export class LoginDevModule {}
