import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ExtratoComponent } from './extrato.component';
import { ExtratoRoutingModule } from './extrato-routing.module';
import { ExtratoContaCorrenteModule } from './components/extrato-conta-corrente/extrato-conta-corrente.module';
import { ExtratoCartaoCreditoModule } from './components/extrato-cartao-credito/extrato-cartao-credito.module';

@NgModule({
  declarations: [ExtratoComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ExtratoRoutingModule,
    ExtratoContaCorrenteModule,
    ExtratoCartaoCreditoModule,
  ],
  providers: [],
  exports: [ExtratoComponent],
})
export class ExtratoModule {}
