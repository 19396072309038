import {
  AlignEnum,
  FormatEnum,
  TypeEnum,
} from './../../../../../shared/common/components/data-table/interfaces/column-detail';
import {ColumnDetail} from 'src/app/merge/shared/common/components/data-table/interfaces/column-detail';
import {HttpParams} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {DataInputComRegra, Empresa} from 'merge-common-ng';
import moment from 'moment';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ToastrService} from 'ngx-toastr';

import {IFiltroDatas} from './../../../../../shared/common/interfaces/filtro-datas';
import {TedDocService} from './../../../../../shared/services/ted-doc.service';
import {TimestampBehaviourService} from './../../../../../shared/services/timestamp-behaviour.service';
import {TemplateService} from './../../../../template/template.service';
import {DadosTedDocDto} from './../../models/dados-ted-doc-tdo';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-ted-doc',
  templateUrl: './ted-doc.component.html',
  styleUrls: ['./ted-doc.component.css'],
})
export class TedDocComponent implements OnInit {
  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  dataSelecionada: DataInputComRegra;
  dadosTedDoc: DadosTedDocDto;

  @BlockUI('bui-despesas-ted-doc') buiDepesasTedDoc: NgBlockUI;

  defaultDataSort = 'data';
  columnsDetails: ColumnDetail[] = [
    {
      dataKey: 'data',
      header: 'DATA',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'banco',
      header: 'BANCO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'recebedor',
      header: 'RECEBEDOR',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'cpfCnpj',
      header: 'CPF / CNPJ',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'numeroDocumento',
      header: 'Nº DOCUMENTO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'agencia/conta',
      header: 'AGÊNCIA / CONTA',
      type: TypeEnum.AGENCIA_CONTA,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'valor',
      header: 'VALOR',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.CURRENCYBR,
    },
  ];

  constructor(
    private tedDocService: TedDocService,
    private mensagemService: ToastrService,
    private templateService: TemplateService,
    private timestampService: TimestampBehaviourService,
    private datePipe: DatePipe
  ) {
    this.dadosTedDoc = new DadosTedDocDto();
    this.dadosTedDoc.listaLancamento = [];
  }

  ngOnInit(): void {
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this._setFiltroDatas(data));

    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));

    this.timestampService.updateRegraCalendario();
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);
    this.timestampService.getCalendarioSubject().subscribe((date) => {
      this.dataSelecionada = date;
    });
    this._carregarDespesasTedDoc();
  }

  private _carregarDespesasTedDoc(): void {
    this.tedDocService
      .listarDespesas(this._parametrosPesquisaTedDoc(), this.buiDepesasTedDoc)
      .subscribe(
        (resposta) => {
          this.dadosTedDoc = resposta.dados;
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  private _setFiltroDatas(data: DataInputComRegra): void {
    this.dataSelecionada = data;
    this.filtroDatas = this.timestampService.translateDateForInput(data);
  }

  private _parametrosPesquisaTedDoc(): HttpParams {
    const dataInicial = moment(this.filtroDatas.dataInicial).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const dataFinal = moment(this.filtroDatas.dataFinal).set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    return new HttpParams()
      .set('dataInicial', dataInicial?.format())
      .set('dataFinal', dataFinal?.format());
  }
}
