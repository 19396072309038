import { LoginDevModule } from './pages/login/login-dev/login-dev.module';
import { ConfiguracoesModule } from './pages/configuracoes/configuracoes.module';
import { LoginApjModule } from './pages/login/login-apj/login-apj.module';
import { AgendaCartoesModule } from './pages/meu-negocio/vendas-a-receber/components/agenda-cartoes/agenda-cartoes.module';

import {
  ApiService,
  AuthGuard,
  CustomDatepickerI18n,
  DatepickerModuleEnum,
  HighchartsChartCustomModule,
  I18n,
  interceptorProviders,
  MergeCommonNgModule,
  NgbDatePTParserFormatter,
  NgbdDatepickerRangeComponent,
  RoleGuard,
  SharedModule,
} from 'merge-common-ng';

import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { BlockUIModule } from 'ng-block-ui';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecebimentosModule } from './pages/meu-negocio/recebimentos/recebimentos.module';
import { VendasAReceberModule } from './pages/meu-negocio/vendas-a-receber/vendas-a-receber.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ComponentsCommonModule } from './shared/common/components-commom.module';
import { LoginModule } from './pages/login/login.module';
import { TemplateModule } from './pages/template/template.module';
import { VisaoGeralModule } from './pages/visao-geral/visao-geral.module';
import { DespesasModule } from './pages/meu-negocio/despesas/despesas.module';
import { FluxoCaixaModule } from './pages/meu-negocio/fluxo-de-caixa/fluxo-caixa.module';
import { RelatoriosModule } from './pages/relatorios/relatorios.module';
import { ServicosModule } from './pages/servicos/servicos.module';
import { MarketplaceModule } from './pages/marketplace/marketplace.module';
import { MaisInfoModule } from './shared/common/components/mais-info/mais-info.module';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { ExtratoModule } from './pages/extrato/extrato.module';
import { AutorizacoesModule } from './pages/autorizacoes/autorizacoes.module';
import { MatChipsModule } from '@angular/material/chips';

registerLocaleData(localePt);
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    LoginModule,
    LoginApjModule,
    LoginDevModule,
    TemplateModule,
    VisaoGeralModule,
    DespesasModule,
    RecebimentosModule,
    VendasAReceberModule,
    FluxoCaixaModule,
    RelatoriosModule,
    ServicosModule,
    ConfiguracoesModule,
    MarketplaceModule,
    MaisInfoModule,
    AgendaCartoesModule,
    ExtratoModule,
    AutorizacoesModule,
    SharedModule,
    MatChipsModule,
    NgbModule,
    ComponentsCommonModule,
    MergeCommonNgModule.forRoot(environment),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    HighchartsChartCustomModule.forRoot(),
    BlockUIModule.forRoot(),
    NgxMaskModule.forRoot(options),
    DatepickerModuleEnum.forRoot(),
    ToastrModule.forRoot({
      autoDismiss: true,
      timeOut: 5000,
      preventDuplicates: true,
      newestOnTop: true,
    }),
    NgbModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxMatomoTrackerModule.forRoot({
      trackerUrl: environment.matomoURL,
      siteId: environment.matomoSiteId,
      enableLinkTracking: true,
    }),
    NgxMatomoRouterModule,
  ],
  providers: [
    RoleGuard,
    AuthGuard,
    ApiService,
    [interceptorProviders],
    { provide: LOCALE_ID, useValue: 'pt' },
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
    [{ provide: NgbDatePTParserFormatter, useClass: NgbDatePTParserFormatter }],
    DatePipe,
    NgbdDatepickerRangeComponent,
    Title,
  ],
  bootstrap: [AppComponent],
  schemas: [],
})
export class AppModule { }
