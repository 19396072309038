import {PagesService} from './pages.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AtualizadorContaCorrenteService {
  constructor(private pageService: PagesService) {}

  atualizarContas() {
    /*Unimed CNU*/
    this.pageService.atualizarContas().subscribe((data: any) => {});
  }

}
