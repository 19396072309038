import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contaCorrente',
})
export class ContaCorrentePipe implements PipeTransform {
  transform(value: number | string): string {
    try {
      if (value === undefined) {
        return '';
      }

      value = String(value).padStart(6, '0');
      const digito = value.substr(-1);
      const numeroConta = value.substring(0, value.length - 1);
      return `${numeroConta}-${digito}`;
    } catch (error) {
      console.error(error);
      return String(value);
    }
  }
}
