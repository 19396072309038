import {Injectable} from '@angular/core';
import {ApiService, Hosts} from 'gnc-merge-common-ng/dist/merge-common-ng';
import {NgBlockUI} from 'ng-block-ui';
import {EndpointsMerge} from '../common/endpointsmerge';

@Injectable({
  providedIn: 'root',
})
export class CartaoService {
  constructor(public apiService: ApiService) {}

  getListaDespesasCartaoCreditoOPF(params, ngBlockUi?: NgBlockUI): any {
    return this.apiService.get(
      EndpointsMerge.LISTAR_DESPESAS_CARTOES_CREDITO_PERIODO,
      params,
      ngBlockUi,
      true,
      Hosts.HOST_OPF
    );
  }

  getListaDespesasCartaoCreditoITE(params, ngBlockUi?: NgBlockUI): any {
    return this.apiService.post(
      EndpointsMerge.CONSULTA_DESPESAS_CARTAO_PERIODO,
      params,
      null,
      ngBlockUi,
      Hosts.HOST_ITEBB
    );
  }
}
