import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TitulosCobrancaComponent } from './titulos-cobranca.component';

const routes: Routes = [
  {
    path: '',
    component: TitulosCobrancaComponent,
    data: { title: 'Meu Negócio - Recebimentos - Boleto' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TitulosCobrancaRoutingModule {}
