import {Component, Input, OnInit} from '@angular/core';
import {ListaFaturasFechadas} from '../../interfaces/consultar-faturas-fechadas-output-dto';
import {ExtratoFaturaCartaoService} from '../../../../../../shared/services/extrato-fatura-cartao.service';
import {ConsultarExtratoFaturaInputDTO} from '../../interfaces/consultar-extrato-fatura-input-dto';
import {ListaContasCartoesEmpresariasDTO} from '../../interfaces/lista-carta-empresarial-dto';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ConsultarExtratoFaturaOutputDTO} from '../../interfaces/consultar-extrato-fatura-output-dto';
import {DadosTabela} from '../extrato-fatura-tabela/extrato-fatura-tabela.component';

@Component({
  selector: 'app-extrato-fatura-fechadas',
  templateUrl: './extrato-fatura-fechadas.component.html',
  styleUrls: ['./extrato-fatura-fechadas.component.css']
})
export class ExtratoFaturaFechadasComponent implements OnInit {

  @Input() dadosFaturas: ListaFaturasFechadas;
  @Input() dadosCartao: ListaContasCartoesEmpresariasDTO;
  extratoFatura: ConsultarExtratoFaturaOutputDTO;
  @BlockUI('bui-lista-extrato') buiListaExtrato: NgBlockUI;
  dadosExtrato: Array<DadosTabela> = [];

  constructor(private extratoFaturaService: ExtratoFaturaCartaoService) { }

  ngOnInit(): void {
    this._readFaturas();
  }

  format(value: string, formatType: number = 1) {
    if (formatType === 1) {
      const d = value.split('.');
      return `${d[0]}/${d[1]}/${d[2]}`;
    }
  }

  private _readFaturas() {
    const params: ConsultarExtratoFaturaInputDTO = {
        numeroContaCartao: this.dadosCartao.numeroContaCartao,
        numeroSequencialFatura: this.dadosFaturas.numeroSequencialFatura
    };
    this.extratoFaturaService.consultarExtratoFaturasCartoesEmpresariais(params, this.buiListaExtrato)
      .subscribe((response) => {
        if (response && response.dados) {
          this.extratoFatura = response.dados;
          for (const transacao of this.extratoFatura.listaTransacao) {
            const d: DadosTabela = {
              dataLancamento: transacao.dataTransacao,
              numeroPlastico: transacao.numeroPlastico,
              textoLancamento: transacao.textoTransacao,
              valorLancamento: transacao.valorMoedaOrigem,
              valorSaldoAnterior: this.extratoFatura.valorSaldoAnterior,
              valorSaldoTotal: this.extratoFatura.valorSaldoTotal
            };
            this.dadosExtrato.push(d);
          }
        }
      });
  }


}
