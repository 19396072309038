import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oferta-cartao-usuario',
  templateUrl: './oferta-cartao-usuario.component.html',
  styleUrls: ['./oferta-cartao-usuario.component.css'],
})
export class OfertaCartaoUsuarioComponent implements OnInit {
  cards: Array<Cards> = [];
  cardsCartao: Array<Cartao> = [];

  constructor() {}

  ngOnInit(): void {
    this.cards = [
      {
        urlImg: 'assets/imagens/publi-cartão-bb/icon-empresas.png',
        title: 'BB Relaciona Empresas',
        body: 'Acumule pontos com as suas compras. Eles podem ser transferidos para programas de relacionamento ou trocados em outras vantagens.',
      },
      {
        urlImg: 'assets/imagens/publi-cartão-bb/icon-descontos.png',
        title: 'Descontos progressivos',
        body: 'Quanto mais você usa o seu Ourocard, maior é o desconto na anuidade do seu cartão.',
      },
      {
        urlImg: 'assets/imagens/publi-cartão-bb/icon-praticidade.png',
        title: 'Praticidade',
        body: 'Cartões com função de crédito e débito para facilitar a movimentação bancária e a gestão financeira da sua empresa.',
      },
      {
        urlImg: 'assets/imagens/publi-cartão-bb/icon-financeira.png',
        title: 'Gestão financeira',
        body: 'Com Ourocard e Banco do Brasil, você consulta as compras e movimentações pela internet, app ou nos terminais de autoatendimento.',
      },
      {
        urlImg: 'assets/imagens/publi-cartão-bb/icon-mundo.png',
        title: 'Em todo o mundo',
        body: 'O Banco do Brasil oferece opções de cartões aceitas internacionalmente, com vantagens exclusivas para sua empresa.',
      },
      {
        urlImg: 'assets/imagens/publi-cartão-bb/icon-prazos.png',
        title: 'Prazos maiores',
        body: 'Alguns cartões oferecem a possibilidade de pagar suas obrigações em até 40 dias sem juros, a depender do vencimento da fatura, e carência de 6 meses para cobrança de anuidade.',
      },
      {
        urlImg: 'assets/imagens/publi-cartão-bb/icon-flexibilidade.png',
        title: 'Flexibilidade',
        body: 'O cartão Ourocard Empresarial Elo Grafite possui benefícios flexíveis que você pode escolher de acordo com as necessidades da sua empresa.',
      },
      {
        urlImg: 'assets/imagens/publi-cartão-bb/icon-viagens.png',
        title: 'Gestão de viagens',
        body: 'O cartão Pamcard oferece gestão das viagens contratadas, com relatórios gerenciais completos.',
      },
    ];
    this.cardsCartao = [
      {
        card: {
          urlImg: 'assets/imagens/publi-cartão-bb/cartao-visa.png',
          body: 'Funções de crédito e débito e movimentação bancária da empresa',
          title: 'Ourocard Empresarial Visa',
        },
        link: 'https://www.bb.com.br/ourocardempresarialvisa',
      },
      {
        card: {
          urlImg: 'assets/imagens/publi-cartão-bb/cartao-elo.png',
          body: 'Cartão com funções crédito e débito, além de permitir saques e transferências bancárias e ser aceito internacionalmente',
          title: 'Ourocard Empresarial Elo',
        },
        link: 'https://www.bb.com.br/site/pro-seu-negocio/cartoes/ourocard-empresarial-elo/',
      },
    ];
  }

  irParaCartao(link: string) {
    window.open(link, '_blank').focus();
  }
}

export class Cards {
  urlImg: string;
  title: string;
  body: string;
}

export class Cartao {
  card: Cards;
  link: string;
}
