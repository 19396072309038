import {Pipe, PipeTransform} from '@angular/core';
import {IFiltroExtratoContas} from './filtro-extrato-contas';

@Pipe({
  name: 'filterExtratoContas',
  pure: false,
})
export class FilterExtratoContas implements PipeTransform {
  transform(items: any[], filter: IFiltroExtratoContas): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item) => {
      return (
        (!!!item.nome || item.nome === filter.nome) &&
        (!!!item.valorNumericoTexto ||
          item.valorNumericoTexto.search(filter.nsu) !== -1 ||
          !!!item.valorNumerico ||
          item.valorNumerico.toString().search(filter.nsu.replace(',', '.')) !==
            -1)
      );
    });
  }
}
