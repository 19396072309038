import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthBBService, Empresa, User, UtilsApp, UtilsToken, } from 'gnc-merge-common-ng/dist/merge-common-ng';
import {AtualizadorContaCorrenteService} from 'src/app/merge/shared/services/atualizador-conta-corrente.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-login-dev',
  templateUrl: './login-dev.component.html',
})
export class LoginDevComponent implements OnInit {
  public version;

  constructor(
    private router: Router,
    private authServiceBB: AuthBBService,
    private activatedRoute: ActivatedRoute,
    private atualizadorContaCorrenteService: AtualizadorContaCorrenteService
  ) {}

  ngOnInit(): void {
    this.version = environment.VERSION;

    if (environment.bbClusterKubernetes === 'PROD') {
      this.router.navigate(['login']);
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      const chave = params['chave'];
      const senha = this.tratarSenha(params['senha']);
      this.login(chave, senha);
    });
  }

  private tratarSenha(senha: string) {
    const senhaPadrao = 'homo1111';

    if (senha == null || (senha == null && senha.length === 0)) {
      return senhaPadrao;
    }

    return senha;
  }

  public login(chave: string, senha: string) {
    this.authServiceBB.loginDev(chave, senha).subscribe(
      (data) => {
        this.loginSuccess(data);
      },
      (error) => {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    );
  }

  public loginSuccess(data: any) {
    localStorage.clear();
    localStorage.setItem(
      UtilsToken.KEY_BB_ACCESS_TOKEN,
      data.tokenBB.access_token
    );
    localStorage.setItem(
      UtilsToken.KEY_BB_REFRESH_TOKEN,
      data.tokenBB.refresh_token
    );
    localStorage.setItem(
      UtilsToken.KEY_ACCESS_TOKEN,
      data.tokenPainel.access_token
    );
    localStorage.setItem(
      UtilsToken.KEY_REFRESH_TOKEN,
      data.tokenPainel.refresh_token
    );

    const user: User = data.usuario;
    user.empresas = new Array<Empresa>();
    user.empresas.push(data.empresa);
    localStorage.setItem(
      UtilsApp.KEY_CURRENT_USER,
      JSON.stringify(data.usuario)
    );
    localStorage.setItem(
      UtilsApp.KEY_CURRENT_EMPRESA,
      JSON.stringify(data.empresa)
    );

    this.atualizadorContaCorrenteService.atualizarContas();
    this.router.navigate(['pages']);
  }
}
