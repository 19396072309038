import { CNPJPipe } from './../../pipes/cnpj.pipe';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import * as XLSX from 'xlsx-js-style';
import { Router } from '@angular/router';
import { IFiltroDatas } from 'src/app/merge/shared/common/interfaces/filtro-datas';
import { TimestampBehaviourService } from 'src/app/merge/shared/services/timestamp-behaviour.service';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { TemplateService } from 'src/app/merge/pages/template/template.service';

import { ColumnDetail, TypeEnum } from './interfaces/column-detail';

interface IResumoPaginacao {
  de: number;
  ate: number;
  total: number;
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css'],
})
export class DataTableComponent implements OnInit {
  @Output() dataEventEmitter = new EventEmitter<any>();

  @Input() dataSource: any[] = new Array();
  @Input() columnsDetails: ColumnDetail[];
  @Input() fieldSort: string;
  @Input() showDetail = false;

  textoFiltro = '';
  count = 0;

  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  dataInicialSelecionada: string;
  dataFinalSelecionada: string;

  resumoPaginacao: IResumoPaginacao;
  currentPage = 1;
  pageSize = 10;
  pageSizes = [10, 20, 30, 50, 100];

  ascedingOrder = true;

  url = [];
  tipo_relatorio = '';
  formatos = ['xlsx', 'csv', 'txt'];
  formato = 'xlsx';

  constructor(
    private datePipe: DatePipe,
    private cnpjPipe: CNPJPipe,
    private currencyPipe: CurrencyPipe,
    private router: Router,
    private timestampService: TimestampBehaviourService,
    private templateService: TemplateService
  ) {}

  ngOnInit(): void {
    this.count = this.getDatasourceLength();
    this._setResumoPaginacao();
    this.sort(this.fieldSort);
    this.setDatas();
    this.templateService
          .getEstabelecimentoSubject()
          .subscribe((est) => (this.estabelecimento = est));
    this.url = this.router.url.split('/');
    this.tipo_relatorio = this.url[this.url.length - 2].toUpperCase() + '-' + this.url[this.url.length - 1].toUpperCase();
  }

  changePage(newPage: number): void {
    this.currentPage = newPage;
    this._setResumoPaginacao();
  }

  onRadioChange(value: string) {
    this.formato = value;
  }

  showFormatedValue(object: object, col: number): any {
    let value;

    if (this.canShow(col)) {
      const columnDetail = this._getColumnDetail(
        this.columnsDetails[col]['dataKey']
      );

      if (columnDetail.type !== TypeEnum.AGENCIA_CONTA) {
        value = object[this.columnsDetails[col]['dataKey']];
      }

      if (columnDetail.type === TypeEnum.DATE) {
        if (value == null) {
          return '-';
        }
        return this.datePipe.transform(value, columnDetail.format);
      } else if (columnDetail.type === TypeEnum.CURRENCY) {
        return this.currencyPipe.transform(value, columnDetail.format);
      } else if (columnDetail.type === TypeEnum.CNPJ) {
        return this.cnpjPipe.transform(value);
      } else if (columnDetail.type === TypeEnum.AGENCIA_CONTA) {
        return this.transformAgenciaConta(object, col);
      } else if (columnDetail.type === TypeEnum.OBJETO) {
        const keys = this.columnsDetails[col]['dataKey'].split('.');
        for (let i = 0; i < keys.length; i++){
          if (i === 0){
            value = object[keys[i]];
          }else{
            value = value[keys[i]];
          }
        }
      }
      return value;
    }
  }

  onChangeFilter(): void {
    this.carregarDadosFiltrados();
    this._setResumoPaginacao();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.currentPage = 1;
    this._setResumoPaginacao();
  }

  carregarDadosFiltrados(): any[] {
    if (this.textoFiltro.length >= 1 && !this.isSemDados()) {
      const result = this.dataSource.filter((object) =>
        Object.values(object).some(
          (value) =>
            (value instanceof Date &&
              this.datePipe
                .transform(value, 'dd/MM/yyyy HH:mm:ss')
                .indexOf(this.textoFiltro) !== -1) ||
            (typeof value === 'number' &&
              value
                .toString()
                .toLowerCase()
                .indexOf(this.textoFiltro.toLowerCase()) !== -1) ||
            (typeof value === 'string' &&
              value
                .toString()
                .toLowerCase()
                .indexOf(this.textoFiltro.toLowerCase()) !== -1)
        )
      );
      this.count = result.length;
      return result;
    } else {
      this.count = this.getDatasourceLength();
      return this.dataSource;
    }
  }

  isSemDados(): boolean {
    if (typeof this.dataSource === 'undefined') {
      return true;
    }
    return this.dataSource.length === 0;
  }

  sort(dataKey: string): void {
    this._setFieldSort(dataKey);
    if (this._isAscending()) {
      this.ascedingOrder = false;
      return this._sortAscending();
    }

    this.ascedingOrder = true;
    return this._sortDescending();
  }

  getClass(col: number): string {
    if (this.canShow(col)) {
      const columnDetail = this._getColumnDetail(
        this.columnsDetails[col]['dataKey']
      );

      return columnDetail['align'];
    }
  }

  detalhar(data: any): void {
    this.dataEventEmitter.emit(data);
  }

  showOrderIcon(dataKey: string): string {
    const ICON_DISABLED = 'assets/icones/icons-data-table/cinza.svg';
    const ICON_ENABLED_ASC = 'assets/icones/icons-data-table/blue-asc.svg';
    const ICON_ENABLED_DES = 'assets/icones/icons-data-table/blue-dsc.svg';

    if (dataKey === this.fieldSort && !this.ascedingOrder) {
      return ICON_ENABLED_ASC;
    }

    if (dataKey === this.fieldSort && this.ascedingOrder) {
      return ICON_ENABLED_DES;
    }

    return ICON_DISABLED;
  }

  canShow(col: number): boolean {
    return col <= this.columnsDetails.length - 1;
  }

  getDatasourceLength(): number {
    if (typeof this.dataSource === 'undefined') {
      return 0;
    }
    return this.dataSource.length;
  }

  private _setFieldSort(dataKey: string) {
    if (dataKey !== this.fieldSort) {
      this.ascedingOrder = true;
      this.fieldSort = dataKey;
    }
  }

  private _sortAscending() {
    if (typeof this.dataSource !== 'undefined') {
      this.dataSource.sort((a, b) => {
        const data_a = this.tratamentoDosValoresPorTipo(a);
        const data_b = this.tratamentoDosValoresPorTipo(b);

        if (data_a < data_b) {
          return -1;
        } else if (data_a > data_b) {
          return 1;
        }
        return 0;
      });
    }
  }

  private _sortDescending() {
    if (typeof this.dataSource !== 'undefined') {
      this.dataSource.sort((a, b) => {
        const data_a = this.tratamentoDosValoresPorTipo(a);
        const data_b = this.tratamentoDosValoresPorTipo(b);

        if (data_a > data_b) {
          return -1;
        } else if (data_a < data_b) {
          return 1;
        }
        return 0;
      });
    }
  }

  private _isAscending(): boolean {
    return this.ascedingOrder === true;
  }

  private tratamentoDosValoresPorTipo(object: object) {
    const index = this.columnsDetails.map(e => e.dataKey).indexOf(this.fieldSort);
    const columnDetail = this.columnsDetails[index];
    let data;
    if (columnDetail.type === TypeEnum.DATE) {
        data = Date.parse(object[this.fieldSort]);
      } else if (columnDetail.type === TypeEnum.CURRENCY) {
        data = object[this.fieldSort];
      } else {
        data = this.showFormatedValue(object, index);
    }
    return data;
  }

  private _getColumnDetail(key: string): ColumnDetail {
    return this.columnsDetails.find((detail) => detail.dataKey === key);
  }

  private _setResumoPaginacao(): void {
    if (!this.dataSource || this.dataSource.length === 0) {
      this.resumoPaginacao = null;
    } else {
      const total = this.count;
      const de = this.currentPage * this.pageSize - (this.pageSize - 1);
      const maximo = this.currentPage * this.pageSize;
      const ate = maximo > total ? total : maximo;

      this.resumoPaginacao = {
        de,
        ate,
        total,
      };
    }
  }

  private transformAgenciaConta(object: object, col: number): string {
    const dadosConta = this.columnsDetails[col]['dataKey'].split('/', 2);

    if (dadosConta.length > 0) {
      return object[dadosConta[0]] + '/' + object[dadosConta[1]];
    }

    return '';
  }

  gerarRelatorio(): void {
    const horaDataAtual = moment();
    const workbook = XLSX.utils.book_new();
    const colunas = this.columnsDetails.map(item => [item.header]);
    const cabecalho = this.getCabecalho();
    const worksheet = XLSX.utils.aoa_to_sheet([
        ...cabecalho,
        colunas,
        ...this.carregarDadosFiltrados().map(
           item => (Array.from({length: Object.keys(item).length}, (value, index) => index).map(
              key => ([
                this.showFormatedValue(item, key)
              ])
           ))
         )
    ]);

    this.formatarWorksheet(worksheet, this.dataSource.length, colunas.length - 1);

    XLSX.utils.book_append_sheet(workbook, worksheet, this.tipo_relatorio);

    const currentDate: string = new Date().toLocaleDateString().toString();
    const fileName = this.tipo_relatorio + '-' + currentDate + '.' + this.formato;
    const bt: any = this.formato;
    XLSX.writeFile(workbook, fileName, { bookType: bt });

  }

  getCabecalho(): string[][] {
      const formatoDataHora = 'DD/MM/YYYY [às] HH:mm:ss';
      const dataFormatada = moment().format(formatoDataHora);
      const data = this.dataInicialSelecionada + ' a ' + this.dataFinalSelecionada;
      const dadosEmpresa = this.estabelecimento?.nomeFantasia + ' - CNPJ: ' + this.estabelecimento?.cnpj;

      const cabecalho = [
          [''],
          ['', '', 'Painel PJ'],
          ['', '', dadosEmpresa],
          [''],
          ['Tipo de Relatório', '', '', this.tipo_relatorio],
          ['Período da Consulta', '', '', data],
          ['Data/Hora da Geração', '', '', dataFormatada],
          [''],
      ];

      return cabecalho;
  }

  formatarWorksheet(worksheet: XLSX.WorkSheet, quantidadeLinhas: number, indexColunaFinal: number) {
      this.mergeCelulas(worksheet, indexColunaFinal);
      this.formatarCabecalho(worksheet, indexColunaFinal);
      this.formatarCelulas(worksheet, quantidadeLinhas, indexColunaFinal);
  }

mergeCelulas(worksheet, indexColunaFinal) {
        const listaMerge = [
            { linhaInicial: 0, colunaInicial: 0, linhaFinal: 0, colunaFinal: indexColunaFinal },
            { linhaInicial: 1, colunaInicial: 0, linhaFinal: 2, colunaFinal: 1 },
            { linhaInicial: 1, colunaInicial: 2, linhaFinal: 1, colunaFinal: indexColunaFinal },
            { linhaInicial: 2, colunaInicial: 2, linhaFinal: 2, colunaFinal: indexColunaFinal },
            { linhaInicial: 3, colunaInicial: 0, linhaFinal: 3, colunaFinal: indexColunaFinal },
            { linhaInicial: 4, colunaInicial: 0, linhaFinal: 4, colunaFinal: 2 },
            { linhaInicial: 5, colunaInicial: 0, linhaFinal: 5, colunaFinal: 2 },
            { linhaInicial: 6, colunaInicial: 0, linhaFinal: 6, colunaFinal: 2 },
            { linhaInicial: 4, colunaInicial: 3, linhaFinal: 4, colunaFinal: indexColunaFinal },
            { linhaInicial: 5, colunaInicial: 3, linhaFinal: 5, colunaFinal: indexColunaFinal },
            { linhaInicial: 6, colunaInicial: 3, linhaFinal: 6, colunaFinal: indexColunaFinal },
            { linhaInicial: 7, colunaInicial: 0, linhaFinal: 7, colunaFinal: indexColunaFinal }
        ];

        if (!worksheet['!merges']) {
            worksheet['!merges'] = [];
        }

        listaMerge.forEach(itemMerge => {
            const { linhaInicial, colunaInicial, linhaFinal, colunaFinal } = itemMerge;
            const mergeCell = { s: { r: linhaInicial, c: colunaInicial }, e: { r: linhaFinal, c: colunaFinal } };
            worksheet['!merges'].push(mergeCell);
        });
    }

    formatarCelulas(worksheet, quantidadeLinhas, indexColunaFinal) {
        const primeiraLinhaPosCabecalho = 9;
        // Posições das colunas que tem valores monetários (R$) para converter o valor para moeda
        const colunasValoresMonetarios = [5, 7];
        // Posição da coluna da taxa (%) para converter em porcentagem
        const colunaTaxa = 6;

        for (let linha = primeiraLinhaPosCabecalho; linha < quantidadeLinhas + primeiraLinhaPosCabecalho; linha++) {
            for (let coluna = 0; coluna <= indexColunaFinal; coluna++) {

                const columnsDetails = this._getColumnDetail(
                this.columnsDetails[coluna]['dataKey']).type;

                const cell = worksheet[XLSX.utils.encode_cell({ r: linha, c: coluna })];
                if (cell) {
                    if (columnsDetails === TypeEnum.CURRENCY) {
                        cell.s = { alignment: { horizontal: 'center' }, numFmt: 'R$ 0.00' };
                    }
                } else {
                    const celulaVazia = { t: 's', v: '-', s: { alignment: { horizontal: 'center' } } };
                    worksheet[XLSX.utils.encode_cell({ r: linha, c: coluna })] = celulaVazia;
                }
            }
        }
    }

    formatarCabecalho(worksheet, indexColunaFinal) {
        this.formatarTituloCabecalho(worksheet);
        this.formatarDetalhamentoCabecalho(worksheet);
        this.formatarTituloColunas(worksheet, indexColunaFinal);
    }

    formatarTituloCabecalho(worksheet) {
        // Configurações da título no cabeçalho da tabela
        const alturaLinhaTituloCabecalho = 22;
        const tituloCabecalhoStyle = {
            alignment: { vertical: 'center' },
            fill: { fgColor: { rgb: 'FF465EFF' } },
            font: {
                name: 'Arial',
                bold: true,
                sz: 18,
                color: { rgb: 'FFFFFF' }
            }
        };

        const celulasTituloCabecalho = [
            { linha: 1, coluna: 2 },
            { linha: 2, coluna: 2 }
        ];

        if (!worksheet['!rows']) {
            worksheet['!rows'] = [];
        }

        celulasTituloCabecalho.forEach(celula => {
            worksheet[XLSX.utils.encode_cell({ r: celula.linha, c: celula.coluna })].s = tituloCabecalhoStyle;
            worksheet['!rows'][celula.linha] = { hpx: alturaLinhaTituloCabecalho };
        });
    }

    formatarDetalhamentoCabecalho(worksheet) {
        // Configurações do detalhamento no cabeçalho da tabela
        const alturaLinhaDetalhamento = 15;
        const detalhamentoCabecalhoStyle = {
            font: {
                name: 'Arial',
                bold: true,
                sz: 12,
            }
        };

        const celulasDetalhamentoCabecalho = [
            { linha: 0, coluna: 0 },
            { linha: 3, coluna: 0 },
            { linha: 4, coluna: 0 },
            { linha: 4, coluna: 3 },
            { linha: 5, coluna: 0 },
            { linha: 5, coluna: 3 },
            { linha: 6, coluna: 0 },
            { linha: 6, coluna: 3 },
            { linha: 7, coluna: 0 },
        ];

        celulasDetalhamentoCabecalho.forEach(celula => {
            worksheet[XLSX.utils.encode_cell({ r: celula.linha, c: celula.coluna })].s = detalhamentoCabecalhoStyle;
            worksheet['!rows'][celula.linha] = { hpx: alturaLinhaDetalhamento };
        });
    }

    formatarTituloColunas(worksheet, indexColunaFinal) {
        // Configurações da linha dos títulos das colunas
        const backgroudCinza = { fgColor: { rgb: 'FFF4F5F7' } };
        const bordaDefault = { style: 'thin', color: { rgb: 'D3D3D3' } };
        const alinhamento = { vertical: 'center', horizontal: 'center', wrapText: true };
        const fonteStyle = { name: 'Arial', bold: true, sz: 10 };
        const indexLinhaTituloColunas = 8;
        const larguraColuna = 15;
        const alturaLinhaTituloColuna = 25;

        if (!worksheet['!cols']) {
            worksheet['!cols'] = [];
        }

        worksheet['!rows'][indexLinhaTituloColunas] = { hpx: alturaLinhaTituloColuna };

        for (let index = 0; index <= indexColunaFinal; index++) {
            worksheet[XLSX.utils.encode_cell({ r: indexLinhaTituloColunas, c: index })].s = {
                alignment: alinhamento,
                fill: backgroudCinza,
                font: fonteStyle,
                border: {
                    top: bordaDefault,
                    left: bordaDefault,
                    bottom: bordaDefault,
                    right: bordaDefault
                }
            };

            worksheet['!cols'][index] = { wch: larguraColuna };
        }
    }

    setDatas(): void {
        this.timestampService.getCalendarioSubject().subscribe(
            (data) => {
                this.filtroDatas = this.timestampService.translateDateForInput(data);
                this.setDatasExibidas();
            }
        );
    }

    setDatasExibidas() {
        this.dataInicialSelecionada = moment(this.filtroDatas?.dataInicial).locale('pt-br').format('DD/MM/YYYY');
        this.dataFinalSelecionada = moment(this.filtroDatas?.dataFinal).locale('pt-br').format('DD/MM/YYYY');
    }
}
