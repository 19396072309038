import {RelatoriosService} from '../../shared/services/relatorios.service';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Empresa} from 'gnc-merge-common-ng/dist/merge-common-ng';
import {DataInput} from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom/data-input';
import {TimestampBehaviourService} from '../../shared/services/timestamp-behaviour.service';
import {TemplateService} from '../template/template.service';
import { IFiltroDatas } from '../../shared/common/interfaces/filtro-datas';
import { ServicoService } from '../../shared/services/servico.service';
import { StatusDoContratoModel } from '../../shared/common/models/status-do-contrato';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.css'],
})
export class RelatoriosComponent implements OnInit, OnChanges {
  @Input() estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;

  date: DataInput;

  planoAtivo = true;
  temPlano = false;

  constructor(
    public relatorioService: RelatoriosService,
    private timestampService: TimestampBehaviourService,
    private templateService: TemplateService,
    private servicoService: ServicoService,
  ) {
    this.estabelecimento = null;
  }

  ngOnInit() {
    this.atualizarChecagemPlano();
    this.templateService.getEstabelecimentoSubject().subscribe(
      (est) => (this.estabelecimento = est)
    );
    this.timestampService.getCalendarioSubject().subscribe((data) => {
      this.filtroDatas = this.timestampService.translateDateForInput(data);
      this.date = data;
      this.timestampService.updateRegraCalendario();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.atualizarChecagemPlano();
    this.timestampService.getCalendarioSubject().subscribe((data) => {
      this.filtroDatas = this.timestampService.translateDateForInput(data);
      this.date = data;
      this.timestampService.updateRegraCalendario();
    });
  }
  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);
  }
  atualizarChecagemPlano(): void {
    this.servicoService.verificarContrato().subscribe((resposta) => {
      this.validatePlano(resposta.dados);
    });
  }
  validatePlano(statusPlano: StatusDoContratoModel): void {
    this.planoAtivo = statusPlano.existeContratoVigente;
    this.temPlano =
      statusPlano.existeContratoVigente ||
      statusPlano.existeContratoCancelado ||
      statusPlano.existePagamentoEmAtraso;
  }
}
