import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConciliacaoVendaDetalheBoletosComponent} from './conciliacao-venda-detalhe-boletos.component';

@NgModule({
  declarations: [ConciliacaoVendaDetalheBoletosComponent],
  imports: [CommonModule],
  exports: [ConciliacaoVendaDetalheBoletosComponent],
})
export class ConciliacaoVendaDetalheBoletosModule {}
