import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IVenda } from '../../../vendas-a-receber/models/extrato-vendas';

@Component({
  selector: 'app-detalhamento-venda',
  templateUrl: './detalhamento-venda.component.html',
  styleUrls: ['./detalhamento-venda.component.css'],
})
export class DetalhamentoVendaComponent implements AfterViewInit {
  @Input() vendaEmDetalhamento: IVenda;

  @Output() fecharDetalhamento = new EventEmitter<any>();

  ngAfterViewInit() {
    const scrollPosition = document.getElementById('detalhamento-venda');
    if (scrollPosition !== null) {
      scrollPosition.scrollIntoView();
    }
  }

  fechar() {
    this.fecharDetalhamento.emit();
  }
}
