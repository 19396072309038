import { NgModule } from '@angular/core';
import { AuthGuard, RoleGuard } from 'merge-common-ng';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'oauth-callback',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'apj-callback',
    loadChildren: () =>
      import('./pages/login/login-apj/login-apj.module').then(
        (m) => m.LoginApjModule
      ),
  },
  {
    path: 'login-dev',
    loadChildren: () =>
      import('./pages/login/login-dev/login-dev.module').then(
        (m) => m.LoginDevModule
      ),
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/template/template.module').then((m) => m.TemplateModule),
      canActivate: [AuthGuard, RoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
