import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplateComponent } from './template.component';

const routes: Routes = [
  {
    path: '',
    component: TemplateComponent,
    children: [
      {
        path: '',
        redirectTo: 'visao-geral',
        pathMatch: 'full',
      },
      {
        path: 'visao-geral',
        loadChildren: () =>
          import('../visao-geral/visao-geral.module').then(
            (m) => m.VisaoGeralModule
          ),
      },
      {
        path: 'meu-negocio/despesas',
        loadChildren: () =>
          import('../meu-negocio/despesas/despesas.module').then(
            (m) => m.DespesasModule
          ),
      },
      {
        path: 'meu-negocio/fluxo-caixa',
        loadChildren: () =>
          import('../meu-negocio/fluxo-de-caixa/fluxo-caixa.module').then(
            (m) => m.FluxoCaixaModule
          ),
      },
      {
        path: 'meu-negocio/recebimentos',
        loadChildren: () =>
          import('../meu-negocio/recebimentos/recebimentos.module').then(
            (m) => m.RecebimentosModule
          ),
      },
      {
        path: 'meu-negocio/vendas-a-receber',
        loadChildren: () =>
          import(
            '../meu-negocio/vendas-a-receber/vendas-a-receber.module'
          ).then((m) => m.VendasAReceberModule),
      },
      {
        path: 'extrato',
        loadChildren: () =>
          import('../extrato/extrato.module').then((m) => m.ExtratoModule),
      },
      {
        path: 'recomendacoes',
        loadChildren: () =>
          import('../recomendacoes/recomendacoes.module').then((m) => m.RecomendacoesModule),
      },

      {
        path: 'servicos',
        loadChildren: () =>
          import('../servicos/servicos.module').then((m) => m.ServicosModule),
      },
      {
        path: 'marketplace',
        loadChildren: () =>
          import('../marketplace/marketplace.module').then(
            (m) => m.MarketplaceModule
          ),
      },
      {
        path: 'autorizacoes',
        loadChildren: () =>
          import('../autorizacoes/autorizacoes.module').then(
            (m) => m.AutorizacoesModule
          ),
      },
      {
        path: 'relatorios',
        loadChildren: () =>
          import('../relatorios/relatorios.module').then(
            (m) => m.RelatoriosModule
          ),
      },
      {
        path: 'cartoes/meu-plano',
        loadChildren: () =>
          import('../cartoes/meu-plano/meu-plano.module').then(
            (m) => m.MeuPlanoModule
          ),
      },
      {
        path: 'cartoes/minhas-vendas',
        loadChildren: () =>
          import('../cartoes/minhas-vendas/minhas-vendas.module').then((m) => m.MinhasVendasModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TemplateRoutingModule {}
