import { DataTableModule } from './../../../../../shared/common/components/data-table/data-table.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { DatepickerModuleEnum } from 'merge-common-ng';
import { NavbarDespesasModule } from './../navbar-despesas/navbar-despesas.module';
import { BlockUIModule } from 'ng-block-ui';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TedDocRoutingModule } from './ted-doc-routing.module';
import { TedDocComponent } from './ted-doc.component';

@NgModule({
  declarations: [TedDocComponent],
  exports: [TedDocComponent],
  imports: [
    CommonModule,
    TedDocRoutingModule,
    NavbarDespesasModule,
    FormsModule,
    NgxPaginationModule,
    BlockUIModule.forRoot(),
    DatepickerModuleEnum,
    DataTableModule,
  ],
})
export class TedDocModule {}
