import {Component, Input, OnInit} from '@angular/core';
import {ConsultarLancamentosFuturosFaturasOutput} from '../../interfaces/consultar-lancamentos-futuros-faturas-output';
import {ExtratoFaturaCartaoService} from '../../../../../../shared/services/extrato-fatura-cartao.service';
import {DadosTabela} from '../extrato-fatura-tabela/extrato-fatura-tabela.component';

@Component({
  selector: 'app-extrato-fatura-aberta',
  templateUrl: './extrato-fatura-aberta.component.html',
  styleUrls: ['./extrato-fatura-aberta.component.css']
})
export class ExtratoFaturaAbertaComponent implements OnInit {

  @Input() dadosFatura: ConsultarLancamentosFuturosFaturasOutput;
  dadosExtrato: Array<DadosTabela>;

  constructor(
    private extratoCartao: ExtratoFaturaCartaoService,
  ) {
  }

  format(value: string, formatType: number = 1) {
    if (formatType === 1) {
      const d = value.split('.');
      return `${d[0]}/${d[1]}/${d[2]}`;
    }
  }

  ngOnInit(): void {
    this.dadosExtrato = this.construirExtrato();
  }

  private construirExtrato(): Array<DadosTabela> {
    const d: Array<DadosTabela> = [];
    for (const lancamento of this.dadosFatura.lancamentos) {
      const dado: DadosTabela = {
        valorLancamento: lancamento.valorReal,
        textoLancamento: lancamento.textoDescricaoLancamento,
        numeroPlastico: lancamento.numeroPlastico,
        dataLancamento: lancamento.dataLancamento,
        valorSaldoTotal: 0,
        valorSaldoAnterior: 0
      };
      d.push(dado);
    }
    return d;
  }
}
