import {ApiService, Response} from 'merge-common-ng';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NgBlockUI} from 'ng-block-ui';
import {EndpointsMerge} from '../common/endpointsmerge';
import {
  AlterarPlanoInterface
} from '../../pages/cartoes/meu-plano/components/alterar-plano/model/alterar-plano.interface';
import {StatusDoContratoModel} from '../common/models/status-do-contrato';

@Injectable({
    providedIn: 'root',
})

export class ServicoService {
    constructor(public apiService: ApiService) { }

    salvarContratacao(body, ngBlockUI?: NgBlockUI): Observable<Response<void>> {
        return this.apiService.post(
            EndpointsMerge.ENDPOINT_SERVICO_CONTRATACAO,
            body,
            null,
            ngBlockUI
        );
    }

    getDetalhamentoDoPlanoContratado(ngBlockUI?: NgBlockUI): Observable<Response<any>> {
        return this.apiService.get(
          EndpointsMerge.DETALHAMENTO_PLANO_CONTRATADO,
          null,
          ngBlockUI
        );
    }

    verificarContrato(ngBlockUI?: NgBlockUI): Observable<Response<StatusDoContratoModel>> {
        return this.apiService.get(
            EndpointsMerge.VERIFICAR_CONTRATO,
            null,
            ngBlockUI
        );
    }

    getHistoricoPagamentoDoPlanoContratado(ngBlockUI?: NgBlockUI): Observable<Response<any>> {
        return this.apiService.get(
            EndpointsMerge.HISTORICO_PAGAMENTO_PLANO_CONTRATADO,
            null,
            ngBlockUI
        );
    }

    getListaAdquirentesContratadas(ngBlockUI?: NgBlockUI): Observable<Response<any>> {
        return this.apiService.get(
            EndpointsMerge.LISTAR_ADQUIRENTES_CONTRATADAS,
            null,
            ngBlockUI
        );
    }

    getAdquirentesLista(ngBlockUI?: NgBlockUI): any {
        return this.apiService.get(
          EndpointsMerge.LISTAR_ADQUIRENTES,
          null,
          ngBlockUI
        );
    }

    incluirNovasAdquirentes(novasAdquirentes: number[]): any {
        return this.apiService.post(
            EndpointsMerge.INCLUIR_ADQUIRENTE,
            { idsAdquirentesSelecionadas: novasAdquirentes }
        );
    }

    alterarPlanoContratado(body: AlterarPlanoInterface, ngBlockUI?: NgBlockUI): Observable<Response<void>> {
        return this.apiService.post(
            EndpointsMerge.ALTERAR_PLANO_CONTRATADO,
            body,
            null,
            ngBlockUI
        );
    }

    cancelarPlanoContratado(ngBlockUI?: NgBlockUI): Observable<Response<void>> {
        return this.apiService.post(
            EndpointsMerge.CANCELAR_PLANO_CONTRATADO,
            null,
            null,
            ngBlockUI
        );
    }

    enviarCodigoDeAutorizacaoDaAdquirente(body, ngBlockUI?: NgBlockUI): Observable<Response<void>> {
        return this.apiService.post(
            EndpointsMerge.ENVIAR_CODIGO_DE_AUTORIZAR_ADQUIRENTE,
            body,
            null,
            ngBlockUI
        );
    }

    mudarStatusDePendenteParaIniciada(body, ngBlockUI?: NgBlockUI): Observable<Response<void>> {
        return this.apiService.post(
            EndpointsMerge.MUDAR_STATUS_PARA_INICIADA,
            body,
            null,
            ngBlockUI
        );
    }
}
