import { CNPJPipe } from './../../pipes/cnpj.pipe';
import { ColumnDetail } from './interfaces/column-detail';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlockUIModule } from 'ng-block-ui';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { DataTableComponent } from './data-table.component';

@NgModule({
  providers: [CurrencyPipe, CNPJPipe],
  declarations: [DataTableComponent],
  imports: [
    FormsModule,
    CommonModule,
    NgxPaginationModule,
    HighchartsChartModule,
    BlockUIModule.forRoot(),
  ],
  exports: [DataTableComponent],
})
export class DataTableModule {}
