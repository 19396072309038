import { NgBlockUI, BlockUIModule } from 'ng-block-ui';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlocoRecebimentosDiaComponent } from './bloco-recebimentos-dia.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MaisInfoModule } from 'src/app/merge/shared/common/components/mais-info/mais-info.module';

@NgModule({
  declarations: [BlocoRecebimentosDiaComponent],
  imports: [CommonModule, HighchartsChartModule, MaisInfoModule, BlockUIModule.forRoot()],
  exports: [BlocoRecebimentosDiaComponent],
})
export class BlocoRecebimentosDiaModule {}
