import {DadosTarifaDto} from './../../pages/meu-negocio/despesas/models/dados-tarifa-dto';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService, Hosts, Response} from 'merge-common-ng';
import {NgBlockUI} from 'ng-block-ui';

@Injectable({
  providedIn: 'root',
})
export class TarifaService {
  LISTAR = 'api/tarifa/consultarTarifa';

  constructor(public apiService: ApiService) {}

  listar(body, ngBlockUI?: NgBlockUI): Observable<Response<DadosTarifaDto>> {
    return this.apiService.post(
      this.LISTAR,
      body,
      null,
      ngBlockUI,
      Hosts.HOST_ITEBB
    );
  }
}
