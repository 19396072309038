import {Injectable} from '@angular/core';
import {
  DataInputComRegra
} from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom-enum/data-input';
import {BehaviorSubject, Observable} from 'rxjs';
import {NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {IFiltroDatas} from '../common/interfaces/filtro-datas';
import {RegrasCalendario} from 'src/app/merge/shared/common/enum/regras-calendario.enum';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TimestampBehaviourService {
  private calendarioSubject: BehaviorSubject<DataInputComRegra>;
  private regra: string;
  private secao: string;
  constructor(private calendar: NgbCalendar,
              private router: Router) {
    this.calendarioSubject = new BehaviorSubject<DataInputComRegra>(null);
    this.secao = '';
  }

  updateRegraCalendario() {
    const SeisMesesParaFrente = new RegExp(/pages\/meu-negocio\/vendas-a-receber/);
    const SeisMesesParaTras = new RegExp(/pages\/meu-negocio\/[despesas|recebimentos]|pages\/extrato\/conta-corrente|pages\/relatorios/);
    const SeisMesesParaFrenteEParaTras = new RegExp(/pages\/meu-negocio\/fluxo-caixa/);

    const novaSecao = this.getNomeSecao(this.router.url);

    if (this.secao !== novaSecao) {  // verifica se foi alterado o item do menu lateral
      if (SeisMesesParaFrente.test(this.router.url)) {
        this.regra = RegrasCalendario.SeisMesesParaFrente;

        this.setCalendarioSubject({
          toDate: this.calendar.getNext(this.calendar.getToday(), 'm', 1),
          fromDate: this.calendar.getToday(),
          regra: this.regra
        });
      } else {
        if (SeisMesesParaTras.test(this.router.url)) {
          this.regra = RegrasCalendario.SeisMesesParaTras;
        } else {
          this.regra = RegrasCalendario.SeisMesesParaFrenteEParaTras;
        }
        this.setCalendarioSubject({
          toDate: this.calendar.getToday(),
          fromDate: this.calendar.getNext(this.calendar.getToday(), 'm', -1),
          regra: this.regra
        });
      }
      this.secao = novaSecao;
    }
  }

  getNomeSecao(url: string): string {
    return url.split('/')[3];
  }

  setCalendarioSubject(dataInput: DataInputComRegra): void {
    this.calendarioSubject.next(dataInput);
  }

  getCalendarioSubject(): Observable<DataInputComRegra> {
    return this.calendarioSubject.asObservable();
  }

  translateDateForEvent(event): IFiltroDatas {
    return {
      dataInicial: new Date(
        event.fromDate.year,
        event.fromDate.month - 1,
        event.fromDate.day
      ),
      dataFinal: new Date(
        event.toDate.year,
        event.toDate.month - 1,
        event.toDate.day
      )
    };
  }

  translateDateForInput(data: DataInputComRegra): IFiltroDatas {
    return {
      dataInicial: new Date(
        data.fromDate.year,
        data.fromDate.month - 1,
        data.fromDate.day
      ),
      dataFinal: new Date(
        data.toDate.year,
        data.toDate.month - 1,
        data.toDate.day
      )
    };
  }

  tratarDatapicker(filtroDatas: IFiltroDatas): void {
    const fromd = filtroDatas.dataInicial;
    const to = filtroDatas.dataFinal;
    const fromDate: NgbDate = new NgbDate(
      fromd.getFullYear(),
      fromd.getMonth() + 1,
      fromd.getDate()
    );
    const toDate: NgbDate = new NgbDate(
      to.getFullYear(),
      to.getMonth() + 1,
      to.getDate()
    );
    const regra: string = this.regra;
    const input: DataInputComRegra = {
      fromDate,
      toDate,
      regra
    };
    this.setCalendarioSubject(input);
  }
}
