import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-autorizacoes',
  templateUrl: './autorizacoes.component.html',
  styleUrls: ['./autorizacoes.component.css']
})
export class AutorizacoesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
