import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DetalhamentoVendaComponent} from './detalhamento-venda.component';

@NgModule({
  declarations: [DetalhamentoVendaComponent],
  imports: [CommonModule],
  exports: [DetalhamentoVendaComponent],
})
export class DetalhamentoVendaModule {}
