import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaisInfoComponent } from './mais-info.component';

@NgModule({
  declarations: [MaisInfoComponent],
  imports: [CommonModule],
  exports: [MaisInfoComponent],
})
export class MaisInfoModule {

}
