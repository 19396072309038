import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { ToastrService } from 'ngx-toastr';
import { VisaoGeralService } from '../../shared/services/visao-geral.service';
import { IGraficoCaixa, IGraficosRecebimentosDespesas, IVisaoGeral,  } from './interfaces/visao-geral';
import { ServicoService } from 'src/app/merge/shared/services/servico.service';
import { ArquivosService } from '../../shared/services/arquivos.service';
import { ChipData } from './interfaces/chip-data';
import { AdquirenteCadastradaModel } from '../cartoes/meu-plano/components/painel-acompanhamento/components/model/adquirente-cadastrada.model';
import { Router } from '@angular/router';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-visao-geral',
  templateUrl: './visao-geral.component.html',
  styleUrls: ['./visao-geral.component.css'],
})
export class VisaoGeralComponent implements OnChanges, OnInit {
  constructor(
    private mensagemService: ToastrService,
    private visaoGeralService: VisaoGeralService,
    private servicoService: ServicoService,
    private arquivosService: ArquivosService,
    private router: Router,
    private matomoTracker: MatomoTracker

  ) { }

  @Input() estabelecimento: Empresa;
  @BlockUI('bui-grafico-visao-geral') buiGraficosVisaoGeral: NgBlockUI;
  dadosVisaoGeral: IVisaoGeral;
  errorConsultaGraficoGeral = false;
  temPlano = true;
  dataHoje: Date = new Date();
  dataOntem: Date = this.subtractDay(this.dataHoje, 1);
  recebimentos: IGraficosRecebimentosDespesas;
  despesas: IGraficosRecebimentosDespesas;
  caixaDiario: IGraficoCaixa;
  datas: ChipData[] = [];
  temTodasAdquirentesPendentes = false;
  readonly TRACK = {
    action: 'click',
    category: 'Interacoes',
    name: 'Home||ConciliadoraNotificacao||Toast'
  };

  ngOnInit(): void {
    this._consultarVisaoGeral();
    this.servicoService.verificarContrato().subscribe((resposta) => this.temPlano = resposta.dados.existeContratoVigente);
    this.getListaDeAdquirentesContratadas();
  }

  ngOnChanges(): void {
    this._consultarVisaoGeral();
    this.getListaDeAdquirentesContratadas();

  }

  preencherDatas(dados: any) {
    dados.valoresDespesasGraficos.forEach(element => {
      const data = new Date(element.data);

      if (data.getDate() === this.dataOntem.getDate() && data.getMonth() === this.dataOntem.getMonth()) {

        this.datas.push({ data: new Date(data), dataExibicao: this.formatDate(new Date(data)), selected: true });
      }
      else {
        this.datas.push({ data: new Date(data), dataExibicao: this.formatDate(new Date(data)), selected: false });
      }

    });
  }

  formatDate(data: Date): string {

    if (data.getDate() === this.dataHoje.getDate()) {
      return 'Hoje';
    }
    if (data.getDate() === this.dataOntem.getDate() ) {
      return 'Ontem';
    }

    const day = data.getDate().toString().padStart(2, '0');
    const month = (data.getMonth() + 1).toString().padStart(2, '0');
    return `${day}/${month}`;
  }

  subtractDay(data: Date, numberDays: number): Date {

    return new Date(new Date().setDate(data.getDate() - numberDays));
  }
  private _consultarVisaoGeral() {
    this.visaoGeralService
      .consultarDadosVisaoGeral(this.buiGraficosVisaoGeral)
      .subscribe(
        (response) => {
          if (response !== null && response.dados !== null) {
            this.dadosVisaoGeral = response.dados;
            this.recebimentos = this.filtraResposta(this.dataOntem, this.dadosVisaoGeral.valoresRecebimentosGraficos);
            this.despesas = this.filtraResposta(this.dataOntem, this.dadosVisaoGeral.valoresDespesasGraficos);
            this.caixaDiario = this.filtraResposta(this.dataOntem, this.dadosVisaoGeral.valoresCaixaDiarioGraficos);
            this.preencherDatas(response.dados);
            this.ordenaArrayDatas();
          } else {
            this.errorConsultaGraficoGeral = true;
          }
        },
        (error) => {
          console.error(error);
          this.errorConsultaGraficoGeral = true;
          this.mensagemService.error(error[0].message);
        }
      );
  }

  changeFilter($event: any) {
    const dataFiltro = new Date($event.value);
    const dataFiltroFormatada = new Date(dataFiltro.getFullYear(), dataFiltro.getMonth(), dataFiltro.getDate(), 0, 0, 0, 0);
    this.recebimentos = this.filtraResposta(dataFiltroFormatada, this.dadosVisaoGeral.valoresRecebimentosGraficos);
    this.despesas = this.filtraResposta(dataFiltroFormatada, this.dadosVisaoGeral.valoresDespesasGraficos);
    this.caixaDiario = this.filtraResposta(dataFiltroFormatada, this.dadosVisaoGeral.valoresCaixaDiarioGraficos);
  }

  filtraResposta(date: Date, arr: Array<any>) {
    const dataFiltro = new Date(date);
    const dataFiltroFormatada = new Date(dataFiltro.getFullYear(), dataFiltro.getMonth(), dataFiltro.getDate(), 0, 0, 0, 0);
    const respostaFiltrada = arr.find(element => {
      const data = new Date(element.data);
      const dataObjetivoFormatada = new Date(data.getFullYear(), data.getMonth(), data.getDate(), 0, 0, 0, 0);
      if (dataFiltroFormatada.getTime() === dataObjetivoFormatada.getTime()) {
        return element;
      }
    });
    return respostaFiltrada;
  }

  ordenaArrayDatas() {

    this.datas = this.datas.sort((a, b) => {
      if (a.data.getTime() > b.data.getTime()) {
        return -1;
      }
      if (a.data.getTime() < b.data.getTime()) {
        return 1;
      }
      return 0;
    }
    );

  }

  getListaDeAdquirentesContratadas(): void {
    this.servicoService
      .getListaAdquirentesContratadas()
      .subscribe(
        (resposta) => {
          if (resposta.dados.length > 0){
            this.temTodasAdquirentesPendentes = this.verificaStatusAdquirente(resposta.dados);
          }
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

   verificaStatusAdquirente(dados: AdquirenteCadastradaModel[]) {
    for (const element of dados) {
      if (element.statusDoVinculo === 'Ativa' || element.statusDoVinculo === 'Inativa') {
        return false;
      }
    }
    return true;
  }

  abrirPaginaMeuPlano(): void {
    this.matomoTracker.trackEvent(this.TRACK.category, this.TRACK.action, this.TRACK.name);
    this.router.navigate(['/pages/cartoes/meu-plano']);
  }
}

