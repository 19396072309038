import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cnpj',
})
export class CNPJPipe implements PipeTransform {
  transform(value: number | string): string {
    try {
      if (value === undefined) {
        return '';
      }

      value = String(value);
      value = value.padStart(14, '0');
      value = value.replace(/[^\d]/g, '');

      return value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      );
    } catch (error) {
      console.error(error);
      return String(value);
    }
  }
}
