import { PagesService } from './../../shared/services/pages.service';
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { BlockUIInterceptor } from 'gnc-merge-common-ng/dist/merge-common-ng';


@Component({
  selector: 'app-servicos',
  templateUrl: './configuracoes.component.html',
  styleUrls: ['./configuracoes.component.css'],
})

export class ConfiguracoesComponent implements OnInit {

  static URL_CIELO_BB = 'https://minhaconta2.cielo.com.br/oauth/?mode=redirect&client_id=90bdf161-e95a-31d7-8516-6e4643c1db95&redirect_uri=https:%2F%2Fweb.conciliadora.com.br%2FAdministrativo%2FAutorizacaoCielo.aspx&state=STATE_INFO&scope=profile_read,profile_write,transaction_read,transaction_write';
  @Inject(DOCUMENT) readonly document: any;
  agendaCartoesOpened: boolean;
  vendasCartaoOpened: boolean;

  constructor(
    private pagesService: PagesService,
    private mensagemService: ToastrService,
    private blockUi: BlockUIInterceptor
  ) { }

  ngOnInit(): void {
    this.agendaCartoesOpened = true;
    this.vendasCartaoOpened = false;

    this.blockUi.stopForce();
  }
  openAgendaCartao() {
    this.agendaCartoesOpened = true;
    this.vendasCartaoOpened = false;
  }
  openVendasCartao() {
    this.agendaCartoesOpened = false;
    this.vendasCartaoOpened = true;
  }

}
