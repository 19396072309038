import {
  TypeEnum,
  AlignEnum,
  FormatEnum,
} from '../../../../../shared/common/components/data-table/interfaces/column-detail';
import { ColumnDetail } from 'src/app/merge/shared/common/components/data-table/interfaces/column-detail';
import { BoletoDto } from '../../models/boleto-dto';
import { Component, OnInit } from '@angular/core';
import { DataInputComRegra, Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { TemplateService } from 'src/app/merge/pages/template/template.service';
import { IFiltroDatas } from 'src/app/merge/shared/common/interfaces/filtro-datas';
import { PagesService } from 'src/app/merge/shared/services/pages.service';
import { TimestampBehaviourService } from 'src/app/merge/shared/services/timestamp-behaviour.service';

import { ConsultaBoletoDto } from '../../models/consulta-boleto-dto';
import { DadosBoletoDto } from '../../models/dados-boleto-dto';

@Component({
  selector: 'app-boleto',
  templateUrl: './boleto.component.html',
  styleUrls: ['./boleto.component.css'],
})
export class BoletoComponent implements OnInit {
  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  dataSelecionada: DataInputComRegra;
  dadosBoleto: DadosBoletoDto;
  textoFiltro = '';

  @BlockUI('bui-despesas-boleto') buiDepesasBoleto: NgBlockUI;

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 20, 30];

  boletos: BoletoDto[] = [];
  defaultDataSort = 'data';
  columnsDetails: ColumnDetail[] = [
    {
      dataKey: 'banco',
      header: 'BANCO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'dataVencimento',
      header: 'EMISSÃO',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'dataVencimento',
      header: 'VENCIMENTO',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'situacao',
      header: 'SITUAÇÃO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'valorLiquido',
      header: 'VALOR LÍQUIDO',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.CURRENCYBR,
    },
  ];

  constructor(
    private pagesService: PagesService,
    private mensagemService: ToastrService,
    private templateService: TemplateService,
    private timestampService: TimestampBehaviourService,
  ) {
    this.dadosBoleto = new DadosBoletoDto();
    this.dadosBoleto.boletos = [];
  }

  ngOnInit(): void {
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this._setFiltroDatas(data));

    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));
    this.timestampService.updateRegraCalendario();
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);
    this.timestampService.getCalendarioSubject().subscribe((date) => {
      this.dataSelecionada = date;
    });
    this._carregarDespesasBoleto();
  }

  private _setFiltroDatas(data: DataInputComRegra): void {
    this.dataSelecionada = data;
    this.filtroDatas = this.timestampService.translateDateForInput(data);
  }

  private _carregarDespesasBoleto(): void {
    this.pagesService
      .getListaBoletos(this._parametroPesquisaBoletos(), this.buiDepesasBoleto)
      .subscribe(
        (resposta) => {
          this.dadosBoleto = resposta.dados;
          this.boletos = this.dadosBoleto.boletos;
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  private _parametroPesquisaBoletos(): ConsultaBoletoDto {
    const dto = new ConsultaBoletoDto();
    dto.dataVencimentoTituloInicio = this.filtroDatas.dataInicial;
    dto.dataVencimentoTituloFim = this.filtroDatas.dataFinal;
    return dto;
  }
}
