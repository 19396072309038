import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConviteOpenBankingComponent } from './convite-open-banking.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MaisInfoModule } from '../../../../shared/common/components/mais-info/mais-info.module';

@NgModule({
  declarations: [ConviteOpenBankingComponent],
  imports: [CommonModule, HighchartsChartModule, MaisInfoModule],
  exports: [ConviteOpenBankingComponent],
})
export class ConviteOpenBankingModule {}
