import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiService, Response} from 'merge-common-ng';
import {NgBlockUI} from 'ng-block-ui';
import {Observable} from 'rxjs';

import {DataPixAgContaModel} from '../../pages/meu-negocio/vendas-a-receber/models/data-pix-ag-conta.model';
import {DadosTablePix} from '../common/components/extrato-contas/interfaces/dados-table-pix';
import {EndpointsMerge} from '../common/endpointsmerge';
import {PixOutputDTO} from './../../pages/meu-negocio/despesas/models/pix-output-dto';

@Injectable({
  providedIn: 'root',
})
export class ConciliacaoVendasService {
  constructor(private apiService: ApiService) {}

  getConsultaListaTabelaPix(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<DadosTablePix[]>> {
    return this.apiService.get(
      EndpointsMerge.PIX_CONSULTAR_LISTA,
      params,
      ngBlockUI
    );
  }

  getConsultarAgenciaContaClientePix(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<DataPixAgContaModel>> {
    return this.apiService.get(
      EndpointsMerge.PIX_CONSULTAR_AG_CONTA,
      params,
      ngBlockUI
    );
  }

  getListaPixPorTipo(
    params,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<PixOutputDTO>> {
    return this.apiService.post(
      EndpointsMerge.PIX_CONSULTAR_POR_TIPO,
      params,
      null,
      ngBlockUI
    );
  }
}
