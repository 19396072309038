import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { BlockUIModule } from 'ng-block-ui';
import { BlocoCaixaComponent } from './bloco-caixa.component';
import { MaisInfoModule } from 'src/app/merge/shared/common/components/mais-info/mais-info.module';

@NgModule({
    declarations: [BlocoCaixaComponent],
    imports: [
        CommonModule,
        HighchartsChartModule,
        MaisInfoModule,
        BlockUIModule.forRoot()
    ],
    exports: [BlocoCaixaComponent],
})
export class BlocoCaixaModule { }
