import { AgendaCartoesMovimentacaoMesComponent } from './agenda-cartoes-movimentacao-mes.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlockUIModule } from 'ng-block-ui';

@NgModule({
  declarations: [AgendaCartoesMovimentacaoMesComponent],
  imports: [CommonModule, HighchartsChartModule, BlockUIModule.forRoot()],
  exports: [AgendaCartoesMovimentacaoMesComponent],
})
export class AgendaCartoesMovimentacaoMesModule {}
