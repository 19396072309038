import { PagesService } from '../../../../../shared/services/pages.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IVenda } from '../../../../meu-negocio/vendas-a-receber/models/extrato-vendas';
import { ExtratoVendasStatusConciliacao } from '../../../../meu-negocio/vendas-a-receber/models/extrato-vendas-status-conciliacao';
import { AlertService, UtilsMensagem } from 'merge-common-ng';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-conciliacao-vendas-atualizar',
  templateUrl: './conciliacao-vendas-atualizar.component.html',
  styleUrls: ['./conciliacao-vendas-atualizar.component.css'],
})
export class ConciliacaoVendasAtualizarComponent implements OnChanges {
  @Input() vendaEmAtualizacao: IVenda[];
  @Input() vendaEmAtualizacaoLenght: number;

  @Output() cancelarAtualizacao = new EventEmitter<any>();
  @Output() atualizaComponentePai = new EventEmitter<any>();

  @BlockUI('bui-conciliacao-vendas-atualizar')
  buiConciliacaoVendasAtualizar: NgBlockUI;

  statusConciliacao = ExtratoVendasStatusConciliacao;
  totalBruto: number;
  totalLiquido: number;

  constructor(
    private pagesService: PagesService,
    protected alertService: AlertService,
    private utilMensagem: UtilsMensagem
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.changesLista();
  }

  changesLista(): void {
    this.totalBruto = 0;
    this.totalLiquido = 0;

    this.vendaEmAtualizacao.forEach((venda) => {
      this.totalBruto += venda.valorBruto;
      this.totalLiquido += venda.valorPago;
    });
  }

  cancelar() {
    this.cancelarAtualizacao.emit();
  }

  marcarComoPendente() {
    const params = {
      codigoStatusConciliacao: this.statusConciliacao.PENDENTE,
      listaVendaId: [],
    };

    this.conciliarVendas(params);
  }

  marcarComoDivergente() {
    const params = {
      codigoStatusConciliacao: this.statusConciliacao.DIVERGENTE,
      listaVendaId: [],
    };

    this.conciliarVendas(params);
  }

  marcarComoConferido() {
    const params = {
      codigoStatusConciliacao: this.statusConciliacao.CONFERIDO,
      listaVendaId: [],
    };

    this.conciliarVendas(params);
  }

  conciliarVendas(params) {
    this.vendaEmAtualizacao.forEach((item) => params.listaVendaId.push(item.id));

    this.pagesService
      .setConciliarVendas(params, this.buiConciliacaoVendasAtualizar)
      .subscribe((data: any) => {
        this.utilMensagem.exibirMensagens(data.mensagens);
        this.sucessoAtualizacao(params.codigoStatusConciliacao);
      });
  }

  sucessoAtualizacao(statusConciliacao: ExtratoVendasStatusConciliacao) {
    this.atualizaComponentePai.emit(statusConciliacao);
  }
}
