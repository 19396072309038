import {
  TypeEnum,
  AlignEnum,
  FormatEnum,
} from './../../../../../shared/common/components/data-table/interfaces/column-detail';
import { ColumnDetail } from 'src/app/merge/shared/common/components/data-table/interfaces/column-detail';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DataInputComRegra, Empresa } from 'merge-common-ng';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { TemplateService } from 'src/app/merge/pages/template/template.service';
import { IFiltroDatas } from 'src/app/merge/shared/common/interfaces/filtro-datas';
import { TimestampBehaviourService } from 'src/app/merge/shared/services/timestamp-behaviour.service';

import { OperacaoCreditoService } from './../../../../../shared/services/operacao-credito.service';
import { ConsultaPorPeriodoDTO } from './../../models/consulta-por-periodo-dto';
import { DadosOperacaoCreditoDto } from './../../models/dados-operacao-credito-dto';
import { OperacaoCreditoDto } from './../../models/operacao-credito-dto';

@Component({
  selector: 'app-operacao-credito',
  templateUrl: './operacao-credito.component.html',
  styleUrls: ['./operacao-credito.component.css'],
})
export class OperacaoCreditoComponent implements OnInit {
  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  dataSelecionada: DataInputComRegra;
  dadosOperacaoCredito: DadosOperacaoCreditoDto;

  @BlockUI('bui-despesas-operacao-credito')
  buiDepesasOperacaoCredito: NgBlockUI;

  defaultDataSort = 'dataRealizacao';
  columnsDetails: ColumnDetail[] = [
    {
      dataKey: 'dataLancamento',
      header: 'DATA REALIZADO',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'contrato',
      header: 'CONTRATO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'produto',
      header: 'PRODUTO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'valor',
      header: 'VALOR',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.CURRENCYBR,
    },
  ];

  constructor(
    private operacaoCreditoService: OperacaoCreditoService,
    private mensagemService: ToastrService,
    private timestampService: TimestampBehaviourService,
    private templateService: TemplateService,
    private datePipe: DatePipe
  ) {
    this.dadosOperacaoCredito = new DadosOperacaoCreditoDto();
    this.dadosOperacaoCredito.operacoesCredito = [];
  }

  ngOnInit(): void {
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this._setFiltroDatas(data));

    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));

    this.timestampService.updateRegraCalendario();
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);
    this.timestampService.getCalendarioSubject().subscribe((date) => {
      this.dataSelecionada = date;
    });
    this._carregarOperacoesCredito();
  }

  getContratoFormatado(contrato: string): string {
    if (contrato.length > 5) {
      return '*****' + contrato.substring(contrato.length - 5);
    }

    return '*****' + contrato;
  }

  private _carregarOperacoesCredito(): void {
    this.operacaoCreditoService
      .getListaOperacoesCreditoNoPeriodo(
        this._parametrosPesquisa(),
        this.buiDepesasOperacaoCredito
      )
      .subscribe(
        (resposta) => {
          this.dadosOperacaoCredito = resposta.dados;
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  private _setFiltroDatas(data: DataInputComRegra): void {
    this.dataSelecionada = data;
    this.filtroDatas = this.timestampService.translateDateForInput(data);
  }

  private _parametrosPesquisa(): ConsultaPorPeriodoDTO {
    const consultaPorPeriodoDTO = new ConsultaPorPeriodoDTO();
    consultaPorPeriodoDTO.dataInicial = this.filtroDatas.dataInicial;
    consultaPorPeriodoDTO.dataFinal = this.filtroDatas.dataFinal;

    return consultaPorPeriodoDTO;
  }
}
