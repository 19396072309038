import {
  TypeEnum,
  AlignEnum,
  FormatEnum,
} from './../../../../../shared/common/components/data-table/interfaces/column-detail';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Component, OnInit } from '@angular/core';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { ITransferenciaTedDoc } from '../../../../../shared/common/interfaces/transferencia-ted-doc';
import { HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { RelatoriosService } from 'src/app/merge/shared/services/relatorios.service';
import { IFiltroDatas } from '../../../../../shared/common/interfaces/filtro-datas';
import { TedDocService } from '../../../../../shared/services/ted-doc.service';
import { DataInputComRegra } from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom-enum/data-input';
import { TimestampBehaviourService } from '../../../../../shared/services/timestamp-behaviour.service';
import { TemplateService } from '../../../../template/template.service';
import moment from 'moment';
import { ColumnDetail } from 'src/app/merge/shared/common/components/data-table/interfaces/column-detail';

@Component({
  selector: 'app-ted-doc',
  templateUrl: './ted-doc.component.html',
  styleUrls: ['./ted-doc.component.css'],
})
export class TedDocComponent implements OnInit {
  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  dataSelecionada: DataInputComRegra;

  @BlockUI('bui-transferencias-externas')
  buiTransferenciasExternas: NgBlockUI;

  transferencias: ITransferenciaTedDoc[];
  currentPage: number;
  pageSize: number;
  bancos: number[];

  defaultDataSort = 'data';

  columnsDetails: ColumnDetail[] = [
    {
      dataKey: 'data',
      header: 'DATA',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'codigoBanco',
      header: 'BANCO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'nome',
      header: 'PAGADOR',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'cpfCnpj',
      header: 'CPF/CNPJ',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'numeroDocumento',
      header: 'N. DOCUMENTO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'valor',
      header: 'VALOR',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.CURRENCYBR,
    },
  ];

  constructor(
    private tedDocService: TedDocService,
    private mensagemService: ToastrService,
    private serviceRelatorio: RelatoriosService,
    private timestampService: TimestampBehaviourService,
    private templateService: TemplateService
  ) {
    this.currentPage = 1;
    this.pageSize = 10;
    this.estabelecimento = null;
    this.transferencias = [];
  }

  ngOnInit() {
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this._setFiltroDatas(data));
    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));
    this.timestampService.updateRegraCalendario();
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);

    this.listarTransferencias();
  }

  private _setFiltroDatas(data: DataInputComRegra): void {
    this.dataSelecionada = data;
    this.filtroDatas = this.timestampService.translateDateForInput(data);
  }

  getQuantidadeTransferenciasPeriodo(): number {
    if (!this.transferencias) {
      return 0;
    } else {
      return this.transferencias.length;
    }
  }

  getValorTotalTransferenciasPeriodo(): number {
    if (!this.transferencias) {
      return 0;
    } else {
      return this.transferencias.reduce(
        (total: number, transferencia) => total + transferencia.valor,
        0
      );
    }
  }

  listarTransferencias(): void {
    const dataInicial = moment(this.filtroDatas.dataInicial).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const dataFinal = moment(this.filtroDatas.dataFinal).set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    const params = new HttpParams()
      .set('dataInicial', dataInicial?.format())
      .set('dataFinal', dataFinal?.format());

    this.tedDocService
      .listarTransferenciasRecebidas(params, this.buiTransferenciasExternas)
      .subscribe(
        (resposta) => {
          this.transferencias = resposta.dados;
          this._setBancos();
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  private _setBancos(): void {
    const bancos = new Set<number>();

    for (const transferencia of this.transferencias) {
      bancos.add(transferencia.codigoBanco);
    }

    this.bancos = Array.from(bancos).sort((a, b) => a - b);
  }

  montarParametros(): HttpParams {
    const dataInicial = moment(this.filtroDatas.dataInicial).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const dataFinal = moment(this.filtroDatas.dataFinal).set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    return new HttpParams()
      .set('dataInicial', dataInicial.format())
      .set('dataFinal', dataFinal.format());
  }

  gerarRelatorioRecebimentosTedDoc() {
    this.serviceRelatorio.gerarRelatorioRecebimentosTedDoc(
      this.montarParametros()
    );
  }
}
