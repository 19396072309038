import {
    AlignEnum,
    ColumnDetail,
    FormatEnum,
    TypeEnum,
} from './../../../../../shared/common/components/data-table/interfaces/column-detail';
import { Component, OnInit } from '@angular/core';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { PagesService } from '../../../../../shared/services/pages.service';
import { ToastrService } from 'ngx-toastr';
import { IExtratoOrdens } from './interfaces/extrato-ordens-bancarias';
import { ConsultaOrdensBancariasDto } from './interfaces/requisicao-ordens-bancarias';
import { IOrdem } from './interfaces/ordem-bancaria';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IFiltroDatas } from '../../../../../shared/common/interfaces/filtro-datas';
import { TimestampBehaviourService } from '../../../../../shared/services/timestamp-behaviour.service';
import { TemplateService } from '../../../../template/template.service';
import { DataInputComRegra } from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom-enum/data-input';
interface IFiltroOrdem {
  valor: string;
  codigoBancoCOMPE: string;
}

interface IResumoPaginacao {
  de: number;
  ate: number;
  total: number;
}

@Component({
  selector: 'app-ordens-bancarias',
  templateUrl: './ordens-bancarias.component.html',
  styleUrls: ['./ordens-bancarias.component.css'],
})
export class OrdensBancariasComponent implements OnInit {
  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  dataSelecionada: DataInputComRegra;
  @BlockUI('bui-bloco-ordens') buiBlocoOrdens: NgBlockUI;

  extratoOrdens: IExtratoOrdens;

  filtroOrdem: IFiltroOrdem;
  resumoPaginacao: IResumoPaginacao;
  currentPage: number;
  pageSize: number;
  mostrarDetalhamento: boolean;
  ordemSelecionada: IOrdem;

  defaultDataSort = 'data';

  columnsDetails: ColumnDetail[] = [
    {
      dataKey: 'dataCreditoConta',
      header: 'DATA CREDITO',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'nomePagador',
      header: 'PAGADOR',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'numeroCnpjPagador',
      header: 'CNPJ',
      type: TypeEnum.CNPJ,
      align: AlignEnum.LEFT,
      format: FormatEnum.CNPJ,
    },
    {
      dataKey: 'valorCredito',
      header: 'VALOR',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.CURRENCYBR,
    },
  ];

  constructor(
    private pagesService: PagesService,
    private mensagemService: ToastrService,
    private timestampService: TimestampBehaviourService,
    private templateService: TemplateService
  ) {
    this.currentPage = 1;
    this.pageSize = 10;
    this.extratoOrdens = {
      quantidadeTotalRegistro: 0,
      valorTotal: 0,
      listaOrdemBancario: [],
    };
  }

  ngOnInit() {
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this._setFiltroDatas(data));
    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));
    this.timestampService.updateRegraCalendario();
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);

    this._listarOrdens();
  }

  private _setFiltroDatas(data: DataInputComRegra): void {
    this.dataSelecionada = data;
    this.filtroDatas = this.timestampService.translateDateForInput(data);
  }

  private _listarOrdens(): void {


    const body = new ConsultaOrdensBancariasDto();
    body.dataInicioPesquisa = this.filtroDatas.dataInicial;
    body.dataFimPesquisa = this.filtroDatas.dataFinal;
    this.pagesService.getExtratoOrdens(body, this.buiBlocoOrdens).subscribe(
      (resposta) => {
        this.extratoOrdens = resposta.dados;
      },
      (error) => {
        this.mensagemService.error(error.erros[0].message);
      }
    );
  }


  detalhar(ordemSelecionada) {
    this.ordemSelecionada = ordemSelecionada;
  }
}
