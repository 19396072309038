import { AlertService, UtilsMensagem } from 'merge-common-ng';
import { Component, OnInit } from '@angular/core';
import { TimestampBehaviourService } from './shared/services/timestamp-behaviour.service';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'gnc-merge-front';
  showDisclaimer: boolean;
  constructor(
    public alertService: AlertService,
    private timestampBehavior: TimestampBehaviourService,
    private calendar: NgbCalendar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private matomoTracker: MatomoTracker
  ) {
    UtilsMensagem.alertService = alertService;
    this.timestampBehavior.setCalendarioSubject({
      toDate: calendar.getToday(),
      fromDate: calendar.getToday(),
      regra: ''
    });
  }

  ngOnInit(): void {
    this.matomoTracker.enableCrossDomainLinking();
    this.matomoTracker.trackAllContentImpressions();
    this.matomoTracker.trackPageView();

    this.showDisclaimer = true;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data) => {
          const pageTitle = data?.title
            ? `Painel - ${data.title}`
            : `Painel - Sua Plataforma Financeira`;
          this.titleService.setTitle(pageTitle);
        });
      });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  toogleShowDisclaimer(e) {
    this.showDisclaimer = e;
  }
}
