import { BlockUIModule } from 'ng-block-ui';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlocoRecebimentosComponent } from './bloco-recebimentos.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MaisInfoModule } from 'src/app/merge/shared/common/components/mais-info/mais-info.module';

@NgModule({
  declarations: [BlocoRecebimentosComponent],
  imports: [CommonModule, HighchartsChartModule, MaisInfoModule, BlockUIModule.forRoot()],
  exports: [BlocoRecebimentosComponent],
})
export class BlocoRecebimentosModule {}
