import { BlockUIModule } from 'ng-block-ui';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VisaoGeralRoutingModule } from './visao-geral-routing.module';
import { VisaoGeralComponent } from './visao-geral.component';
import { BlocoRecebimentosDiaModule } from './components/bloco-recebimentos-dia/bloco-recebimentos-dia.module';
import { BlocoDespesasDiaModule } from './components/bloco-despesas-dia/bloco-despesas-dia.module';
import { BlocoSaldoBancarioModule } from './components/bloco-saldo-bancario/bloco-saldo-bancario.module';
import { GraficoFluxoCaixaModule } from '../../shared/common/components/grafico-fluxo-caixa/grafico-fluxo-caixa.module';
import { ToastrModule } from 'ngx-toastr';
import { BlocoCaixaDiarioComponent } from './components/bloco-caixa-diario/bloco-caixa-diario.component';
import { HighchartsChartCustomModule } from 'merge-common-ng';
import { MaisInfoModule } from '../../shared/common/components/mais-info/mais-info.module';
import { BannerConciliadoraModule } from '../../shared/common/components/banner-conciliadora/banner-conciliadora.module';
import { InsightModule } from '../../shared/common/components/insights-notificacao/insight-notificacao.module';
import {MatChipsModule} from '@angular/material/chips';
import { NotificacaoToastModule } from '../../shared/common/components/notificacao-toast/notificacao-toast.module';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';

@NgModule({
  declarations: [VisaoGeralComponent, BlocoCaixaDiarioComponent],
  imports: [
    CommonModule,
    VisaoGeralRoutingModule,
    ToastrModule,
    BlocoRecebimentosDiaModule,
    BlocoDespesasDiaModule,
    BlocoSaldoBancarioModule,
    GraficoFluxoCaixaModule,
    MaisInfoModule,
    BannerConciliadoraModule,
    BlockUIModule.forRoot(),
    HighchartsChartCustomModule,
    InsightModule,
    MatChipsModule,
    NotificacaoToastModule,
    NgxMatomoTrackerModule
  ],
  exports: [VisaoGeralComponent],
})
export class VisaoGeralModule {}
