import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentsCommonModule } from '../../../../shared/common/components-commom.module';
import {
  DatepickerModuleEnum,
  NgbdDatepickerRangeComponent,
} from 'merge-common-ng';
import { BlockUIModule } from 'ng-block-ui';
import { ExtratoContaCorrenteComponent } from './extrato-conta-corrente.component';
import { ExtratoContaCorrenteRoutingModule } from './extrato-conta-corrente-rounting.module';

@NgModule({
  declarations: [ExtratoContaCorrenteComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ComponentsCommonModule,
    DatepickerModuleEnum,
    BlockUIModule.forRoot(),
    ExtratoContaCorrenteRoutingModule,
  ],
  providers: [NgbdDatepickerRangeComponent],
  exports: [ExtratoContaCorrenteComponent],
})
export class ExtratoContaCorrenteModule {}
