import { DataTableModule } from './../../../../../shared/common/components/data-table/data-table.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdensBancariasComponent } from './ordens-bancarias.component';
import { ComponentsCommonModule } from '../../../../../shared/common/components-commom.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { BlockUIModule } from 'ng-block-ui';
import { OrdensBancariasRoutingModule } from './ordens-bancarias-routing.module';
import { DatepickerModuleEnum } from 'merge-common-ng';
import { NavbarRecebimentosModule } from '../navbar-recebimentos/navbar-recebimentos.module';

@NgModule({
  declarations: [OrdensBancariasComponent],
  exports: [OrdensBancariasComponent],
  imports: [
    CommonModule,
    OrdensBancariasRoutingModule,
    ComponentsCommonModule,
    CurrencyMaskModule,
    BlockUIModule.forRoot(),
    NavbarRecebimentosModule,
    DatepickerModuleEnum,
    DataTableModule,
  ],
})
export class OrdensBancariasModule {}
