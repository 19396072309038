import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketplaceRoutingModule } from './marketplace-routing.module';
import { MarketplaceComponent } from './marketplace.component';
import { BlockUIModule } from 'ng-block-ui';

@NgModule({
  declarations: [MarketplaceComponent],
  imports: [CommonModule, MarketplaceRoutingModule, BlockUIModule],
  exports: [MarketplaceComponent],
})
export class MarketplaceModule { }
