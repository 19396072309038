import { Component, Input, OnInit } from '@angular/core';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { TemplateService } from '../template/template.service';
import { DataInputComRegra } from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom-enum/data-input';
import { TimestampBehaviourService } from '../../shared/services/timestamp-behaviour.service';
import { IFiltroDatas } from '../../shared/common/interfaces/filtro-datas';

@Component({
  selector: 'app-extrato',
  templateUrl: './extrato.component.html',
  styleUrls: ['./extrato.component.css'],
})
export class ExtratoComponent implements OnInit {
  @Input() estabelecimento: Empresa;

  filtroDatas: IFiltroDatas;
  date: DataInputComRegra;

  constructor(
    private templateService: TemplateService,
    private timestampService: TimestampBehaviourService
  ) {}

  ngOnInit(): void {
    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this.setFiltroDatas(data));
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);
    this.timestampService.getCalendarioSubject().subscribe((date) => {
      this.date = date;
    });
  }

  private setFiltroDatas(data: DataInputComRegra): void {
    this.filtroDatas = this.timestampService.translateDateForInput(data);
    this.date = data;
  }
}
