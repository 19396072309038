export enum TipoInteracaoAviso {
  CARREGADO = 0,
  LIDO = 1,
  CURTIDO = 2,
  NAO_CURTIDO = 3,
  EXCLUIDO = 4,
  CLICADO_NO_LINK = 5,
  LIDO_MENU_RECOMENDACOES = 6,
  CURTIDO_MENU_RECOMENDACOES = 7,
  CLICADO_MENU_RECOMENDACOES = 8
}
