import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateMenuService {
  private updateMenuSubject: Subject<void> = new Subject<void>();

  atualizarMenu(): any {
    this.updateMenuSubject.next();
  }

  atualizarMenuObservable(): Observable<void> {
    return this.updateMenuSubject.asObservable();
  }
}
