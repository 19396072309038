import { Component, OnInit } from '@angular/core';
import { PagesService } from 'src/app/merge/shared/services/pages.service';
import { IPesquisaNPS } from '../../models/pesquisa-nps';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-pesquisa-nps',
  templateUrl: './pesquisa-nps.component.html',
  styleUrls: ['./pesquisa-nps.component.css']
})
export class PesquisaNpsComponent {

  rating = 0;
  hoverState = 0;
  descricao: string;
  ratingGenerator: number[] = Array(10).fill(0).map((x, i) => i + 1);
  respostaPesquisa: IPesquisaNPS;
  @BlockUI('bui-pesquisa-nps')
  buiPesquisaNps: NgBlockUI;
  constructor(private pageService: PagesService, private mensagemService: ToastrService, private dialogRef: MatDialogRef<PesquisaNpsComponent>) { }

  enviarPesquisa(){
    this.respostaPesquisa = {
      nota: this.rating,
      comentario: this.descricao
    };
    this.pageService.enviarAvaliacao(this.respostaPesquisa, this.buiPesquisaNps).subscribe((res) => {
      this.mensagemService.success('Obrigado por nos avaliar!');
      this.dialogRef.close();
    },
    (err) => {
      this.mensagemService.error('Erro ao enviar a avaliação, tente novamente mais tarde.');
      this.dialogRef.close();
    }
    );
  }

}
