import { Component, Input, OnInit } from '@angular/core';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { TemplateService } from '../../template/template.service';
import { Movimentacoes } from './models/movimentacoes';
import { DataInputComRegra } from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom-enum/data-input';
import { TimestampBehaviourService } from '../../../shared/services/timestamp-behaviour.service';
import { IFiltroDatas } from '../../../shared/common/interfaces/filtro-datas';

@Component({
    selector: 'app-fluxo-caixa',
    templateUrl: './fluxo-caixa.component.html',
    styleUrls: ['./fluxo-caixa.component.css'],
})
export class FluxoCaixaComponent implements OnInit {

    @Input() estabelecimento: Empresa;

    filtroDatas: IFiltroDatas;
    date: DataInputComRegra;

    dadosDespesas: number;
    dadosRecebimentos: number;

    constructor(
        private templateService: TemplateService,
        private timestampService: TimestampBehaviourService
    ) {
        this.estabelecimento = null;
    }

    ngOnInit() {
        this.templateService
            .getEstabelecimentoSubject()
            .subscribe((est) => (this.estabelecimento = est));
        this.timestampService
            .getCalendarioSubject()
            .subscribe((data) => this.setFiltroDatas(data));
        this.timestampService.updateRegraCalendario();
    }

    datesSelectedToViews(event) {
        this.filtroDatas = this.timestampService.translateDateForEvent(event);
        this.timestampService.tratarDatapicker(this.filtroDatas);
        this.timestampService.getCalendarioSubject().subscribe((date) => {
            this.date = date;
        });
    }

    private setFiltroDatas(data: DataInputComRegra): void {
        this.filtroDatas = this.timestampService.translateDateForInput(data);
        this.date = data;
    }

    despesasParaGraficoCaixa(dados: number): void {
        this.dadosDespesas = dados;
    }

    recebimentosParaGraficoCaixa(dados: number): void {
        this.dadosRecebimentos = dados;
    }
}
