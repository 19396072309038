import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DataInput, Empresa} from 'gnc-merge-common-ng/dist/merge-common-ng';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ToastrService} from 'ngx-toastr';
import {PagesService} from 'src/app/merge/shared/services/pages.service';
import {TimestampBehaviourService} from 'src/app/merge/shared/services/timestamp-behaviour.service';
import {RelatoriosService} from '../../../../shared/services/relatorios.service';
import {TemplateService} from '../../../template/template.service';
import {LancamentoExtratoDto} from '../extrato-conta-corrente/interfaces/lancamento-extrato-dto';
import {ListaContasCartoesEmpresariasDTO} from './interfaces/lista-carta-empresarial-dto';
import {ExtratoFaturaCartaoService} from '../../../../shared/services/extrato-fatura-cartao.service';
import {ContasCartoesEmpresariasDto} from './interfaces/contas-cartoes-empresarias-dto';
import {ConsultarFaturasFechadasOutputDTO, ListaFaturasFechadas} from './interfaces/consultar-faturas-fechadas-output-dto';
import {ConsultaFaturasInputDTO} from './interfaces/consulta-faturas-input-dto';
import {UtilitiesService} from '../../../../shared/services/utilities.service';
import {ConsultarLancamentosFaturaFuturaInput} from './interfaces/consultar-lancamentos-fatura-futura-input';
import {ConsultarLancamentosFuturosFaturasOutput} from './interfaces/consultar-lancamentos-futuros-faturas-output';
import {IFiltroDatas} from 'src/app/merge/shared/common/interfaces/filtro-datas';

@Component({
  selector: 'app-extrato-cartao-credito',
  templateUrl: './extrato-cartao-credito.component.html',
  styleUrls: ['./extrato-cartao-credito.component.css']
})
export class ExtratoCartaoCreditoComponent implements OnInit, OnChanges {

  @Input() estabelecimento: Empresa;

  extrato: LancamentoExtratoDto[];

  @BlockUI('bui-lista-conta-empresarial') buiListaContaEmpresarial: NgBlockUI;
  @BlockUI('bui-extrato-conta-empresarial') buiExtratoContaEmpresarial: NgBlockUI;

  filtroDatas: IFiltroDatas;
  date: DataInput;
  activeId: number;
  lastIndex: number;

  contasEmpresariais: ContasCartoesEmpresariasDto;
  contaEmpresarialSelecionada: ListaContasCartoesEmpresariasDTO;
  faturasFechadas: ConsultarFaturasFechadasOutputDTO;
  listaFaturas: Array<ListaFaturasFechadas>;
  faturaFutura: ConsultarLancamentosFuturosFaturasOutput;
  pageName: string;
  exibirOferta: boolean;

  constructor(
    private pagesService: PagesService,
    private mensagemService: ToastrService,
    private relatorioService: RelatoriosService,
    private templateService: TemplateService,
    private timestampService: TimestampBehaviourService,
    private extratoCartao: ExtratoFaturaCartaoService,
    private utils: UtilitiesService
  ) {}

  ngOnInit(): void {
    this.pageName = 'Fatura do Cartão de Crédito';
    this.listarContasEmpresariais(); // verificar se existe
    this.exibirOferta = false;
    if (this.contaEmpresarialSelecionada != null && this.contaEmpresarialSelecionada.numeroContaCartao !== 0) {
      this.consultarFaturasAntigas();
    }
  }
  listarContasEmpresariais(): void {
    this.extratoCartao
      .consultarContasCartoesEmpresariais(this.buiListaContaEmpresarial)
      .subscribe(
        (resposta): void => {
          this.contasEmpresariais = resposta.dados;
          if (this.contasEmpresariais.listaPesquisaCartao.length > 0) {
            this.contaEmpresarialSelecionada = this.contasEmpresariais.listaPesquisaCartao[0];
            this.consultarFaturasAntigas();
            this.consultarLancamentosFuturasFaturas();
          } else {
            this.exibirOferta = true;
          }
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );

  }

  public consultarFaturasAntigas() {
    const input: ConsultaFaturasInputDTO = {
      numeroContaCartao: this.contaEmpresarialSelecionada.numeroContaCartao
    };
    this.extratoCartao.consultarFaturasCartoesEmpresariais(input, this.buiExtratoContaEmpresarial)
      .subscribe((fatura) => {
        if (fatura !== null && fatura.dados !== null && fatura.erros.length === 0) {
          this.faturasFechadas = fatura.dados;
          this.listaFaturas = this.faturasFechadas.listaFaturasFechadas.reverse();
          this.lastIndex = this.listaFaturas.length - 1;
          this.activeId = this.listaFaturas.length - 1;
        }
      });
  }
  public tratarNav(fatura, index) {
    return index === this.lastIndex ? 'Próximas faturas' :
        this.utils.getMonth(Number(fatura.mesCorrente)).concat('/').concat(fatura.anoCorrente);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.contaEmpresarialSelecionada != null && this.contaEmpresarialSelecionada.numeroContaCartao !== 0) {
      this.consultarFaturasAntigas();
      this.consultarLancamentosFuturasFaturas();
    }
  }
  consultarLancamentosFuturasFaturas() {
    const input: ConsultarLancamentosFaturaFuturaInput = {
      numeroContaCartao: this.contaEmpresarialSelecionada.numeroContaCartao
    };
    this.extratoCartao.consultarFaturasFuturasCartoesEmpresariais(input)
      .subscribe((response) => {
        if (response != null && response.dados != null) {
          this.faturaFutura = response.dados;
        }
      });
  }

  tratarCartao(numeroPlastico: number | undefined) {
    const cartao = String(numeroPlastico);
    if (cartao === '0') {
      return '****';
    }
    const cod = cartao.slice(cartao.length - 4, cartao.length);
    const d = cartao.slice(0, (cartao.length) - 5).replace(/\d+/g, '****.****.****.');
    return d.concat(cod);

  }
}
