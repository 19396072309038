import { Injectable } from '@angular/core';
import { ApiService, Hosts, Response } from 'merge-common-ng';
import { INotificacoesAvisoOrientacaoCliente } from './interfaces/notificacoes-aviso-orientacao-cliente';
import { Observable } from 'rxjs';
import { NgBlockUI } from 'ng-block-ui';
import { IInteracaoExibicaoAvisoCliente } from './interfaces/interacao-exibicao-aviso-cliente';
import { IAvisoOrientacaoCliente } from './interfaces/aviso-orientacao-cliente';
import { TipoInteracaoAviso } from './enums/tipo-interacao-aviso.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificacoesService {
  private readonly NOTIFICACOES_AVISOS_URL = 'api/avisos-orientacao-cliente';
  private readonly INTERACOES_AVISOS_URL =
    'api/interacoes-exibicao-avisos-cliente';

  constructor(public apiService: ApiService) {}

  listarNotificacoes(
    ngBlockUI?: NgBlockUI
  ): Observable<Response<INotificacoesAvisoOrientacaoCliente>> {
    return this.apiService.get(
      this.NOTIFICACOES_AVISOS_URL,
      null,
      ngBlockUI,
      true,
      Hosts.HOST_ANA
    );
  }

  salvarInteracao(
    body: IInteracaoExibicaoAvisoCliente,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<number>> {
    return this.apiService.post(
      this.INTERACOES_AVISOS_URL,
      body,
      null,
      ngBlockUI,
      Hosts.HOST_ANA
    );
  }

  marcarComoLidas(
    avisos: IAvisoOrientacaoCliente[],
    ngBlockUI?: NgBlockUI,
    isRecomendacoes: boolean = false,
  ): void {
    const avisosNaoLidos: IAvisoOrientacaoCliente[] = avisos.filter(
      (a) => !a.avisoLido
    );

    avisosNaoLidos.forEach(async (a) => {
      const codigoInteracao = isRecomendacoes ? TipoInteracaoAviso.LIDO_MENU_RECOMENDACOES : TipoInteracaoAviso.LIDO;
      const body: IInteracaoExibicaoAvisoCliente = {
        numeroAviso: a.numeroAviso,
        codigoInteracao,
      };
      await this.salvarInteracao(body, ngBlockUI).subscribe((r) => r);
    });
  }
}
