import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

type Directions = 'top' | 'down' | 'left' | 'right';
declare var $: any;

@Component({
  selector: 'app-mais-info',
  templateUrl: './mais-info.component.html',
  styleUrls: ['./mais-info.component.css'],
})
export class MaisInfoComponent implements OnInit {
  @Input() body: string;
  @Input() direction: Directions;

  ngOnInit() {
    $('[data-toggle="popover"]').popover({ trigger: 'hover' });
  }
}
