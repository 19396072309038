import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { PagesService } from 'src/app/merge/shared/services/pages.service';
import { AuthService, User, UtilsApp } from 'merge-common-ng';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-aceite-termos',
  templateUrl: './aceite-termos.component.html',
  styleUrls: ['./aceite-termos.component.css'],
})
export class AceiteTermosComponent implements OnInit, AfterContentChecked {
  user: User;
  termo: any;
  textoButton: string;
  termosParaModal: any;
  termosParaAceite: any;
  avisoUsuario: string;
  termoDeUso: any;
  politicaPrivacidade: any;
  disabledViewAll = true;

  constructor(
    private pagesService: PagesService,
    private authService: AuthService,
    private mensagemService: ToastrService
  ) {}

  ngOnInit(): void {
    this.user = UtilsApp.getCurrentUser();

    if (this.user != null) {
      this.getConsultarTermosUsuario();
    }
  }

  ngAfterContentChecked() {
    this.openModal();
  }

  logout() {
    this.hideModal();
    this.authService.logout();
  }

  async getConsultarTermosUsuario() {
    await this.pagesService
      .getConsultarTermosUsuario()
      .subscribe((termos: any) => {
        if (termos?.length === 0) {
          return;
        }

        this.termosParaModal = [];
        this.termosParaAceite = termos;

        this.termosParaAceite.forEach((termo) => {
          switch (termo.tipoCodigo) {
            case 1:
              this.termoDeUso = termo;

              break;

            case 2:
              this.politicaPrivacidade = termo;

              break;

            default:
              break;
          }
        });
      });
  }

  openModal(): void {
    if (
      this.termosParaAceite?.length > 0 &&
      (!this.termoDeUso?.aceite || !this.politicaPrivacidade?.aceite)
    ) {
      (<any> $ ('#modalTermo')).modal('show');
    }
  }

  download(termo): void {
    termo.lido = true;

    const byteArray = new Uint8Array(
      atob(termo.termo)
        .split('')
        .map((char) => char.charCodeAt(0))
    );

    const blob = new Blob([byteArray], { type: 'application/pdf' });

    const url = window.URL.createObjectURL(blob);

    window.open(url);
  }

  setAceiteTermo(): void {
    this.disabledViewAll = false;
    this.termosParaAceite.forEach((termo) => {
      if (!termo.aceite) {
        this.disabledViewAll = true;
      }
    });
  }

  salvar() {
    const params = {
      termos: [],
      dataAceiteTermo: new Date(),
    };

    params.termos = this.validarAceiteTermo();

    if (params.termos.length === 0) {
      return;
    }

    this.pagesService.setAceiteTermosUsuario(params).subscribe((retorno) => {
      this.mensagemService.success(retorno.mensagens[0].message);
      this.hideModal();
    });
  }

  validarAceiteTermo() {
    const termos = [];

    this.termosParaAceite.forEach((termo) => {
      if (termo.aceite) {
        termos.push({
          tipoCodigo: termo.tipoCodigo,
          versao: termo.versao,
        });
      }
    });
    return termos;
  }
  voltarAoModalDosAceites() {
    (<any> $('#avisoUsuario')).modal('hide');
  }

  hideModal(): void {
    (<any> $('#avisoUsuario')).modal('hide');
    (<any> $('#modalTermo')).modal('hide');
    (<any> $('.modal-backdrop')).css('z-index', '-999');
  }
}
