// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { version } from '../../package.json';

export const environment = {
  production: true,
  VERSION: version,
  authURL: 'https://auth.painel.bb.com.br/',
  apiURL: 'https://api.painel.bb.com.br/',
  apiIteBBURL: 'https://api.ite.painel.bb.com.br/',
  apiOpfURL: 'https://api.opf.painel.bb.com.br/',
  apiAnaURL: 'https://api.ana.painel.bb.com.br/',
  clientId: 'merge-app',
  clientSecret: 'RfOe7KrQ/EWM/krKkH7WkrYT+tB6dOYR',
  bbAuthURL: 'https://oauth.bb.com.br/',
  bbClientId: 'eyJpZCI6ImJmMDIyYmQtYmQzNS00YSIsImNvZGlnb1B1YmxpY2Fkb3IiOjAsImNvZGlnb1NvZnR3YXJlIjoxNTczNCwic2VxdWVuY2lhbEluc3RhbGFjYW8iOjF9',
  matomoSiteId: '373',
  matomoURL: 'https://eni.bb.com.br/eni2/',
  autoatendimentoAuthURL: 'https://autoatendimento.bb.com.br/cfe-acesso/gcs/statics/login/login.novo.bb?tipo=st_cpf&origemLoginCfe=sim&tipos=ST_CPF%2CST_J&urlRetorno=..%2F..%2F..%2F..%2Fapf-apj-gcs&relacionamentos=false#/st-cpf',
  bbClusterKubernetes: 'PROD',
  authnURL: 'https://authn.bb.com.br/',
  aapjBaseUrl: 'https://autoatendimento.bb.com.br',
  aapjOptinRelativeUrl: '/apf-apj-autoatendimento/index.html?v=2.3.56#/template/~2Fapf-apj-png-aclt-crege%23~2Fopt-in',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 *
 * import 'zone.js/dist/zone-error';  // Included with Angular CLI.
 */
