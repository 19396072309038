import { Component, Inject, OnInit } from '@angular/core';
import { ConfiguracoesComponent } from '../../configuracoes.component';
import { PagesService } from '../../../../shared/services/pages.service';
import { ToastrService } from 'ngx-toastr';
import {BlockUIInterceptor} from 'gnc-merge-common-ng/dist/merge-common-ng';
@Component({
  selector: 'app-info-vendas-cartao',
  templateUrl: './info-vendas-cartao.component.html',
  styleUrls: ['./info-vendas-cartao.component.css']
})
export class InfoVendasCartaoComponent implements OnInit {
  constructor(
    private pagesService: PagesService,
    private mensagemService: ToastrService,
    private blockUi: BlockUIInterceptor
  ) { }

  ngOnInit(): void {
  }

  registrarEmpresaConciliadora() {

    this.pagesService.registrarEmpresaConciliadora().subscribe((resposta) => {
      window.open(ConfiguracoesComponent.URL_CIELO_BB, 'winname',
        'directories=no, titlebar=yes, toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=500, height=500');
    }, (error) => {
      this.mensagemService.error(error.erros[0].message);
    });
  }

}
