import { Injectable } from '@angular/core';
import { ApiService, Hosts, Response } from 'merge-common-ng';
import { NgBlockUI } from 'ng-block-ui';
import { Observable } from 'rxjs';
import { AutorizacoesOpf } from './dto/autorizacoes-opf';
import { AutorizacaoOpf } from './dto/autorizacao-opf';

@Injectable({
  providedIn: 'root',
})
export class AutorizacoesOpfService {
  constructor(public apiService: ApiService) {}

  public static readonly ENDPOINT_CONSULTA_AUTORIZACOES_OPF =
    'autorizacoes-compartilhamento/';

  listarAutorizacoesOpf(
    ngBlockUI?: NgBlockUI
  ): Observable<Response<AutorizacaoOpf[]>> {
    return this.apiService.get(
      AutorizacoesOpfService.ENDPOINT_CONSULTA_AUTORIZACOES_OPF,
      null,
      ngBlockUI,
      true,
      Hosts.HOST_OPF
    );
  }
}
