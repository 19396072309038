import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IVenda } from '../../../meu-negocio/vendas-a-receber/models/extrato-vendas';

@Component({
    selector: 'app-conciliacao-venda-detalhe',
    templateUrl: './conciliacao-venda-detalhe.component.html',
    styleUrls: ['./conciliacao-venda-detalhe.component.css'],
})
export class ConciliacaoVendaDetalheComponent implements OnChanges, OnInit {

    @Input() vendaEmDetalhamento: IVenda;

    @Output() fecharDetalhamento = new EventEmitter<any>();

    // Modal
    private modalRef: NgbModalRef;
    @ViewChild('modal') private modalContent: TemplateRef<ConciliacaoVendaDetalheComponent>;

    constructor(private modalService: NgbModal) { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.vendaEmDetalhamento.currentValue) {
            this.open();
        }
    }

    open(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.modalRef = this.modalService.open(this.modalContent, { size: 'xl', backdrop: 'static' });
            this.modalRef.result.then(resolve, resolve);
        });
    }

    async close(): Promise<void> {
        this.modalRef.close();
        this.fecharDetalhamento.emit();
    }
}
