import { BlockUIModule } from 'ng-block-ui';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlocoSaldoBancarioComponent } from './bloco-saldo-bancario.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MaisInfoModule } from 'src/app/merge/shared/common/components/mais-info/mais-info.module';
import { ComponentsCommonModule } from '../../../../shared/common/components-commom.module';

@NgModule({
  declarations: [BlocoSaldoBancarioComponent],
  imports: [
    CommonModule,
    HighchartsChartModule,
    MaisInfoModule,
    BlockUIModule.forRoot(),
    ComponentsCommonModule,
  ],
  exports: [BlocoSaldoBancarioComponent],
})
export class BlocoSaldoBancarioModule {}
