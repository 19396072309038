import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VendasBoletosValorLiquidoComponent} from './vendas-boletos-valor-liquido.component';
import {HighchartsChartModule} from 'highcharts-angular';

@NgModule({
  declarations: [VendasBoletosValorLiquidoComponent],
  imports: [CommonModule, HighchartsChartModule],
  exports: [VendasBoletosValorLiquidoComponent],
})
export class VendasBoletosValorLiquidoModule {}
