import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-agenda-cartoes-disclaimer-usuario-sem-autorizacao',
  templateUrl:
    './agenda-cartoes-disclaimer-usuario-sem-autorizacao.component.html',
  styleUrls: [
    './agenda-cartoes-disclaimer-usuario-sem-autorizacao.component.css',
  ],
})
export class AgendaCartoesDisclaimerUsuarioSemAutorizacaoComponent
  implements OnInit
{
  @Input() urlAAPJ: string;
  constructor() {}

  ngOnInit(): void {}

  abrirApj() {
    window.open(this.urlAAPJ, '_blank').focus();
  }
}
