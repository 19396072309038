import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { AuthBBService, UtilsApp, UtilsToken } from 'merge-common-ng';
import {
  Empresa,
  User,
} from 'node_modules/gnc-merge-common-ng/dist/merge-common-ng/public-api';
import { environment } from 'src/environments/environment';

import { AtualizadorContaCorrenteService } from '../../../shared/services/atualizador-conta-corrente.service';

@Component({
  selector: 'app-login-apj',
  templateUrl: './login-apj.component.html',
})
export class LoginApjComponent implements OnInit {
  public version;

  constructor(
    private router: Router,
    private authServiceBB: AuthBBService,
    private activatedRoute: ActivatedRoute,
    private atualizadorContaCorrenteService: AtualizadorContaCorrenteService,
    private matomoTracker: MatomoTracker
  ) { }

  ngOnInit(): void {
    this.version = environment.VERSION;

    this.activatedRoute.queryParams.subscribe((params) => {
      const bb_painel_pj = params['mciPj'] + ',' + params['chaveJ'];
      this.loginAPJ(bb_painel_pj);
    });
  }

  public loginAPJ(painelPJ: string) {
    this.authServiceBB.loginAPJ(painelPJ).subscribe(
      (data) => {
        this.loginAPJSuccess(data);
        this.matomoTracker.enableCrossDomainLinking();
        this.matomoTracker.trackAllContentImpressions();
        this.matomoTracker.trackPageView();
      },
      (error) => {
        this.router.navigate(['login']);
        // this.mensagemService.exibirMensagens(error);
        // this.authServiceBB.loginAuthn();
      }
    );
  }

  public loginAPJSuccess(data: any) {
    localStorage.clear();
    localStorage.setItem(
      UtilsToken.KEY_BB_ACCESS_TOKEN,
      data.tokenBB.access_token
    );
    localStorage.setItem(
      UtilsToken.KEY_BB_REFRESH_TOKEN,
      data.tokenBB.refresh_token
    );
    localStorage.setItem(
      UtilsToken.KEY_ACCESS_TOKEN,
      data.tokenPainel.access_token
    );
    localStorage.setItem(
      UtilsToken.KEY_REFRESH_TOKEN,
      data.tokenPainel.refresh_token
    );

    const user: User = data.usuario;
    user.empresas = new Array<Empresa>();
    user.empresas.push(data.empresa);
    localStorage.setItem(
      UtilsApp.KEY_CURRENT_USER,
      JSON.stringify(data.usuario)
    );
    localStorage.setItem(
      UtilsApp.KEY_CURRENT_EMPRESA,
      JSON.stringify(data.empresa)
    );

    this.atualizadorContaCorrenteService.atualizarContas();
    this.router.navigate(['pages']);
  }
}
