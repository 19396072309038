import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConciliacaoVendaDetalheComponent} from './conciliacao-venda-detalhe.component';

@NgModule({
  declarations: [ConciliacaoVendaDetalheComponent],
  imports: [CommonModule],
  exports: [ConciliacaoVendaDetalheComponent],
})
export class ConciliacaoVendaDetalheModule {}
