import {Pipe, PipeTransform} from '@angular/core';
import {IFiltroExtratoVendasBoletos} from '../interfaces/filtro-extrato-vendas-boletos';

@Pipe({
  name: 'filterExtratoVendasBoleto',
  pure: false,
})
export class FilterExtratoBoleto implements PipeTransform {
  transform(items: any[], filter: IFiltroExtratoVendasBoletos): any {
    if (!items || !filter) {
      return items;
    }

    if (filter.textoEstadoTitulo === '') {
      if (filter.valorAtualTituloCobranca === null) {
        return items;
      }
    }
    return items.filter((item) => {
      return (
        (!!!item.textoEstadoTitulo ||
          item.textoEstadoTitulo) &&
        (!!!item.valorAtualTituloCobranca ||
          item.valorAtualTituloCobranca.toString().search(filter.valorAtualTituloCobranca) !== -1 ||
          !!!item.valorAtualTituloCobranca ||
          item.valorAtualTituloCobranca
            .toString()
            .search(filter.valorAtualTituloCobranca.toString().replace(',', '.')) !== -1)
      );
    });
  }
}
