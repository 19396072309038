import { DataTableModule } from './../../../../../shared/common/components/data-table/data-table.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { DatepickerModuleEnum } from 'merge-common-ng';
import { OperacaoCreditoComponent } from './operacao-credito.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperacaoCreditoRoutingModule } from './operacao-credito-routing.module';
import { ComponentsCommonModule } from 'src/app/merge/shared/common/components-commom.module';
import { BlockUIModule } from 'ng-block-ui';
import { NavbarDespesasModule } from '../navbar-despesas/navbar-despesas.module';

@NgModule({
  declarations: [OperacaoCreditoComponent],
  exports: [OperacaoCreditoComponent],
  imports: [
    CommonModule,
    OperacaoCreditoRoutingModule,
    ComponentsCommonModule,
    FormsModule,
    NgxPaginationModule,
    BlockUIModule.forRoot(),
    NavbarDespesasModule,
    DatepickerModuleEnum,
    DataTableModule,
  ],
})
export class OperacaoCreditoModule {}
