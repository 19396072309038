import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Empresa} from 'merge-common-ng';
import { NotificacoesService } from './components/notificacoes/notificacoes.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private estabelecimento: Subject<Empresa> = new BehaviorSubject<Empresa>(
    new Empresa()
  );
  mostrarMsgNaoLidas: Subject<boolean> = new BehaviorSubject<boolean>(false);
  qntMsgNaoLidas = 0;

  constructor(
    private notificacoesService: NotificacoesService
  ) { }

  getEstabelecimentoSubject(): Observable<Empresa> {
    return this.estabelecimento.asObservable();
  }

  setEstabelecimentoSubject(data: Empresa): void {
    this.estabelecimento.next(data);
  }

  updateNotificacaoMsgNaoLidas(): void {
    this.notificacoesService.listarNotificacoes().subscribe(
      lista => {
        this.qntMsgNaoLidas = lista.dados.quantidadeNotificacoesNaoLidas;
        if (this.qntMsgNaoLidas !== 0) {
          this.mostrarMsgNaoLidas.next(true);
        } else {
          this.mostrarMsgNaoLidas.next(false);
        }
      }
    );
  }

  getMsgNaoLidas(): Observable<boolean> {
    return this.mostrarMsgNaoLidas.asObservable();
  }

  getQntMsgNaoLidas(): number {
    return this.qntMsgNaoLidas;
  }
}
