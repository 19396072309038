import {Injectable} from '@angular/core';
import {MatomoTracker} from '@ngx-matomo/tracker';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() {}

  public getMonth(monthNumber: number): string {
    const months = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ];
    return months[monthNumber - 1];
  }

}
