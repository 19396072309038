import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar-despesas',
  templateUrl: './navbar-despesas.component.html',
  styleUrls: ['./navbar-despesas.component.css'],
})
export class NavbarDespesasComponent {
  constructor() {}
}
