import { LoginApjComponent } from './login-apj.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginApjRoutingModule } from './login-apj-routing.module';

@NgModule({
  declarations: [LoginApjComponent],
  imports: [CommonModule, LoginApjRoutingModule],
  exports: [LoginApjComponent],
})
export class LoginApjModule {}
