import { AtualizadorContaCorrenteService } from '../../shared/services/atualizador-conta-corrente.service';
import {
  AuthBBService,
  AuthService,
  Empresa,
  Login,
  Response,
  User,
  UtilsApp,
  UtilsMensagem,
  UtilsToken,
} from 'merge-common-ng';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

declare global {
  interface Window {
    MyNamespace: any;
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public user = new Login();
  public concordo = false;
  public version;

  public _shown = false;
  @ViewChild('senha') input;
  @ViewChild('olho') src;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toast: ToastrService,
    private mensagemService: UtilsMensagem,
    private authServiceBB: AuthBBService,
    private atualizadorContaCorrenteService: AtualizadorContaCorrenteService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    if (!UtilsApp.isAuthenticated()) {
      localStorage.removeItem('notificacaoConciliadora');
    }
    this.version = environment.VERSION;
    this.activatedRoute.queryParams.subscribe((params) => {
      const code = params['code'];
      if (code) {
        this.loginBB(code);
      } else if (UtilsApp.isAuthenticated()) {
        this.router.navigate(['pages']);
      }
    });
  }

  public loginBB(code) {
    this.authServiceBB.login(code).subscribe(
      (data) => {
        this.loginBBSuccess(data);
      },
      (error) => {
        this.mensagemService.exibirMensagens(error);
      }
    );
  }

  public login(loginForm: NgForm) {
    this.authServiceBB.redirectOAuthBB();
  }

  public loginBBSuccess(data: any) {
    localStorage.clear();
    localStorage.setItem(
      UtilsToken.KEY_BB_ACCESS_TOKEN,
      data.tokenBB.access_token
    );
    localStorage.setItem(
      UtilsToken.KEY_BB_REFRESH_TOKEN,
      data.tokenBB.refresh_token
    );
    localStorage.setItem(
      UtilsToken.KEY_ACCESS_TOKEN,
      data.tokenPainel.access_token
    );
    localStorage.setItem(
      UtilsToken.KEY_REFRESH_TOKEN,
      data.tokenPainel.refresh_token
    );

    const user: User = data.usuario;
    user.empresas = new Array<Empresa>();
    user.empresas.push(data.empresa);
    localStorage.setItem(
      UtilsApp.KEY_CURRENT_USER,
      JSON.stringify(data.usuario)
    );
    localStorage.setItem(
      UtilsApp.KEY_CURRENT_EMPRESA,
      JSON.stringify(data.empresa)
    );

    this.atualizadorContaCorrenteService.atualizarContas();
    this.router.navigate(['pages']);
  }

  public loginSuccess(data: any) {
    localStorage.clear();
    localStorage.setItem(UtilsToken.KEY_ACCESS_TOKEN, data.access_token);
    localStorage.setItem(UtilsToken.KEY_REFRESH_TOKEN, data.refresh_token);

    this.authService.getUserAuth().subscribe((user) => {
      this.redirectPage(user);
    });
  }

  public redirectPage(user: Response<User>) {
    localStorage.setItem(UtilsApp.KEY_CURRENT_USER, JSON.stringify(user.dados));
    this.router.navigate(['pages']);
  }

  mostrarSenha() {
    this._shown = !this._shown;
    if (this._shown) {
      this.input.nativeElement.setAttribute('type', 'text');
      this.src.nativeElement.setAttribute(
        'src',
        '../assets/imagens/login/olho-fechado.svg'
      );
    } else {
      this.input.nativeElement.setAttribute('type', 'password');
      this.src.nativeElement.setAttribute(
        'src',
        '../assets/imagens/login/olho.svg'
      );
    }
  }
}
