import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatepickerModule, NgbdDatepickerRangeComponent, NgbdDatepickerRangeEnumComponent } from 'merge-common-ng';

import { ConfiguracoesRoutingModule } from './configuracoes-routing.module';
import { ConfiguracoesComponent } from './configuracoes.component';
import { InfoAgendaCartaoComponent } from './components/info-agenda-cartao/info-agenda-cartao.component';
import { InfoVendasCartaoComponent } from './components/info-vendas-cartao/info-vendas-cartao.component';

@NgModule({
  declarations: [ConfiguracoesComponent, InfoAgendaCartaoComponent, InfoVendasCartaoComponent],
  imports: [CommonModule, ConfiguracoesRoutingModule, DatepickerModule],
  providers: [NgbdDatepickerRangeComponent, NgbdDatepickerRangeEnumComponent],
  exports: [ConfiguracoesComponent],
})
export class ConfiguracoesModule { }
