import { DataTableModule } from 'src/app/merge/shared/common/components/data-table/data-table.module';
import { AgendaCartoesDetalheValorModule } from '../agenda-cartoes-detalhe-valor/agenda-cartoes-detalhe-valor.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgendaCartoesDetalhamentoComponent } from './agenda-cartoes-detalhamento.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { BlockUIModule } from 'ng-block-ui';

@NgModule({
  declarations: [AgendaCartoesDetalhamentoComponent],
  imports: [
    CommonModule,
    AgendaCartoesDetalheValorModule,
    NgxPaginationModule,
    NgxMaskModule,
    FormsModule,
    BlockUIModule.forRoot(),
    DataTableModule,
  ],
  exports: [AgendaCartoesDetalhamentoComponent],
})
export class AgendaCartoesDetalhamentoComponentModule {}
