import { DataTableModule } from './../../../../../shared/common/components/data-table/data-table.module';
import { BlockUIModule } from 'ng-block-ui';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConciliacaoVendasBoletosComponent } from './conciliacao-vendas-boletos.component';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { VendasBoletosValorLiquidoModule } from '../vendas-boletos-valor-liquido/vendas-boletos-valor-liquido.module';
import { ConciliacaoVendaDetalheBoletosModule } from './modal-conciliacao-venda-detalhe-boletos/conciliacao-venda-detalhe-boletos.module';
import { NavbarVendasAReceberModule } from '../navbar-vendas-a-receber/navbar-vendas-a-receber.module';
import { DatepickerModuleEnum } from 'merge-common-ng';
import { ConciliacaoVendasBoletosRoutingModule } from './conciliacao-vendas-boletos-routing.module';

@NgModule({
  declarations: [ConciliacaoVendasBoletosComponent],
  imports: [
    CommonModule,
    ConciliacaoVendasBoletosRoutingModule,
    FormsModule,
    NgxPaginationModule,
    VendasBoletosValorLiquidoModule,
    ConciliacaoVendaDetalheBoletosModule,
    BlockUIModule.forRoot(),
    NavbarVendasAReceberModule,
    DatepickerModuleEnum,
    DataTableModule,
  ],
  exports: [ConciliacaoVendasBoletosComponent],
})
export class ConciliacaoVendasBoletosModule {}
