import { formatCurrency } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  OnChanges,
} from '@angular/core';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import * as Highcharts from 'highcharts';
import { DadosGraficosVendaBoleto } from '../../../../../shared/common/interfaces/dados-graficos-venda-boleto';

@Component({
  selector: 'app-vendas-boletos-valor-liquido',
  templateUrl: './vendas-boletos-valor-liquido.component.html',
  styleUrls: ['./vendas-boletos-valor-liquido.component.css'],
})
export class VendasBoletosValorLiquidoComponent implements OnInit, OnChanges {
  @Input() estabelecimento: Empresa;
  @Input() dadosGraficoExternalValorUnitario: DadosGraficosVendaBoleto;
  @Input() dadosGraficoExternalTicketMedio: DadosGraficosVendaBoleto;
  @Input() dadosGraficoExternalMediaVendas: DadosGraficosVendaBoleto;
  @Input() errorConsultaDadosGrafico: boolean;

  public dadosGrafico = Highcharts;
  public optionsChartsValorUnitario: Highcharts.Options;
  public optionsChartsTicketMedio: Highcharts.Options;
  public optionsChartsMediaVendas: Highcharts.Options;

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  ngOnInit(): void {
    this.createChartsValorUnitario();
    this.createChartsValorUnitarioTicketMedio();
    this.createChartsMediaVendas();
  }

  ngOnChanges(): void {
    this.createChartsValorUnitario();
    this.createChartsValorUnitarioTicketMedio();
    this.createChartsMediaVendas();
  }

  createChartsValorUnitario() {
    this.dadosGrafico = Highcharts;
    this.optionsChartsValorUnitario = {
      chart: { type: 'column', height: 250, width: 300, backgroundColor: '' },
      legend: { enabled: false },
      title: { text: this.dadosGraficoExternalValorUnitario.title },
      subtitle: {
        text: this.getFormattedValue(
          this.dadosGraficoExternalValorUnitario.valorVenda
        ),
      },
      credits: { enabled: false },
      yAxis: {
        visible: false,
        grid: { enabled: false },
        accessibility: { enabled: false },
      },
      xAxis: { visible: false, margin: 0 },
      tooltip: {
        pointFormat: 'Valor: {point.y:,.2f}<br/> Dia: <strong>{point.x}</strong>',
      },
      plotOptions: {
        column: { pointPadding: 0.2, borderWidth: 0 },
        series: {
          zones: [
            { color: this.dadosGraficoExternalValorUnitario.color },
            { color: '#6b7eff' },
          ],
          pointStart: 1,
          marker: {
            enabled: false,
          },
        },
      },
    };
    this.optionsChartsValorUnitario.series =
      this.dadosGraficoExternalValorUnitario.dadosGrafico;
  }

  createChartsValorUnitarioTicketMedio() {
    this.dadosGrafico = Highcharts;
    this.optionsChartsTicketMedio = {
      chart: { type: 'column', height: 250, width: 300, backgroundColor: '' },
      legend: { enabled: false },
      title: { text: this.dadosGraficoExternalTicketMedio.title },
      subtitle: {
        text: this.getFormattedValue(
          this.dadosGraficoExternalTicketMedio.valorVenda
        ),
      },
      credits: { enabled: false },
      yAxis: {
        visible: false,
        grid: { enabled: false },
        accessibility: { enabled: false },
      },
      xAxis: { visible: false, margin: 0 },
      tooltip: {
        pointFormat: 'Valor: {point.y:,.2f}<br/> Dia: <strong>{point.x}</strong>',
      },
      plotOptions: {
        column: { pointPadding: 0.2, borderWidth: 0 },
        series: {
          zones: [
            { color: this.dadosGraficoExternalTicketMedio.color },
            { color: '#6b7eff' },
          ],
          pointStart: 1,
          marker: {
            enabled: false,
          },
        },
      },
    };
    this.optionsChartsTicketMedio.series =
      this.dadosGraficoExternalTicketMedio.dadosGrafico;
  }

  createChartsMediaVendas() {
    this.dadosGrafico = Highcharts;
    this.optionsChartsMediaVendas = {
      chart: { type: 'column', height: 250, width: 300, backgroundColor: '' },
      legend: { enabled: false },
      title: { text: this.dadosGraficoExternalMediaVendas.title },
      subtitle: {
        text:
          this.lessThanZero(this.dadosGraficoExternalMediaVendas.valorVenda) +
          ' venda(s)/dia',
      },
      credits: { enabled: false },
      yAxis: {
        visible: false,
        grid: { enabled: false },
        accessibility: { enabled: false },
      },
      xAxis: { visible: false, margin: 0 },
      tooltip: {
        formatter() {
          return 'Quantidade:' + this.y + '<br/> Dia: <strong>' + this.x + '</strong>';
        },
      },
      plotOptions: {
        column: { pointPadding: 0.2, borderWidth: 0 },
        series: {
          zones: [
            { color: this.dadosGraficoExternalMediaVendas.color },
            { color: '#6b7eff' },
          ],
          pointStart: 1,
          marker: {
            enabled: false,
          },
        },
      },
    };
    this.optionsChartsMediaVendas.series =
      this.dadosGraficoExternalMediaVendas.dadosGrafico;
  }

  getFormattedValue(value: number, currency = 'R$') {
    return formatCurrency(Number(value.toFixed(2)), this.locale, currency);
  }

  lessThanZero(value: number) {
    if (value === 0) {
      return value;
    }
    if (value < 1) {
      return 'menos que 1';
    }

    return value;
  }
}
