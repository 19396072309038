import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CartaoComponent } from './cartao.component';

const routes: Routes = [
  {
    path: '',
    component: CartaoComponent,
    data: { title: 'Meu Negócio - Despesas - Cartão' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CartaoRoutingModule {}
