import { NgBlockUI, BlockUIModule } from 'ng-block-ui';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraficoGeralComponent } from './grafico-geral.component';
import { GraficoFluxoCaixaModule } from '../../../../../shared/common/components/grafico-fluxo-caixa/grafico-fluxo-caixa.module';

@NgModule({
  declarations: [GraficoGeralComponent],
  imports: [CommonModule, GraficoFluxoCaixaModule, BlockUIModule.forRoot()],
  exports: [GraficoGeralComponent],
})
export class GraficoGeralModule {}
