import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AceiteTermosComponent} from './aceite-termos.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [AceiteTermosComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [AceiteTermosComponent]
})
export class AceiteTermosModule { }
