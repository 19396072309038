import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import { IPix } from '../pix/interfaces/pix';

@Component({
  selector: 'app-detalhamento-pix',
  templateUrl: './detalhamento-pix.component.html',
  styleUrls: ['./detalhamento-pix.component.css']
})
export class DetalhamentoPixComponent implements AfterViewInit {
  @Input() pixSelecionado: IPix;

  @Output() fecharDetalhamento = new EventEmitter<any>();

  ngAfterViewInit(): void {
    const scrollPosition = document.getElementById('detalhamento-pix');
    if (scrollPosition !== null) {
      scrollPosition.scrollIntoView();
    }
  }

  fechar() {
    this.fecharDetalhamento.emit();
  }
}
