import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TipoInteracaoAviso } from '../../../../pages/template/components/notificacoes/enums/tipo-interacao-aviso.enum';
import { IInteracaoExibicaoAvisoCliente } from '../../../../pages/template/components/notificacoes/interfaces/interacao-exibicao-aviso-cliente';
import { INotificacoesAvisoOrientacaoCliente } from '../../../../pages/template/components/notificacoes/interfaces/notificacoes-aviso-orientacao-cliente';
import { NotificacoesService } from '../../../../pages/template/components/notificacoes/notificacoes.service';
import { CoutinhoEstadoEnum } from './enum/coutinho-estado.enum';
import { IAvisoOrientacaoCliente } from 'src/app/merge/pages/template/components/notificacoes/interfaces/aviso-orientacao-cliente';
import { TemplateService } from 'src/app/merge/pages/template/template.service';

@Component({
  selector: 'app-insight-notificacao',
  templateUrl: './insight-notificacao.component.html',
  styleUrls: ['./insight-notificacao.component.css'],
})
export class InsightComponent implements OnInit, OnDestroy {
  static FIRST_TIME_FLOW = 2000;
  static SECOND_TIME_FLOW = 5900;

  @ViewChild('insightContainer') insightContainer: ElementRef;

  notificacoes: INotificacoesAvisoOrientacaoCliente;
  mostrarDialog: boolean;
  mostrarToast: boolean;
  mostrarCoutinho: boolean;
  coutinhoEstado: CoutinhoEstadoEnum;
  qtdMessageNaoLida = 0;
  qtdMessage = 0;
  clickCount = 0;
  unsubcribe: () => void;

  constructor(
    private notificacaoService: NotificacoesService,
    private mensagemService: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private templateService: TemplateService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribeLintener();
  }

  ngOnInit() {
    this.mostrarDialog = false;
    this.mostrarToast = false;
    this.coutinhoEstado = CoutinhoEstadoEnum.ATIVO;
    this.mostrarCoutinho = false;
    this.listarNotificacoes();
  }

  unsubscribeLintener(): void {
    if (this.unsubcribe) {
      this.unsubcribe();
    }
  }

  showCoutinho(): void {
    if (this.qtdMessageNaoLida > 0) {
      this.mostrarCoutinho = true;
      setTimeout(() => {
        this.inciarPulsacao();
      }, InsightComponent.FIRST_TIME_FLOW);
    } else {
      this.mostrarCoutinho = false;
    }
  }

  inciarPulsacao(): void {
    if (!this.mostrarDialog) {
      this.coutinhoEstado = CoutinhoEstadoEnum.PULSANDO;
      setTimeout(() => {
        this.finalizarPulsacao();
      }, InsightComponent.SECOND_TIME_FLOW);
   }
  }

  finalizarPulsacao(): void {
    if (!this.mostrarDialog) {
      this.coutinhoEstado = CoutinhoEstadoEnum.INATIVO;
      this.mostrarToast = true;
    }
  }

  closeToast(): void {
    this.mostrarToast = false;
  }

  fecharDialog(): void {
    this.coutinhoEstado = CoutinhoEstadoEnum.INATIVO;
    this.mostrarDialog = false;
    this.mostrarCoutinho = false;
    this.unsubscribeLintener();
  }

  abrirDialog(): void {
    this.closeToast();
    this.coutinhoEstado = CoutinhoEstadoEnum.ABERTO;
    this.mostrarDialog = true;

    this.gerenciarNotificacoes();
    this.startCheckCliqueOutside();
    this.templateService.updateNotificacaoMsgNaoLidas();
  }

  abrirNovaAba(numeroAviso: number, url: string): void {
    const interacao: IInteracaoExibicaoAvisoCliente = {
      numeroAviso,
      codigoInteracao: TipoInteracaoAviso.CLICADO_NO_LINK,
    };
    this.salvarInteracao(interacao);

    const BLANK = '_blank';
    window.open(url, BLANK);
  }

  startCheckCliqueOutside(): void {
    this.unsubcribe = this.renderer.listen('document', 'click', (event) => {
      this.onClick(event.srcElement);
    });
  }

  public onClick(target: HTMLElement): void {
    if (this.insightContainer && this.clickCount > 0) {
      const clickInsight = this.clickIsInside(target, this.insightContainer.nativeElement);

      if (!clickInsight) {
        this.fecharDialog();
      }
    } else {
      /*
      * O click da diretiva do angular é mais rapido do que o evento do Renderer2.
      * Quando o usuário clica no coutinho para abrir o dialog, o hostlistener acha que o fluxo para validar se deve fechar o dialog já iniciou.
      * Esse contador serve justamente para ignorar o clique para abrir o dialog.
      */
      this.clickCount++;
    }
  }

  clickIsInside(target: HTMLElement, container: HTMLElement): boolean {
    if (container === target) {
      return true;
    } else if (container.childNodes.length === 0) {
      return false;
    } else {
      let found = false;
      const elements = Array.from(container.childNodes);
      for (const elemento of elements) {
        if (!found) {
          found = this.clickIsInside(target, elemento as HTMLElement);
        } else {
          break;
        }
      }
      return found;
    }
  }

  toggleDialog(): void {
    if (this.coutinhoEstado === CoutinhoEstadoEnum.ABERTO) {
      this.fecharDialog();
    } else {
      this.abrirDialog();
    }
  }

  listarNotificacoes(): void {
    this.notificacaoService.listarNotificacoes().subscribe(
      (response) => {
        this.inicializarNotificacoes(response.dados);
        this.showCoutinho();
      },
      (error) => {
        this.mensagemService.error(error.erros[0].message);
      }
    );
  }

  inicializarNotificacoes(notificacoes: INotificacoesAvisoOrientacaoCliente): void {
    const SIZE_LIMIT = 4;
    const listaNotificacoes = notificacoes.listaNotificacoes && notificacoes.listaNotificacoes.length > 0 ?
      notificacoes.listaNotificacoes.slice(0, SIZE_LIMIT) : [];
    const msgNaoLidas = listaNotificacoes.filter(notificao => !notificao.avisoLido).length;

    this.qtdMessageNaoLida = msgNaoLidas;
    this.qtdMessage = listaNotificacoes.length;
    this.notificacoes = {
      listaNotificacoes,
      quantidadeNotificacoesNaoLidas: msgNaoLidas
    };
  }

  gerenciarNotificacoes(): void {
    if (this.notificacoes.quantidadeNotificacoesNaoLidas > 0) {
      if (this.mostrarDialog) {
        this.marcarComoLidas();
      }
    }
  }

  marcarComoLidas(): void {
    this.notificacaoService.marcarComoLidas(
      this.notificacoes.listaNotificacoes
    );
  }

  curtirNotificacao(notificacao: IAvisoOrientacaoCliente): void {
    if (!notificacao.avisoCurtido) {
      const numeroAviso = notificacao.numeroAviso;
      const interacao: IInteracaoExibicaoAvisoCliente = {
        numeroAviso,
        codigoInteracao: TipoInteracaoAviso.CURTIDO,
      };
      this.salvarInteracao(interacao);
    }
  }

  salvarInteracao(interacao: IInteracaoExibicaoAvisoCliente): void {
    this.notificacaoService
      .salvarInteracao(interacao)
      .subscribe(
        (response) => {
          if (response.dados) {
            const elemento = this.notificacoes.listaNotificacoes.find(notificao => notificao.numeroAviso === interacao.numeroAviso);
            elemento.codigoTipoUltimaInteracaoAviso = interacao.codigoInteracao;
            if (interacao.codigoInteracao === 2 || interacao.codigoInteracao === 7) {
              elemento.avisoCurtido = true;
            }
            this.changeDetectorRef.detectChanges();
          }
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }
}
