import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FluxoCaixaRoutingModule } from './fluxo-caixa-routing.module';
import { FluxoCaixaComponent } from './fluxo-caixa.component';
import { FormsModule } from '@angular/forms';
import { GraficoGeralModule } from './components/grafico-geral/grafico-geral.module';
import { BlocoRecebimentosModule } from './components/bloco-recebimentos/bloco-recebimentos.module';
import { BlocoDespesasModule } from './components/bloco-despesas/bloco-despesas.module';
import {
  DatepickerModuleEnum,
  NgbdDatepickerRangeEnumComponent,
} from 'merge-common-ng';
import { BlocoCaixaModule } from './components/bloco-caixa/bloco-caixa.module';
import { InsightModule } from 'src/app/merge/shared/common/components/insights-notificacao/insight-notificacao.module';

@NgModule({
  declarations: [FluxoCaixaComponent],
  imports: [
    FormsModule,
    CommonModule,
    DatepickerModuleEnum,
    GraficoGeralModule,
    BlocoCaixaModule,
    BlocoDespesasModule,
    BlocoRecebimentosModule,
    FluxoCaixaRoutingModule,
    InsightModule
  ],
  providers: [NgbdDatepickerRangeEnumComponent],
  exports: [FluxoCaixaComponent],
})
export class FluxoCaixaModule {}
