import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IGraficosCaixa } from './bloco-caixa.models';

interface IGraficoSaida {
    titulo: string;
    totalPrevisto: number;
    legendas: string[];
    percentuais: number[];
    valores: number[];
    cores: string[];
    erroConsultaGrafico: boolean;
}

@Component({
    selector: 'app-bloco-caixa',
    templateUrl: './bloco-caixa.component.html',
    styleUrls: ['./bloco-caixa.component.css'],
})
export class BlocoCaixaComponent implements OnInit, OnChanges {

    @Input() dadosRecebimentos = 0;
    @Input() dadosDespesas = 0;

    erroConsultaGrafico: boolean;
    dadosGrafico: IGraficosCaixa;
    graficoSaida: IGraficoSaida;

    highcharts: any;
    chartOptions: any;

    @BlockUI('bui-caixa') buiCaixa: NgBlockUI;

    constructor() {
        this.highcharts = Highcharts;
        this.erroConsultaGrafico = false;

        this.dadosGrafico = this.iniciarGraficoVazio();
        this.graficoSaida = this.montarGrafico();
        this.exibirGrafico();
    }

    ngOnInit(): void {
        this.buiCaixa.start('Carregando...');
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.dadosRecebimentos?.currentValue) {
            this.dadosRecebimentos = changes.dadosRecebimentos.currentValue;
        }
        if (changes.dadosDespesas?.currentValue) {
            this.dadosDespesas = changes.dadosDespesas.currentValue;
        }
        this.getConsultaDadosGraficoCaixa();
    }

    iniciarGraficoVazio() {
        return {
            totalPrevisto: 0,
            recebimentos: 0,
            despesas: 0
        };
    }

    getConsultaDadosGraficoCaixa() {

        if (!isNaN(this.dadosRecebimentos) && !isNaN(this.dadosDespesas)) {

            this.buiCaixa.stop();

            this.erroConsultaGrafico = false;
            this.dadosGrafico = {
                totalPrevisto: this.dadosRecebimentos - this.dadosDespesas,
                recebimentos: this.dadosRecebimentos,
                despesas: this.dadosDespesas
            };
            this.graficoSaida = this.montarGrafico();
            this.exibirGrafico();

        } else {
            this.dadosGrafico = this.iniciarGraficoVazio();
        }
    }

    montarGrafico(): IGraficoSaida {

        if (!this.dadosGrafico) {
            this.erroConsultaGrafico = true;
            return null;
        }

        return {
            titulo: 'Caixa',
            totalPrevisto: this.dadosGrafico.totalPrevisto,
            legendas: ['Recebimentos', 'Despesas'],
            percentuais: [
                this.dadosGrafico.recebimentos,
                this.dadosGrafico.despesas,
            ],
            valores: [this.dadosGrafico.recebimentos, this.dadosGrafico.despesas],
            cores: ['#465eff', '#f97a70'],
            erroConsultaGrafico: false,
        };
    }

    exibirGrafico() {

        if (!this.graficoSaida) {
            this.erroConsultaGrafico = true;
            return;
        }

        this.chartOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
            },
            title: { text: '' },
            exporting: { enabled: false },
            credits: { enabled: false },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -75,
                        y: -5,
                        format: '{y}',
                        style: { fontWeight: 'bold', color: 'black', fontSize: '14px' },
                        filter: { property: 'name', operator: '===' },
                    },
                    borderWidth: 3,
                },
                series: { animation: true },
            },
            series: [
                {
                    type: 'pie',
                    name: '',
                    innerSize: '50%',
                    data: [
                        {
                            name: this.graficoSaida.legendas[0],
                            y: this.graficoSaida.percentuais[0],
                            color: this.graficoSaida.cores[0],
                            value: this.graficoSaida.valores[0],
                        },
                        {
                            name: this.graficoSaida.legendas[1],
                            y: this.graficoSaida.percentuais[1],
                            color: this.graficoSaida.cores[1],
                            value: this.graficoSaida.valores[1],
                        },
                    ],
                },
            ],
            tooltip: {
                pointFormat: '<strong>{point.percentage:.2f} % / R$ {point.value:.2f}</strong>',
            },
        };
    }
}
