import { LancamentoDetalhes } from '../../models/lancamento-detalhes.model';
import { AgendaCartoesDetalheValorComponent } from './agenda-cartoes-detalhe-valor.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [AgendaCartoesDetalheValorComponent],
  imports: [CommonModule],
  exports: [AgendaCartoesDetalheValorComponent],
})
export class AgendaCartoesDetalheValorModule {}
