import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgendaCartoesDisclaimerUsuarioSemAutorizacaoComponent } from './agenda-cartoes-disclaimer-usuario-sem-autorizacao.component';



@NgModule({
  declarations: [AgendaCartoesDisclaimerUsuarioSemAutorizacaoComponent],
  imports: [
    CommonModule
  ],
  exports: [AgendaCartoesDisclaimerUsuarioSemAutorizacaoComponent]
})
export class AgendaCartoesDisclaimerUsuarioSemAutorizacaoModule { }
