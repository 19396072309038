import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VendasAReceberComponent } from './vendas-a-receber.component';

const routes: Routes = [
  {
    path: '',
    component: VendasAReceberComponent,
    children: [
      {
        path: '',
        redirectTo: 'cartao',
        pathMatch: 'full',
      },
      {
        path: 'cartao',
        loadChildren: () =>
          import('./components/agenda-cartoes/agenda-cartoes.module').then(
            (m) => m.AgendaCartoesModule
          ),
      },
      {
        path: 'boleto',
        loadChildren: () =>
          import(
            './components/conciliacao-vendas-boletos/conciliacao-vendas-boletos.module'
          ).then((m) => m.ConciliacaoVendasBoletosModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VendasAReceberRoutingModule {}
