import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarVendasAReceberComponent } from './navbar-vendas-a-receber.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NavbarVendasAReceberComponent],
  imports: [CommonModule, RouterModule],
  exports: [NavbarVendasAReceberComponent],
})
export class NavbarVendasAReceberModule {}
