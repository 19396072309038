import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-notificacao-toast',
  templateUrl: './notificacao-toast.component.html',
  styleUrls: ['./notificacao-toast.component.css']
})
export class NotificacaoToastComponent {

  @Output() clickBotao = new EventEmitter<any>();
  @Input() textoBotao = 'Verificar';
  @Input() mostrarIcone = true;
  constructor() { }


  buttonClick() {
    this.clickBotao.emit();
  }

}
