import {Component, OnInit} from '@angular/core';
import {NotificacoesService} from './notificacoes.service';
import {INotificacoesAvisoOrientacaoCliente} from './interfaces/notificacoes-aviso-orientacao-cliente';
import {IAvisoOrientacaoCliente} from './interfaces/aviso-orientacao-cliente';
import {IInteracaoExibicaoAvisoCliente} from './interfaces/interacao-exibicao-aviso-cliente';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {TipoInteracaoAviso} from './enums/tipo-interacao-aviso.enum';
import {ToastrService} from 'ngx-toastr';
import { ServicoService } from 'src/app/merge/shared/services/servico.service';
import { Router } from '@angular/router';
import { AdquirenteCadastradaModel } from '../../../cartoes/meu-plano/components/painel-acompanhamento/components/model/adquirente-cadastrada.model';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.css'],
})
export class NotificacoesComponent implements OnInit {
  @BlockUI('bui-notificacoes') ngBlockUI: NgBlockUI;
  ngBlockUINulo: NgBlockUI;
  exibirNotificacoes: boolean;
  notificacoes: INotificacoesAvisoOrientacaoCliente = {
    listaNotificacoes: [],
    quantidadeNotificacoesNaoLidas: 0
  };

  hoverlike: number;
  hoverdeslike: number;
  temNotificacaoConciliadora: boolean;
  public readonly TRACK = {
    category: 'Interacoes',
    action: 'click',
    name: {
      botaoFechar: 'NotificacaoSininho||Conciliadora||AdquirentesPendentes||BotaoFechar',
      botaoVerificar: 'NotificacaoSininho||Conciliadora||AdquirentesPendentes||BotaoVerificar'
    }

  };
  constructor(
    private _notificacaoService: NotificacoesService,
    private _mensagemService: ToastrService,
    private _servicoService: ServicoService,
    private router: Router,
    private readonly matomoTracker: MatomoTracker
  ) {}

  ngOnInit(): void {
    this.exibirNotificacoes = false;
    this.verificaPendenciaAdquirentes();
  }

  private _listarNotificacoes(): void {
    const ngBlock = this.exibirNotificacoes
      ? this.ngBlockUI
      : this.ngBlockUINulo;
    this._notificacaoService.listarNotificacoes(ngBlock).subscribe(
      (response) => {
        this.notificacoes = response.dados;
        this.verificaPendenciaAdquirentes();
      },
      (error) => {
        this._mensagemService.error(error.erros[0].message);
      }
    );
  }

  abrirNotificacoes(): void {
    this.exibirNotificacoes = !this.exibirNotificacoes;

    if (this.notificacoes.quantidadeNotificacoesNaoLidas > 0) {
      if (this.exibirNotificacoes) {
        this._marcarComoLidas();
      } else {
        this.notificacoes.quantidadeNotificacoesNaoLidas = 0;
        this._listarNotificacoes();
      }
    }
  }

  private _marcarComoLidas(): void {
    const mensagensNaoLidas: IAvisoOrientacaoCliente[] =
      this.notificacoes.listaNotificacoes.filter((n) => !n.avisoLido);

    this._notificacaoService.marcarComoLidas(
      mensagensNaoLidas,
      this.ngBlockUINulo
    );
  }

  curtirNotificacao(numeroAviso: number): void {
    const interacao: IInteracaoExibicaoAvisoCliente = {
      numeroAviso,
      codigoInteracao: TipoInteracaoAviso.CURTIDO,
    };

    this._salvarInteracao(interacao);
  }

  naoCurtirNotificacao(numeroAviso: number): void {
    const interacao: IInteracaoExibicaoAvisoCliente = {
      numeroAviso,
      codigoInteracao: TipoInteracaoAviso.NAO_CURTIDO,
    };

    this._salvarInteracao(interacao);
  }

  excluirNotificacao(numeroAviso: number): void {
    const interacao: IInteracaoExibicaoAvisoCliente = {
      numeroAviso,
      codigoInteracao: TipoInteracaoAviso.EXCLUIDO,
    };

    this._salvarInteracao(interacao);
  }

  private _salvarInteracao(interacao: IInteracaoExibicaoAvisoCliente): void {
    this._notificacaoService
      .salvarInteracao(interacao, this.ngBlockUI)
      .subscribe(
        (response) => {
          if (response.dados) {
            this._listarNotificacoes();
          }
        },
        (error) => {
          this._mensagemService.error(error.erros[0].message);
        }
      );
  }


  verificaPendenciaAdquirentes(): void {
    this._servicoService
      .getListaAdquirentesContratadas()
      .subscribe(
        (resposta) => {
          if (resposta.dados.length > 0){
            if (!localStorage.getItem('notificacaoConciliadora') && this.verificaStatusAdquirente(resposta.dados)){
              this.notificacoes.listaNotificacoes.push({
                numeroAviso: 0,
                codigoCliente: 0,
                textoAviso: 'Existem autorizações pendentes em sua Conciliadora de cartões.',
                codigoTipoUltimaInteracaoAviso: 0,
                avisoLido: false,
                avisoCurtido: false,
                notificacaoConciliadora: true
              });
              this.notificacoes.quantidadeNotificacoesNaoLidas += 1;
            }
            this.temNotificacaoConciliadora = true;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  verificaStatusAdquirente(dados: AdquirenteCadastradaModel[]) {
    for (const element of dados) {
      if (element.statusDoVinculo.toUpperCase() === 'PENDENTE' || element.statusDoVinculo === 'EM APROVAÇÃO' || element.statusDoVinculo === 'INICIADA') {
        return true;
      }
    }
    return false;
  }

  excluirNotificacaoConciliadora(){
    localStorage.setItem('notificacaoConciliadora', 'true');
    this._listarNotificacoes();
    this.matomoTracker.trackEvent(this.TRACK.category, this.TRACK.action, this.TRACK.name.botaoFechar);
  }

  redirecionaMeuPlano(): void {
    this.router.navigate(['/pages/cartoes/meu-plano/']);
    this.exibirNotificacoes = false;
    localStorage.setItem('notificacaoConciliadora', 'true');
    this._listarNotificacoes();
    this.matomoTracker.trackEvent(this.TRACK.category, this.TRACK.action, this.TRACK.name.botaoVerificar);
  }
}
