import {DadosTedDocDto} from './../../pages/meu-negocio/despesas/models/dados-ted-doc-tdo';
import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {NgBlockUI} from 'ng-block-ui';
import {Observable} from 'rxjs';
import {ITransferenciaTedDoc} from '../common/interfaces/transferencia-ted-doc';
import {ApiService, Response} from 'merge-common-ng';

@Injectable({
  providedIn: 'root',
})
export class TedDocService {
  LISTAR_TED_DOC_RECEBIDOS = 'api/ted-doc/recebidos';
  LISTAR_TED_DOC_ENVIADOS = 'api/ted-doc/enviados';
  LISTAR_TED_DOC_DESPESAS = 'api/ted-doc/pagamentos';

  constructor(private apiService: ApiService) {}

  listarTransferenciasRecebidas(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<ITransferenciaTedDoc[]>> {
    return this.apiService.get(
      this.LISTAR_TED_DOC_RECEBIDOS,
      params,
      ngBlockUI
    );
  }

  listarTransferenciasEnviadas(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<ITransferenciaTedDoc[]>> {
    return this.apiService.get(this.LISTAR_TED_DOC_ENVIADOS, params, ngBlockUI);
  }

  listarDespesas(
    params: HttpParams,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<DadosTedDocDto>> {
    return this.apiService.get(this.LISTAR_TED_DOC_DESPESAS, params, ngBlockUI);
  }
}
