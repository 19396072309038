import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prefixo',
})
export class PrefixoPipe implements PipeTransform {
  transform(value: number | string): string {
    try {
      if (value === undefined) {
        return '';
      }

      value = String(value);
      return value.padStart(4, '0');
    } catch (error) {
      console.error(error);
      return String(value);
    }
  }
}
