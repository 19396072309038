import { AlignEnum, ColumnDetail, FormatEnum, TypeEnum } from 'src/app/merge/shared/common/components/data-table/interfaces/column-detail';

export class FormatosColunasRelatorios {
    public static readonly RELATORIO_TARIFAS: ColumnDetail[] = [
        {
          dataKey: 'dataEfetivacaoCobrancaTarifa',
          header: 'DATA',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.DATEBR,
        },
        {
          dataKey: 'numeroContaCorrenteDebito',
          header: 'No. CONTA',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'textoDescricaoTarifa',
          header: 'DESCRIÇÃO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'quantidadeTarifaCobrado',
          header: 'QUANTIDADE',
          type: TypeEnum.NUMBER,
          align: AlignEnum.RIGHT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valorOperacaoCobrancaTarifa',
          header: 'VALOR',
          type: TypeEnum.CURRENCY,
          align: AlignEnum.RIGHT,
          format: FormatEnum.CURRENCYBR,
        },
      ];
      public static readonly RELATORIO_DESPESAS_BOLETO: ColumnDetail[] = [
        {
          dataKey: 'banco',
          header: 'BANCO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'dataVencimento',
          header: 'EMISSÃO',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.DATEBR,
        },
        {
          dataKey: 'dataVencimento',
          header: 'VENCIMENTO',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.DATEBR,
        },
        {
          dataKey: 'situacao',
          header: 'SITUAÇÃO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valorLiquido',
          header: 'VALOR LÍQUIDO',
          type: TypeEnum.CURRENCY,
          align: AlignEnum.RIGHT,
          format: FormatEnum.CURRENCYBR,
        },
      ];

      public static readonly RELATORIO_DESPESAS_PIX: ColumnDetail[] = [
        {
          dataKey: 'banco.nome',
          header: 'BANCO',
          type: TypeEnum.OBJETO,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'descricao',
          header: 'DESCRIÇÃO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'dataPagamentoDate',
          header: 'DATA/HORA',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.TIMESTAMPBR,
        },
        {
          dataKey: 'agencia/conta',
          header: 'AGÊNCIA/CONTA',
          type: TypeEnum.AGENCIA_CONTA,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'estadoPagamento',
          header: 'SITUAÇÃO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valorNumerico',
          header: 'VALOR LÍQUIDO',
          type: TypeEnum.CURRENCY,
          align: AlignEnum.RIGHT,
          format: FormatEnum.CURRENCYBR,
        },
      ];

      public static readonly RELATORIO_DESPESAS_OPERACAO_CREDITO: ColumnDetail[] = [
        {
          dataKey: 'dataLancamento',
          header: 'DATA REALIZADO',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.DATEBR,
        },
        {
          dataKey: 'contrato',
          header: 'CONTRATO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'produto',
          header: 'PRODUTO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valor',
          header: 'VALOR',
          type: TypeEnum.CURRENCY,
          align: AlignEnum.RIGHT,
          format: FormatEnum.CURRENCYBR,
        },
      ];
      public static readonly RELATORIO_DESPESAS_TED: ColumnDetail[] = [
        {
          dataKey: 'data',
          header: 'DATA',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.DATEBR,
        },
        {
          dataKey: 'banco',
          header: 'BANCO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'recebedor',
          header: 'RECEBEDOR',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'cpfCnpj',
          header: 'CPF / CNPJ',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'numeroDocumento',
          header: 'Nº DOCUMENTO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'agencia/conta',
          header: 'AGÊNCIA / CONTA',
          type: TypeEnum.AGENCIA_CONTA,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valor',
          header: 'VALOR',
          type: TypeEnum.CURRENCY,
          align: AlignEnum.RIGHT,
          format: FormatEnum.CURRENCYBR,
        },
      ];

      public static readonly RELATORIO_DESPESAS_CARTAO: ColumnDetail[] = [
        {
          dataKey: 'dataRealizacao',
          header: 'DATA REALIZADO',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.DATEBR,
        },
        {
          dataKey: 'descricao',
          header: 'DESCRIÇÃO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valorLiquido',
          header: 'VALOR LÍQUIDO',
          type: TypeEnum.CURRENCY,
          align: AlignEnum.RIGHT,
          format: FormatEnum.CURRENCYBR,
        },
      ];
      public static readonly RELATORIO_RECEBIMENTOS_TITULO_COBRANCA: ColumnDetail[] = [
        {
          dataKey: 'pagador',
          header: 'PAGADOR',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'dataEmissao',
          header: 'DATA EMISSÃO',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.DATEBR,
        },
        {
          dataKey: 'dataVencimento',
          header: 'DATA VENCIMENTO',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.DATEBR,
        },
        {
          dataKey: 'valorLiquido',
          header: 'VALOR LÍQUIDO',
          type: TypeEnum.CURRENCY,
          align: AlignEnum.RIGHT,
          format: FormatEnum.CURRENCYBR,
        },
        {
          dataKey: 'situacao',
          header: 'SITUAÇÃO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
      ];
      public static readonly RELATORIO_RECEBIMENTOS_PIX: ColumnDetail[] = [
        {
          dataKey: 'data',
          header: 'DATA EMISSÃO',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.DATEBR,
        },
        {
          dataKey: 'pagador',
          header: 'PAGADOR',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'descricao',
          header: 'DESCRIÇÃO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valor',
          header: 'VALOR',
          type: TypeEnum.CURRENCY,
          align: AlignEnum.RIGHT,
          format: FormatEnum.CURRENCYBR,
        },
      ];
      public static readonly RELATORIO_RECEBIMENTOS_ORDENS_BANCARIAS: ColumnDetail[] = [
        {
          dataKey: 'dataCreditoConta',
          header: 'DATA CREDITO',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.DATEBR,
        },
        {
          dataKey: 'nomePagador',
          header: 'PAGADOR',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'numeroCnpjPagador',
          header: 'CNPJ',
          type: TypeEnum.CNPJ,
          align: AlignEnum.LEFT,
          format: FormatEnum.CNPJ,
        },
        {
          dataKey: 'valorCredito',
          header: 'VALOR',
          type: TypeEnum.CURRENCY,
          align: AlignEnum.RIGHT,
          format: FormatEnum.CURRENCYBR,
        },
      ];
      public static readonly RELATORIO_RECEBIMENTOS_TED: ColumnDetail[] = [
        {
          dataKey: 'data',
          header: 'DATA',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.DATEBR,
        },
        {
          dataKey: 'codigoBanco',
          header: 'BANCO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'nome',
          header: 'PAGADOR',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'cpfCnpj',
          header: 'CPF/CNPJ',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'numeroDocumento',
          header: 'N. DOCUMENTO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valor',
          header: 'VALOR',
          type: TypeEnum.CURRENCY,
          align: AlignEnum.RIGHT,
          format: FormatEnum.CURRENCYBR,
        },
      ];
      public static readonly RELATORIO_CONCILIADORA_PREVISOES_RECEBIMENTO: ColumnDetail[] = [
        {
          dataKey: 'codigoStatusConciliacao',
          header: 'STATUS',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'dataDaVenda',
          header: 'VENDA',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'dataPrevistaDeRecebimento',
          header: 'PREVISTA',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'modalidade',
          header: 'MODALIDADE',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'operadora',
          header: 'OPERADORA',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valorBruto',
          header: 'BRUTO',
          type: TypeEnum.NUMBER,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'taxaAplicada',
          header: 'TAXAS',
          type: TypeEnum.NUMBER,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valorLiquido',
          header: 'A RECEBER',
          type: TypeEnum.NUMBER,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'numeroDaParcela',
          header: 'PARCELA',
          type: TypeEnum.NUMBER,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
      ];
      public static readonly RELATORIO_CONCILIADORA_VENDAS_E_RECEBIMENTOS: ColumnDetail[] = [
        {
          dataKey: 'codigoStatusConciliacao',
          header: 'STATUS',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'dataDaVenda',
          header: 'VENDA',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'dataDeRecebimento',
          header: 'RECEBIMENTO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'modalidade',
          header: 'MODALIDADE',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'operadora',
          header: 'OPERADORA',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valorBruto',
          header: 'BRUTO',
          type: TypeEnum.NUMBER,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'taxaAplicada',
          header: 'TAXAS',
          type: TypeEnum.NUMBER,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valorLiquido',
          header: 'A RECEBER',
          type: TypeEnum.NUMBER,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'numeroDaParcela',
          header: 'PARCELA',
          type: TypeEnum.NUMBER,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
      ];
      public static readonly RELATORIO_RECEBIMENTO_CARTAO: ColumnDetail[] = [
        {
          dataKey: 'statusConciliacao',
          header: 'STATUS',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'dataPrevistaPgto',
          header: 'PREVISTO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'dataPagamento',
          header: 'REALIZADO',
          type: TypeEnum.DATE,
          align: AlignEnum.LEFT,
          format: FormatEnum.DATEBR,
        },
        {
          dataKey: 'operadora',
          header: 'OPERADORA',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'banco',
          header: 'BANCO',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'conta',
          header: 'CONTA',
          type: TypeEnum.STRING,
          align: AlignEnum.LEFT,
          format: FormatEnum.NONE,
        },
        {
          dataKey: 'valorLiquidoNumerico',
          header: 'VALOR',
          type: TypeEnum.CURRENCY,
          align: AlignEnum.RIGHT,
          format: FormatEnum.CURRENCYBR,
        },
      ];
}
