import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AgendaCartoesService } from './agenda-cartoes.service';
import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../../../../shared/services/pages.service';
import { ContaCorrenteDto } from '../../../../extrato/components/extrato-conta-corrente/interfaces/conta-corrente-dto';
import { BlockUIInterceptor } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { environment } from '../../../../../../../environments/environment';
import { ServicoService } from 'src/app/merge/shared/services/servico.service';
import { ConsultaAutorizacoesDTO } from './models/ConsultaAutorizacoesDTO';
import { ConsultaAgendaDTO } from './models/ConsultaAgendaDTO';

@Component({
  selector: 'app-agenda-cartoes',
  templateUrl: './agenda-cartoes.component.html',
  styleUrls: ['./agenda-cartoes.component.css'],
})
export class AgendaCartoesComponent implements OnInit {
  constructor(
    private agendaCartoesService: AgendaCartoesService,
    private pagesService: PagesService,
    private mensagemService: ToastrService,
    private blockUIInterceptor: BlockUIInterceptor,
    private servicoService: ServicoService
  ) {}
  valorTotalDisponivel: number;
  valorTotalUtilizado: number;

  @BlockUI('bui-lista-contas-correntes')
  buiListaContasCorrentes: NgBlockUI;
  @BlockUI('bui-agenda-cartoes')
  buiAgendaCartoes: NgBlockUI;

  listaAgendaCreditoCliente: any;
  aapjOptinUrl: string;
  contasCorrentes: ContaCorrenteDto[];
  contaCorrenteSelecionada: ContaCorrenteDto;
  userHasAuthorization: boolean;
  temPlano = true;

  ngOnInit(): void {
    this.aapjOptinUrl = environment.aapjBaseUrl.concat(
      environment.aapjOptinRelativeUrl
    );
    this.listarContasCorrentes();
    this.servicoService.verificarContrato().subscribe((resposta) => this.temPlano = resposta.dados.existeContratoVigente);
  }

  listarContasCorrentes(): void {
    this.pagesService
      .getListarContasCorrentesBB(this.buiListaContasCorrentes)
      .subscribe(
        (resposta) => {
          this.contasCorrentes = resposta.dados;

          if (this.contasCorrentes.length > 0) {
            this.contaCorrenteSelecionada = this.contasCorrentes[0];
            const input: ConsultaAutorizacoesDTO = {
              idInformacaoBancaria: this.contaCorrenteSelecionada.idInformacaoBancaria,
              numeroControlePesquisa: 0,
            };
            this.agendaCartoesService
              .verificarAutorizacoesNaCIP(input)
              .subscribe((resp) => {
                if (resp.dados !== null) {
                  if (resp.dados.userAuthorized) {
                    this.listarAgendaCreditoCliente();
                    this.userHasAuthorization = resp.dados.userAuthorized;
                  } else {
                    this.verificarAutorizacoesCIP(resp.dados.userAuthorized);
                  }
                }
              });
          }
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  listarAgendaCreditoCliente(): void {
    const params: ConsultaAgendaDTO = {
      idInformacaoBancaria: this.contaCorrenteSelecionada.idInformacaoBancaria,
      dataInicioAgenda: null,
      dataFimAgenda: null,
    };
    const input: ConsultaAutorizacoesDTO = {
      idInformacaoBancaria: this.contaCorrenteSelecionada.idInformacaoBancaria,
      numeroControlePesquisa: 0,
    };
    this.agendaCartoesService
      .verificarAutorizacoesNaCIP(input)
      .subscribe((resp) => {
        if (resp.dados !== null) {
          if (resp.dados.userAuthorized) {
            this.agendaCartoesService
              .listarAgendaCreditoCliente(params, this.buiAgendaCartoes)
              .subscribe(
                (resposta: any) => {
                  this.listaAgendaCreditoCliente = resposta;

                  this.valorTotalDisponivel = resposta.valorTotalDisponivel;
                  this.valorTotalUtilizado = resposta.valorTotalUtilizado;
                },
                (error) => {
                  this.mensagemService.error(error.erros[0].message);
                }
              );
          }
        }
      });
  }

  private verificarAutorizacoesCIP(isAuth) {
    this.userHasAuthorization = isAuth;
    if (!this.userHasAuthorization) {
      this.blockUIInterceptor.stopForce();
    }
    return false;
  }
}
