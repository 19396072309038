import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DatepickerModuleEnum } from 'merge-common-ng';
import { BlockUIModule } from 'ng-block-ui';
import { ComponentsCommonModule } from 'src/app/merge/shared/common/components-commom.module';

import { BoletoModule } from './components/boleto/boleto.module';
import { CartaoModule } from './components/cartao/cartao.module';
import { OperacaoCreditoModule } from './components/operacao-credito/operacao-credito.module';
import { PixModule } from './components/pix/pix.module';
import { TedDocModule } from './components/ted-doc/ted-doc.module';
import { DespesasRoutingModule } from './despesas-routing.module';
import { DespesasComponent } from './despesas.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { TarifaModule } from './components/tarifa/tarifa.module';
import { InsightModule } from 'src/app/merge/shared/common/components/insights-notificacao/insight-notificacao.module';

@NgModule({
  declarations: [DespesasComponent],
  imports: [
    CommonModule,
    BlockUIModule.forRoot(),
    ComponentsCommonModule,
    DespesasRoutingModule,
    RouterModule,
    FormsModule,
    CurrencyMaskModule,
    NgxPaginationModule,
    TarifaModule,
    DatepickerModuleEnum,
    InsightModule,
    BoletoModule,
    CartaoModule,
    PixModule,
    TedDocModule,
    OperacaoCreditoModule,
  ],
  exports: [DespesasComponent],
})
export class DespesasModule {}
