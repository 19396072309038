import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConciliacaoVendasBoletosComponent } from './conciliacao-vendas-boletos.component';

const routes: Routes = [
  {
    path: '',
    component: ConciliacaoVendasBoletosComponent,
    data: { title: 'Meu Negócio - Vendas a receber - Boleto' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConciliacaoVendasBoletosRoutingModule {}
