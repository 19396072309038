import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatepickerModuleEnum } from 'merge-common-ng';
import { BlockUIModule } from 'ng-block-ui';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxPaginationModule } from 'ngx-pagination';

import { ComponentsCommonModule } from '../../../../../shared/common/components-commom.module';
import { DataTableModule } from '../../../../../shared/common/components/data-table/data-table.module';
import { NavbarDespesasModule } from '../navbar-despesas/navbar-despesas.module';
import { PixRoutingModule } from './tarifa-routing.module';
import { TarifaComponent } from './tarifa.component';

@NgModule({
  declarations: [TarifaComponent],
  exports: [TarifaComponent],
  imports: [
    CommonModule,
    PixRoutingModule,
    NgxPaginationModule,
    ComponentsCommonModule,
    FormsModule,
    CurrencyMaskModule,
    BlockUIModule.forRoot(),
    NavbarDespesasModule,
    DatepickerModuleEnum,
    DataTableModule,
  ],
})
export class TarifaModule {}
