import { NgModule } from '@angular/core';
import { RecebimentosComponent } from './recebimentos.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DatepickerModuleEnum } from 'merge-common-ng';
import { NgxPaginationModule } from 'ngx-pagination';
import { ComponentsCommonModule } from '../../../shared/common/components-commom.module';
import { RecebimentosRoutingModule } from './recebimentos-routing.module';
import { DetalhamentoVendaModule } from './components/detalhamento-venda/detalhamento-venda.module';
import { TedDocModule } from './components/ted-doc/ted-doc.module';
import { TitulosCobrancaModule } from './components/titulos-cobranca/titulos-cobranca.module';
import { BlockUIModule } from 'ng-block-ui';
import { PixModule } from './components/pix/pix.module';
import { DetalhamentoPixModule } from './components/detalhamento-pix/detalhamento-pix.module';
import { InsightModule } from 'src/app/merge/shared/common/components/insights-notificacao/insight-notificacao.module';
import { OrdensBancariasModule } from './components/ordens-bancarias/ordens-bancarias.module';

@NgModule({
  declarations: [RecebimentosComponent],
  imports: [
    CommonModule,
    RecebimentosRoutingModule,
    RouterModule,
    FormsModule,
    DatepickerModuleEnum,
    NgxPaginationModule,
    ComponentsCommonModule,
    DetalhamentoVendaModule,
    TitulosCobrancaModule,
    TedDocModule,
    BlockUIModule.forRoot(),
    PixModule,
    DetalhamentoPixModule,
    InsightModule,
    OrdensBancariasModule
  ],
  exports: [RecebimentosComponent],
})
export class RecebimentosModule {}
