import { Component, Input} from '@angular/core';
import { IFiltroDatas } from 'src/app/merge/shared/common/interfaces/filtro-datas';
import { ColumnDetail} from 'src/app/merge/shared/common/components/data-table/interfaces/column-detail';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { RelatoriosService } from 'src/app/merge/shared/services/relatorios.service';
@Component({
  selector: 'botao-relatorio',
  templateUrl: './botao-relatorio.component.html',
  styleUrls: ['./botao-relatorio.component.css']
})
export class BotaoRelatorioComponent {
  @Input() filtroDatas: IFiltroDatas;
  @Input() conteudoEsperado: string;
  dados: any[];

  estabelecimento: Empresa;
  columnsDetails: ColumnDetail[] = [];
  tipo_relatorio = '';
  formato = 'xlsx';
  constructor(
    public relatorioService: RelatoriosService
  ) {}
  realizarDownload(){
    const valoresRelatorio = {
      ConciliadoraVendas(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioConciliadoraVendas(filtroDatas, formato);
      },
      ConciliadoraRecebimentos(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioConciliadoraRecebimentos(filtroDatas, formato);
      },
      ConciliadoraPrevisoes(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioConciliadoraPrevisoesRecebimentos(filtroDatas, formato);
      },
      RecebimentosTed(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioRecebimentoTed(filtroDatas, formato);
      },
      RecebimentosOrdem(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioRecebimentoOrdensBancarias(filtroDatas, formato);
      },
      RecebimentosPix(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioRecebimentoPix(filtroDatas, formato);
      },
      RecebimentosTitulos(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioRecebimentoTituloCobranca(filtroDatas, formato);
      },
      DespesasCartao(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioDespesasCartao(filtroDatas, formato);
      },
      DespesasTed(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioDespesasTed(filtroDatas, formato);
      },
      DespesasOperacaoCredito(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioDespesasOperacoesCredito(filtroDatas, formato);
      },
      DespesasPix(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioDespesasPix(filtroDatas, formato);
      },
      DespesasBoleto(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioDespesasBoleto(filtroDatas, formato);
      },
      DespesasTarifas(relatorioService: RelatoriosService, filtroDatas: IFiltroDatas , formato: string){
        relatorioService.gerarRelatorioTarifas(filtroDatas, formato);
      },
    };
    valoresRelatorio[this.conteudoEsperado](this.relatorioService, this.filtroDatas, this.formato);
  }
  onRadioChange(value: string) {
    this.formato = value;
  }
}
