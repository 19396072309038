import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutorizacoesComponent } from './autorizacoes.component';
import { AutorizacoesRoutingModule } from './autorizacoes-routing.module';
import { BlockUIModule } from 'ng-block-ui';
import { AutorizacoesOpfComponent } from './autorizacoes-opf/autorizacoes-opf.component';

@NgModule({
  declarations: [
    AutorizacoesComponent,
    AutorizacoesOpfComponent],
  imports: [
    CommonModule,
    AutorizacoesRoutingModule,
    BlockUIModule.forRoot()
  ],
  exports: [
    AutorizacoesComponent
  ]
})
export class AutorizacoesModule { }
