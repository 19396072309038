import {Injectable} from '@angular/core';
import {ApiService, Hosts, Response} from 'merge-common-ng';
import {
  ContasCartoesEmpresariasDto
} from '../../pages/extrato/components/extrato-cartao-credito/interfaces/contas-cartoes-empresarias-dto';
import {Observable} from 'rxjs';
import {NgBlockUI} from 'ng-block-ui';
import {
  ConsultaFaturasInputDTO
} from '../../pages/extrato/components/extrato-cartao-credito/interfaces/consulta-faturas-input-dto';
import {
  ConsultarFaturasFechadasOutputDTO
} from '../../pages/extrato/components/extrato-cartao-credito/interfaces/consultar-faturas-fechadas-output-dto';
import {
  ConsultarExtratoFaturaInputDTO
} from '../../pages/extrato/components/extrato-cartao-credito/interfaces/consultar-extrato-fatura-input-dto';
import {
  ConsultarExtratoFaturaOutputDTO
} from '../../pages/extrato/components/extrato-cartao-credito/interfaces/consultar-extrato-fatura-output-dto';
import {
  ConsultarLancamentosFaturaFuturaInput
} from '../../pages/extrato/components/extrato-cartao-credito/interfaces/consultar-lancamentos-fatura-futura-input';
import {
  ConsultarLancamentosFuturosFaturasOutput
} from '../../pages/extrato/components/extrato-cartao-credito/interfaces/consultar-lancamentos-futuros-faturas-output';

@Injectable({
  providedIn: 'root'
})
export class ExtratoFaturaCartaoService {

  private static URL_API_BASE = 'api/faturas/';
  private static URL_CONTAS_CARTOES = ExtratoFaturaCartaoService.URL_API_BASE.concat('contas/cartoes');
  private static URL_FATURAS_FECHADAS_CARTOES = ExtratoFaturaCartaoService.URL_API_BASE.concat('fechadas');
  private static URL_FATURAS_FECHADAS_EXTRATO_CARTOES = ExtratoFaturaCartaoService.URL_API_BASE.concat('fechadas/extrato');
  private static URL_FATURAS_ABERTAS_CARTOES = ExtratoFaturaCartaoService.URL_API_BASE.concat('abertas');

  constructor(private apiService: ApiService) {}

  public consultarContasCartoesEmpresariais(ngBlockUI?: NgBlockUI): Observable<Response<ContasCartoesEmpresariasDto>> {
    return this.apiService.post(ExtratoFaturaCartaoService.URL_CONTAS_CARTOES, {}, null, ngBlockUI, Hosts.HOST_ITEBB);
  }
  public consultarFaturasCartoesEmpresariais(input: ConsultaFaturasInputDTO, ngBlockUI?: NgBlockUI): Observable<Response<ConsultarFaturasFechadasOutputDTO>> {
    return this.apiService.post(
      ExtratoFaturaCartaoService.URL_FATURAS_FECHADAS_CARTOES,
      input,
      null,
      ngBlockUI,
      Hosts.HOST_ITEBB
    );
  }
  public consultarExtratoFaturasCartoesEmpresariais(input: ConsultarExtratoFaturaInputDTO, ngBlockUI?: NgBlockUI): Observable<Response<ConsultarExtratoFaturaOutputDTO>> {
    return this.apiService.post(
      ExtratoFaturaCartaoService.URL_FATURAS_FECHADAS_EXTRATO_CARTOES,
      input,
      null,
      ngBlockUI,
      Hosts.HOST_ITEBB
    );
  }
  public consultarFaturasFuturasCartoesEmpresariais(input: ConsultarLancamentosFaturaFuturaInput, ngBlockUI?: NgBlockUI): Observable<Response<ConsultarLancamentosFuturosFaturasOutput>> {
    return this.apiService.post(
      ExtratoFaturaCartaoService.URL_FATURAS_ABERTAS_CARTOES,
      input,
      null,
      ngBlockUI,
      Hosts.HOST_ITEBB
    );
  }
}
