import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PixComponent } from './pix.component';

const routes: Routes = [
  {
    path: '',
    component: PixComponent,
    data: { title: 'Meu Negócio - Recebimentos - Pix' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PixRoutingModule {}
