import {
  TypeEnum,
  AlignEnum,
  FormatEnum,
} from './../../../../../shared/common/components/data-table/interfaces/column-detail';
import { ColumnDetail } from 'src/app/merge/shared/common/components/data-table/interfaces/column-detail';
import { DadosTablePix } from './../../../../../shared/common/components/extrato-contas/interfaces/dados-table-pix';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DataInputComRegra, Empresa } from 'merge-common-ng';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { NaturezaPixEnum } from 'src/app/merge/pages/meu-negocio/despesas/models/natureza-pix-enum';
import { TemplateService } from 'src/app/merge/pages/template/template.service';
import { IFiltroDatas } from 'src/app/merge/shared/common/interfaces/filtro-datas';
import { TimestampBehaviourService } from 'src/app/merge/shared/services/timestamp-behaviour.service';

import { ConciliacaoVendasService } from './../../../../../shared/services/conciliacao-vendas.service';
import { PixInputDTO } from './../../models/pix-input-dto';
import { PixOutputDTO } from './../../models/pix-output-dto';

@Component({
  selector: 'app-pix',
  templateUrl: './pix.component.html',
  styleUrls: ['./pix.component.css'],
})
export class PixComponent implements OnInit {
  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  dataSelecionada: DataInputComRegra;
  dadosPix: PixOutputDTO;

  @BlockUI('bui-despesas-pix') buiDepesasPix: NgBlockUI;

  defaultDataSort = 'dataRealizacao';
  columnsDetails: ColumnDetail[] = [
    {
      dataKey: 'banco.nome',
      header: 'BANCO',
      type: TypeEnum.OBJETO,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'descricao',
      header: 'DESCRIÇÃO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'dataPagamentoDate',
      header: 'DATA/HORA',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.TIMESTAMPBR,
    },
    {
      dataKey: 'agencia/conta',
      header: 'AGÊNCIA/CONTA',
      type: TypeEnum.AGENCIA_CONTA,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'estadoPagamento',
      header: 'SITUAÇÃO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'valorNumerico',
      header: 'VALOR LÍQUIDO',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.CURRENCYBR,
    },
  ];

  constructor(
    private conciliacaoVendasService: ConciliacaoVendasService,
    private mensagemService: ToastrService,
    private templateService: TemplateService,
    private timestampService: TimestampBehaviourService,
    private datePipe: DatePipe
  ) {
    this.dadosPix = new PixOutputDTO();
    this.dadosPix.listaLancamento = [];
  }

  ngOnInit(): void {
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this._setFiltroDatas(data));

    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));

    this.timestampService.updateRegraCalendario();
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);
    this.timestampService.getCalendarioSubject().subscribe((date) => {
      this.dataSelecionada = date;
    });
    this._carregarDespesasPix();
  }

  private _carregarDespesasPix(): void {
    this.conciliacaoVendasService
      .getListaPixPorTipo(this._parametrosPesquisaPix(), this.buiDepesasPix)
      .subscribe(
        (resposta) => {
          this.dadosPix = resposta.dados;
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  private _parametrosPesquisaPix(): PixInputDTO {
    const dto = new PixInputDTO();
    dto.dataInicial = this.filtroDatas.dataInicial;
    dto.dataFinal = this.filtroDatas.dataFinal;
    dto.naturezaPix = NaturezaPixEnum.DEBITO;
    return dto;
  }

  private _setFiltroDatas(data: DataInputComRegra): void {
    this.dataSelecionada = data;
    this.filtroDatas = this.timestampService.translateDateForInput(data);
  }
}
