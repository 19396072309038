import {Injectable} from '@angular/core';
import {ApiService, Response} from 'merge-common-ng';
import {Observable} from 'rxjs';
import {NgBlockUI} from 'ng-block-ui';
import {IVisaoGeral} from '../../pages/visao-geral/interfaces/visao-geral';

@Injectable({
  providedIn: 'root',
})
export class VisaoGeralService {
  private static readonly VISAO_GERAL_URL = 'api/visaogeral/graficos';

  constructor(private apiService: ApiService) {}

  consultarDadosVisaoGeral(
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IVisaoGeral>> {
    return this.apiService.post(
      VisaoGeralService.VISAO_GERAL_URL,
      {},
      null,
      ngBlockUI
    );
  }
}
