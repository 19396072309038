export class ListaRegistro {
  public numeroTablespace: number;
  public numeroTituloCobrancaBancoBrasil: string;
  public dataRegistroTituloCobranca: string;
  public dataVencimentoTituloCobranca: string;
  public dataRegistroTituloCobrancaFormatada: string;
  public dataVencimentoTituloCobrancaFormatada: string;
  public valorOriginalTituloCobranca: string;
  public valorOriginalTituloNumerico: number;
  public numeroCarteiraCobranca: number;
  public numeroVariacaoCarteiraCobranca: number;
  public siglaSistemaOrigem: string;
  public textoEstadoTitulo: string;
  public numeroContratoCobranca: number;
  public dataMovimentoCobranca: string;
  public dataMovimentoCobrancaFormatada: string;
  public valorAtualTituloCobranca: number;
  public valorPagoSacado: number;
  public codigoTipoInscricaoSacado: number;
  public numeroInscricaoSacadoCobranca: number;
  public nomeSacadoCobranca: string;

  public constructor(init?: Partial<ListaRegistro>) {
    Object.assign(this, init);
  }
}
