import { NgBlockUI } from 'ng-block-ui';
import { EndpointsMerge } from '../../../../../shared/common/endpointsmerge';
import { ApiService, Response } from 'merge-common-ng';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DadosRespostaAutorizacoesCIPCliente } from './models/DadosRespostaAutorizacoesCIPCliente';
import { DadosRespostaListarAgendaCreditoCliente } from './models/DadosRespostaListarAgendaCreditoCliente';
import { ConsultaAutorizacoesDTO } from './models/ConsultaAutorizacoesDTO';
import { ConsultaAgendaDTO } from './models/ConsultaAgendaDTO';

@Injectable({
  providedIn: 'root',
})
export class AgendaCartoesService {
  constructor(public apiService: ApiService) {}

  listarAgendaCreditoCliente(
    body: ConsultaAgendaDTO,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<DadosRespostaListarAgendaCreditoCliente>> {
    return this.apiService.post(
      EndpointsMerge.LISTAR_AGENDA_CREDITO_CLIENTE,
      body,
      null,
      ngBlockUI
    );
  }
  verificarAutorizacoesNaCIP(
    input: ConsultaAutorizacoesDTO,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<DadosRespostaAutorizacoesCIPCliente>> {
    return this.apiService.post(
      EndpointsMerge.CONSULTAR_AUTORIZACOES_CIP,
      input,
      null,
      ngBlockUI
    );
  }
}
