import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { BlockUIModule } from 'ng-block-ui';
import { environment } from 'src/environments/environment';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    BlockUIModule.forRoot(),
    NgxMatomoTrackerModule.forRoot({
      trackerUrl: environment.matomoURL,
      siteId: environment.matomoSiteId,
      enableLinkTracking: true,
    }),
    NgxMatomoRouterModule
  ],
  exports: [LoginComponent],
})
export class LoginModule { }
