import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificacaoToastComponent } from './notificacao-toast.component';



@NgModule({
  declarations: [NotificacaoToastComponent],
  imports: [
    CommonModule
  ],
  exports: [NotificacaoToastComponent]
})
export class NotificacaoToastModule { }
