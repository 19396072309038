import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotaoRelatorioComponent } from './botao-relatorio.component';
import { RelatoriosService } from 'src/app/merge/shared/services/relatorios.service';



@NgModule({
  providers: [RelatoriosService],
  declarations: [BotaoRelatorioComponent],
  imports: [
    CommonModule
  ],
  exports: [BotaoRelatorioComponent]
})
export class BotaoRelatorioModule { }
