import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxPaginationModule } from 'ngx-pagination';

import { FilterResumoRecebimentos } from '../../pages/meu-negocio/recebimentos/filters/resumo-recebimentos.pipe';
import { FilterExtratoContas } from './components/extrato-contas/interfaces/filter-extrato-contas';
import { BancoPipe } from './pipes/banco.pipe';
import { CNPJPipe } from './pipes/cnpj.pipe';
import { ContaCorrentePipe } from './pipes/conta-corrente.pipe';
import { CPFPipe } from './pipes/cpf.pipe';
import { PrefixoPipe } from './pipes/prefixo.pipe';
import { NotificacaoToastComponent } from './components/notificacao-toast/notificacao-toast.component';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    FilterExtratoContas,
    FilterResumoRecebimentos,
    BancoPipe,
    CPFPipe,
    CNPJPipe,
    PrefixoPipe,
    ContaCorrentePipe,

  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    HighchartsChartModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
  ],
  exports: [PrefixoPipe, ContaCorrentePipe, BancoPipe, CPFPipe, CNPJPipe],
})
export class ComponentsCommonModule {}
