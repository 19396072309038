import { LancamentoDetalhe } from '../../models/lancamento-detalhe.model';
import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-agenda-cartoes-detalhe-valor',
  templateUrl: './agenda-cartoes-detalhe-valor.component.html',
  styleUrls: ['./agenda-cartoes-detalhe-valor.component.css'],
})
export class AgendaCartoesDetalheValorComponent implements AfterViewInit {
  @Input() lancamentoDetalhe: LancamentoDetalhe;

  @Output() fecharDetalhamento = new EventEmitter<any>();

  ngAfterViewInit() {
    (<any> $('#modalTermos')).modal('show');
  }

  fechar() {
    this.fecharDetalhamento.emit();
  }
}
