import moment from 'moment';
import { ColumnDetail, TypeEnum } from '../common/components/data-table/interfaces/column-detail';
import * as XLSX from 'xlsx-js-style';
import { IFiltroDatas } from '../common/interfaces/filtro-datas';
import { CNPJPipe } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { DatePipe, CurrencyPipe } from '@angular/common';

export class GeradorDeRelatorios {
    formato: string;
    dataSource: any[] = new Array();
    columnsDetails: ColumnDetail[];
    dataInicialSelecionada: string;
    dataFinalSelecionada: string;
    nomeRelatorio = '';
    datePipe: DatePipe = new DatePipe('en-US');
    cnpjPipe: CNPJPipe = new CNPJPipe();
    currencyPipe: CurrencyPipe = new CurrencyPipe('en-US', 'R$');
    nomeFantasia: string;
    cnpj: string;
    private fileName: string;
    private workbook: XLSX.WorkBook;
    private bt: any ;
    constructor(
      ){
    }
    public gerarRelatorio(
        formato: string,
        dataSource: any[],
        columnsDetails: ColumnDetail[],
        filtroDatas: IFiltroDatas,
        nomeFantasia: string,
        cnpj: string,
        nomeRelatorio: string): void{

        this.nomeRelatorio = nomeRelatorio;
        this.cnpj = cnpj;
        this.nomeFantasia = nomeFantasia;
        this.formato = formato;
        this.dataSource = dataSource;
        this.columnsDetails = columnsDetails;
        this.dataInicialSelecionada =
          moment(filtroDatas.dataInicial).locale('pt-br').format('DD/MM/YYYY');
        this.dataFinalSelecionada =
          moment(filtroDatas.dataFinal).locale('pt-br').format('DD/MM/YYYY');

        this.workbook = XLSX.utils.book_new();
        const colunas = this.columnsDetails.map(item => [item.header]);
        const cabecalho = this.getCabecalho();
        const worksheet = XLSX.utils.aoa_to_sheet([
            ...cabecalho,
            colunas,
            ...this.dataSource.map(
               item => (Array.from({length: Object.keys(item).length}, (value, index) => index).map(
                  key => ([
                    this.showFormatedValue(item, key)
                  ])
               ))
             )
        ]);

        this.formatarWorksheet(worksheet, this.dataSource.length, colunas.length - 1);

        XLSX.utils.book_append_sheet(this.workbook, worksheet, this.nomeRelatorio);

        const currentDate: string = new Date().toLocaleDateString().toString();
        this.fileName = this.nomeRelatorio + '-' + currentDate + '.' + this.formato;
        this.bt = this.formato;
        this.write();
    }

    write(){
        XLSX.writeFile(this.workbook, this.fileName, { bookType: this.bt });
    }

    showFormatedValue(object: object, col: number): any {
        let value;

        if (this.canShow(col)) {
          const columnDetail = this._getColumnDetail(
            this.columnsDetails[col]['dataKey']
          );

          if (columnDetail.type !== TypeEnum.AGENCIA_CONTA) {
            value = object[this.columnsDetails[col]['dataKey']];
          }

          if (columnDetail.type === TypeEnum.DATE) {
            return this.datePipe.transform(value, columnDetail.format);
          } else if (columnDetail.type === TypeEnum.CURRENCY) {
            return this.currencyPipe.transform(value, columnDetail.format);
          } else if (columnDetail.type === TypeEnum.CNPJ) {
            return this.cnpjPipe.transform(value);
          } else if (columnDetail.type === TypeEnum.AGENCIA_CONTA) {
            return this.transformAgenciaConta(object, col);
          } else if (columnDetail.type === TypeEnum.OBJETO) {
            const keys = this.columnsDetails[col]['dataKey'].split('.');
            for (let i = 0; i < keys.length; i++){
              if (i === 0){
                value = object[keys[i]];
              }else{
                value = value[keys[i]];
              }
            }
          }
          return value;
        }
      }
    private transformAgenciaConta(object: object, col: number): string {
      const dadosConta = this.columnsDetails[col]['dataKey'].split('/', 2);

      if (dadosConta.length > 0) {
        return object[dadosConta[0]] + '/' + object[dadosConta[1]];
      }

      return '';
    }
    private _getColumnDetail(key: string): ColumnDetail {
      return this.columnsDetails.find((detail) => detail.dataKey === key);
    }
    canShow(col: number): boolean {
      return col <= this.columnsDetails.length - 1;
    }


    isSemDados(): boolean {
      if (typeof this.dataSource === 'undefined') {
        return true;
      }
      return this.dataSource.length === 0;
    }

    getDatasourceLength(): number {
        if (typeof this.dataSource === 'undefined') {
          return 0;
        }
        return this.dataSource.length;
    }

    getCabecalho(): string[][] {
        const formatoDataHora = 'DD/MM/YYYY [às] HH:mm:ss';
        const dataFormatada = moment().format(formatoDataHora);
        const data = this.dataInicialSelecionada + ' a ' + this.dataFinalSelecionada;
        const dadosEmpresa = this.nomeFantasia + ' - CNPJ: ' + this.cnpj;

        const cabecalho = [
            [''],
            ['', '', 'Painel PJ'],
            ['', '', dadosEmpresa],
            [''],
            ['Tipo de Relatório', '', '', this.nomeRelatorio],
            ['Período da Consulta', '', '', data],
            ['Data/Hora da Geração', '', '', dataFormatada],
            [''],
        ];

        return cabecalho;
    }

    formatarWorksheet(worksheet: XLSX.WorkSheet, quantidadeLinhas: number, indexColunaFinal: number) {
        this.mergeCelulas(worksheet, indexColunaFinal);
        this.formatarCabecalho(worksheet, indexColunaFinal);
        this.formatarCelulas(worksheet, quantidadeLinhas, indexColunaFinal);
    }

  mergeCelulas(worksheet, indexColunaFinal) {
          const listaMerge = [
              { linhaInicial: 0, colunaInicial: 0, linhaFinal: 0, colunaFinal: indexColunaFinal },
              { linhaInicial: 1, colunaInicial: 0, linhaFinal: 2, colunaFinal: 1 },
              { linhaInicial: 1, colunaInicial: 2, linhaFinal: 1, colunaFinal: indexColunaFinal },
              { linhaInicial: 2, colunaInicial: 2, linhaFinal: 2, colunaFinal: indexColunaFinal },
              { linhaInicial: 3, colunaInicial: 0, linhaFinal: 3, colunaFinal: indexColunaFinal },
              { linhaInicial: 4, colunaInicial: 0, linhaFinal: 4, colunaFinal: 2 },
              { linhaInicial: 5, colunaInicial: 0, linhaFinal: 5, colunaFinal: 2 },
              { linhaInicial: 6, colunaInicial: 0, linhaFinal: 6, colunaFinal: 2 },
              { linhaInicial: 4, colunaInicial: 3, linhaFinal: 4, colunaFinal: indexColunaFinal },
              { linhaInicial: 5, colunaInicial: 3, linhaFinal: 5, colunaFinal: indexColunaFinal },
              { linhaInicial: 6, colunaInicial: 3, linhaFinal: 6, colunaFinal: indexColunaFinal },
              { linhaInicial: 7, colunaInicial: 0, linhaFinal: 7, colunaFinal: indexColunaFinal }
          ];

          if (!worksheet['!merges']) {
              worksheet['!merges'] = [];
          }

          listaMerge.forEach(itemMerge => {
              const { linhaInicial, colunaInicial, linhaFinal, colunaFinal } = itemMerge;
              const mergeCell = { s: { r: linhaInicial, c: colunaInicial }, e: { r: linhaFinal, c: colunaFinal } };
              worksheet['!merges'].push(mergeCell);
          });
      }

      formatarCelulas(worksheet, quantidadeLinhas, indexColunaFinal) {
          const primeiraLinhaPosCabecalho = 9;

          for (let linha = primeiraLinhaPosCabecalho; linha < quantidadeLinhas + primeiraLinhaPosCabecalho; linha++) {
              for (let coluna = 0; coluna <= indexColunaFinal; coluna++) {

                  const columnsDetails = this._getColumnDetail(
                  this.columnsDetails[coluna]['dataKey']).type;

                  const cell = worksheet[XLSX.utils.encode_cell({ r: linha, c: coluna })];
                  if (cell) {
                      if (columnsDetails === TypeEnum.CURRENCY) {
                          cell.s = { alignment: { horizontal: 'center' }, numFmt: 'R$ 0.00' };
                      }
                  } else {
                      const celulaVazia = { t: 's', v: '-', s: { alignment: { horizontal: 'center' } } };
                      worksheet[XLSX.utils.encode_cell({ r: linha, c: coluna })] = celulaVazia;
                  }
              }
          }
      }

      formatarCabecalho(worksheet, indexColunaFinal) {
          this.formatarTituloCabecalho(worksheet);
          this.formatarDetalhamentoCabecalho(worksheet);
          this.formatarTituloColunas(worksheet, indexColunaFinal);
      }

      formatarTituloCabecalho(worksheet) {
          // Configurações da título no cabeçalho da tabela
          const alturaLinhaTituloCabecalho = 22;
          const tituloCabecalhoStyle = {
              alignment: { vertical: 'center' },
              fill: { fgColor: { rgb: 'FF465EFF' } },
              font: {
                  name: 'Arial',
                  bold: true,
                  sz: 18,
                  color: { rgb: 'FFFFFF' }
              }
          };

          const celulasTituloCabecalho = [
              { linha: 1, coluna: 2 },
              { linha: 2, coluna: 2 }
          ];

          if (!worksheet['!rows']) {
              worksheet['!rows'] = [];
          }

          celulasTituloCabecalho.forEach(celula => {
              worksheet[XLSX.utils.encode_cell({ r: celula.linha, c: celula.coluna })].s = tituloCabecalhoStyle;
              worksheet['!rows'][celula.linha] = { hpx: alturaLinhaTituloCabecalho };
          });
      }

      formatarDetalhamentoCabecalho(worksheet) {
          // Configurações do detalhamento no cabeçalho da tabela
          const alturaLinhaDetalhamento = 15;
          const detalhamentoCabecalhoStyle = {
              font: {
                  name: 'Arial',
                  bold: true,
                  sz: 12,
              }
          };

          const celulasDetalhamentoCabecalho = [
              { linha: 0, coluna: 0 },
              { linha: 3, coluna: 0 },
              { linha: 4, coluna: 0 },
              { linha: 4, coluna: 3 },
              { linha: 5, coluna: 0 },
              { linha: 5, coluna: 3 },
              { linha: 6, coluna: 0 },
              { linha: 6, coluna: 3 },
              { linha: 7, coluna: 0 },
          ];

          celulasDetalhamentoCabecalho.forEach(celula => {
              worksheet[XLSX.utils.encode_cell({ r: celula.linha, c: celula.coluna })].s = detalhamentoCabecalhoStyle;
              worksheet['!rows'][celula.linha] = { hpx: alturaLinhaDetalhamento };
          });
      }

      formatarTituloColunas(worksheet, indexColunaFinal) {
          // Configurações da linha dos títulos das colunas
          const backgroudCinza = { fgColor: { rgb: 'FFF4F5F7' } };
          const bordaDefault = { style: 'thin', color: { rgb: 'D3D3D3' } };
          const alinhamento = { vertical: 'center', horizontal: 'center', wrapText: true };
          const fonteStyle = { name: 'Arial', bold: true, sz: 10 };
          const indexLinhaTituloColunas = 8;
          const larguraColuna = 15;
          const alturaLinhaTituloColuna = 25;

          if (!worksheet['!cols']) {
              worksheet['!cols'] = [];
          }

          worksheet['!rows'][indexLinhaTituloColunas] = { hpx: alturaLinhaTituloColuna };

          for (let index = 0; index <= indexColunaFinal; index++) {
              worksheet[XLSX.utils.encode_cell({ r: indexLinhaTituloColunas, c: index })].s = {
                  alignment: alinhamento,
                  fill: backgroudCinza,
                  font: fonteStyle,
                  border: {
                      top: bordaDefault,
                      left: bordaDefault,
                      bottom: bordaDefault,
                      right: bordaDefault
                  }
              };

              worksheet['!cols'][index] = { wch: larguraColuna };
          }
      }
}
