import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.css']
})
export class BottomSheetComponent implements OnInit {

  @Input() show: boolean;
  @Output() closedModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }
  isBottomSheetEnable: boolean;
  ngOnInit(): void {
    this.isBottomSheetEnable = true;
    this.closedModal.emit(true);
  }

  closed() {
    this.closedModal.emit(false);
  }

}
