import { BlockUIModule } from 'ng-block-ui';
import { HighchartsChartCustomModule } from 'merge-common-ng';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraficoVolumeVendasComponent } from './grafico-volume-vendas.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [GraficoVolumeVendasComponent],
  imports: [
    CommonModule,
    HighchartsChartCustomModule,
    HighchartsChartModule,
    FormsModule,
    BlockUIModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  exports: [GraficoVolumeVendasComponent],
})
export class GraficoVolumeVendasModule {}
