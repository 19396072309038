import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecebimentosComponent } from './recebimentos.component';

const routes: Routes = [
  {
    path: '',
    component: RecebimentosComponent,
    children: [
      {
        path: '',
        redirectTo: 'boleto',
        pathMatch: 'full',
      },
      {
        path: 'boleto',
        loadChildren: () =>
          import('./components/titulos-cobranca/titulos-cobranca.module').then(
            (m) => m.TitulosCobrancaModule
          ),
      },
      {
        path: 'pix',
        loadChildren: () =>
          import('./components/pix/pix.module').then((m) => m.PixModule),
      },
      {
        path: 'ted-doc',
        loadChildren: () =>
          import('./components/ted-doc/ted-doc.module').then(
            (m) => m.TedDocModule
          ),
      },
      {
        path: 'ordens-bancarias',
        loadChildren: () =>
          import('./components/ordens-bancarias/ordens-bancarias.module').then(
            (m) => m.OrdensBancariasModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RecebimentosRoutingModule {}
