import { BlockUIModule } from 'ng-block-ui';
import { FormsModule } from '@angular/forms';
import { AgendaCartoesDetalheValorModule } from './components/agenda-cartoes-detalhe-valor/agenda-cartoes-detalhe-valor.module';
import { AgendaCartoesDetalhamentoComponentModule } from './components/agenda-cartoes-detalhamento/agenda-cartoes-detalhamento.module';
import { AgendaCartoesMovimentacaoMesModule } from './components/agenda-cartoes-movimentacao-mes/agenda-cartoes-movimentacao-mes.module';
import { AgendaCartoesResumoGeralModule } from './components/agenda-cartoes-resumo-geral/agenda-cartoes-resumo-geral.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgendaCartoesRoutingModule } from './agenda-cartoes-routing.module';
import { AgendaCartoesComponent } from './agenda-cartoes.component';
import { FiltroAgendaCartoesDetalhamentoPipe } from './pipes/filtro-agenda-cartoes-detalhamento.pipe';
import { ComponentsCommonModule } from '../../../../../shared/common/components-commom.module';
import { AgendaCartoesDisclaimerUsuarioSemAutorizacaoModule } from './components/agenda-cartoes-disclaimer-usuario-sem-autorizacao/agenda-cartoes-disclaimer-usuario-sem-autorizacao.module';
import { NavbarVendasAReceberModule } from '../navbar-vendas-a-receber/navbar-vendas-a-receber.module';
import { BannerConciliadoraModule } from '../../../../../shared/common/components/banner-conciliadora/banner-conciliadora.module';


@NgModule({
  declarations: [AgendaCartoesComponent, FiltroAgendaCartoesDetalhamentoPipe],
  exports: [AgendaCartoesComponent],
  imports: [
    CommonModule,
    FormsModule,
    ComponentsCommonModule,
    AgendaCartoesRoutingModule,
    AgendaCartoesDetalhamentoComponentModule,
    AgendaCartoesDetalheValorModule,
    AgendaCartoesMovimentacaoMesModule,
    AgendaCartoesResumoGeralModule,
    AgendaCartoesDisclaimerUsuarioSemAutorizacaoModule,
    BlockUIModule.forRoot(),
    NavbarVendasAReceberModule,
    BannerConciliadoraModule,
  ],
})
export class AgendaCartoesModule {}
