import { FiltroAgendaCartoesDetalhamento } from '../models/filtro-agenda-cartoes-detalhamento.model';
import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'filtroAgendaCartoesDetalhamento',
  pure: false,
})
export class FiltroAgendaCartoesDetalhamentoPipe implements PipeTransform {
  transform(items: any[], filter: FiltroAgendaCartoesDetalhamento): any {
    if (!items || !filter) {
      return items;
    }

    return items.filter((item) => {
      return (
        (!!!item.numeroPessoaReceitaUsuarioRecebedor ||
          !!!filter.numeroPessoaReceitaUsuarioRecebedor ||
          item.numeroPessoaReceitaUsuarioRecebedor ===
            filter.numeroPessoaReceitaUsuarioRecebedor) &&
        (!!!item.nomeRedeAquisicao ||
          !!!filter.nomeRedeAquisicao ||
          item.nomeRedeAquisicao === filter.nomeRedeAquisicao) &&
        (!!!item.nomeArranjoPagamento ||
          !!!filter.nomeArranjoPagamento ||
          item.nomeArranjoPagamento === filter.nomeArranjoPagamento) &&
        (!!!item.dataEfetivoLiquidacao ||
          !!!filter.dataEfetivoLiquidacao ||
          item.dataEfetivoLiquidacao
            .replaceAll('.', '/')
            .search(filter.dataEfetivoLiquidacao) !== -1) &&
        (!!!item.dataEfetivoLiquidacao ||
          !!!filter.mesDataEfetivoLiquidacao ||
          (item.dataEfetivoLiquidacao.split('.')[1] * 1 ===
            filter.mesDataEfetivoLiquidacao &&
            item.dataEfetivoLiquidacao.split('.')[2] * 1 ===
              filter.anoDataEfetivoLiquidacao))
      );
    });
  }
}
