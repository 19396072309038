export class EndpointsMerge {
  public static readonly LISTAR_VENDAS_REALIZADAS_CARTAO = 'api/conciliacao/listarVendasRealizadas/cartao';

  public static readonly LISTAR_VENDAS_REALIZADAS_PIX = 'api/conciliacao/listarVendasRealizadas/pix';

  public static readonly LISTAR_RECEBIMENTOS_CARTAO = 'api/conciliacao/listarRecebimentos/cartao';

  public static readonly LISTAR_RECEBIMENTOS_PIX = 'api/conciliacao/listarRecebimentos/pix';

  public static readonly LISTAR_PREVISAO_RECEBIMENTOS = 'api/conciliacao/listarPrevisaoRecebimentos';

  public static readonly DETALHE_VENDAS_EXTRATO = 'api/conciliacao/detalharVenda';

  public static readonly CONCILIAR_VENDAS = 'api/conciliacao/conciliarVendas';




  public static readonly CONSULTAR_AVISOS_AO_CLIENTE =
    '/api/avisos-orientacao-cliente';

  public static readonly CONSULTAR_AVISOS_AO_CLIENTE_DIARIOS =
    'api/avisos-orientacao-cliente/diarios';

  public static readonly RELATORIO_BOLETOS_VENDAS =
    'api/relatorios/boletosVendas';

  public static readonly RELATORIO_RECEBIMENTOS_CARTOES =
    'api/relatorios/recebimentosCartoes';

  public static readonly RELATORIO_RECEBIMENTOS_ANTECIPACOES =
    'api/relatorios/recebimentosAntecipacoes';

  public static readonly RELATORIO_RECEBIMENTOS_PIX =
    'api/relatorios/recebimentosPix';

  public static readonly RELATORIO_RECEBIMENTOS_TEDDOC =
    'api/relatorios/recebimentosTedDoc';

  public static readonly VENDAS_COMPARATIVO_MES =
    'api/venda/periodo/sintetizado/mensal';

  public static readonly MAIORES_TAXAS_TIPO = 'api/venda/taxas';

  public static readonly LISTA_BOLETOS = 'api/boleto/despesas';

  public static readonly VENDAS_REALIZADAS_CARTAO =
    'api/venda/consultarVendasRealizadas/cartao';


  public static readonly VENDAS_REALIZADAS_PIX =
  'api/venda/consultarVendasRealizadas/pix';

  public static readonly LISTAR_ADQUIRENTES = 'api/adquirente';

  public static readonly CONSULTA_CNPJ_HABILITADO = 'api/arquivos/download-cnpjs';

  public static readonly INCLUIR_ADQUIRENTE = 'api/servicos/incluir-adquirentes';

  public static readonly CONSULTA_ADQUIRENTE = 'api/adquirente/empresa';

  public static readonly CONSULTA_DADOS_GRAFICO_ADQUIRENTE =
    'api/venda/volume/operadoras';
    public static readonly CONSULTA_VOLUME_VENDAS_ADQUIRENTE =
    'api/venda/volume/adquirentes';

  public static readonly CONSULTA_MODALIDADE_GRAFICO_ADQUIRENTE =
    'api/venda/volume/modalidades';

  public static readonly RESULTADO_FLUXO_CAIXA = 'api/fluxocaixa/resultados';

  public static readonly CONSULTA_MOVIMENTACOES =
    'api/fluxocaixa/movimentacoes';

  public static readonly CONSULTA_GRAFICO_FLUXO_CAIXA =
    'api/fluxocaixa/grafico';

  public static readonly CONSULTA_GRAFICO_RECEBIMENTOS_PERIODO =
    'api/fluxocaixa/grafico/recebimentos';

  public static readonly CONSULTA_GRAFICO_DESPESAS_PERIODO =
    'api/fluxocaixa/grafico/despesas';

  public static readonly PIX_CONSULTAR_LISTA =
    'api/pix/consultarListaTabelaPix';

  public static readonly PIX_CONSULTAR_AG_CONTA =
    'api/pix/consultarAgenciaContaClientePix';

  public static readonly PIX_CONSULTAR_POR_TIPO = 'api/pix/consutarPixPorTipo';

  public static readonly CONSULTA_SALDO = 'api/saldo/empresa';

  public static readonly CONSULTA_CONTAS_EXTERNAS = 'contas-armazenadas/';

  public static readonly CONSULTA_SALDOS_CONTAS_EXTERNAS =
    'contas-armazenadas/saldo';

  public static readonly CONSULTA_EXTRATO_CONTAS_EXTERNAS =
    'api/opb/accounts/extrato';

  public static readonly LISTAR_DESPESAS_CARTOES_CREDITO =
    'api/opb/expenses/creditCards';

  public static readonly LISTAR_DESPESAS_CARTOES_CREDITO_PERIODO =
    'api/opb/expenses/creditCards/despesas';

  public static readonly CONSULTA_LANCAMENTOS_EMPRESTIMOS_PERIODO =
    'api/emprestimo/consultarEmprestimo/periodo';

  public static readonly LISTAR_DESPESAS_EMPRESTIMOS = 'api/opb/expenses/loans';

  public static readonly CONSULTA_DESPESAS_CARTAO_PERIODO =
    'api/cartao/despesas/periodo';

  public static readonly CONSULTA_LANCAMENTOS_CARTAO_PERIODO =
    'api/cartao/consultarLancamentosCartao/periodo';

  public static readonly LISTAR_DESPESAS_FINANCIAMENTOS =
    'api/opb/expenses/financing';

  public static readonly CONSULTA_LANCAMENTOS_EMPRESTIMOS =
    'api/emprestimo/consultarEmprestimo';

  public static readonly CONSULTA_LANCAMENTOS_CARTAO =
    'api/cartao/consultarLancamentosCartao';

  public static readonly ATUALIZA_CONTA_CORRENTE =
    'api/contacorrente/atualizar';

  public static readonly CONSULTA_TERMOS_USUARIO = 'api/termo/consultar/aceite';

  public static readonly ACEITE_TERMOS_USUARIO = 'api/termo/aceite';

  public static readonly PESQUISA_SATISFACAO_USUARIO =
    'api/pesquisa/survey/nps';

  public static readonly LISTAR_CONTAS_POR_EMPRESA_LOGADA =
    'api/contacorrente/';

  public static readonly LISTAR_CARTAO_EMPRESA_LOGADA =
    '/api/faturasCartao/ListarContasCartoesEmpresariasAtivasConsultaFaturas';

  public static readonly CONSULTA_EXTRATO_POR_PERIODO = 'api/extrato/periodo';

  public static readonly LISTAR_TRANSF_TED_DOC_DESPESAS =
    'api/ted-doc/enviados';

  public static readonly LISTAR_AGENDA_CREDITO_CLIENTE =
    'api/agenda/listarAgendaCreditoCliente';

  public static readonly REGISTRAR_EMPRESA_CONCILIADORA =
    'api/conciliadora/registrarEmpresa';

  public static readonly CONSULTAR_AUTORIZACOES_CIP =
    'api/agenda/verificar/autorizacoes';

  public static readonly EXTRATO_TITULOS_COBRANCA =
    'api/titulos/extrato-titulos-cobranca';

  public static readonly TITULOS_COBRANCA_EM_ABERTO =
  'api/titulos/titulos-em-aberto';

  public static readonly VISAO_GERAL_GRAFICOS = 'api/visaogeral/graficos';

  public static readonly LISTA_EXTRATO_PERIODO_BB =
    'api/extrato/formatado';

  public static readonly LISTA_EXTRATO_PERIODO_OPB = 'contas-armazenadas/extrato';

  public static readonly INTERACOES_AVISOS =
    '/api/interacoes-exibicao-avisos-cliente';

  public static readonly EXTRATO_PIX = 'api/recebimentos/pix';

  public static readonly ENDPOINT_SERVICO_CONTRATACAO = 'api/servicos/contratacao';

  public static readonly DETALHAMENTO_PLANO_CONTRATADO = 'api/servicos';

  public static readonly VERIFICAR_CONTRATO = 'api/servicos/verificarcontrato';

  public static readonly HISTORICO_PAGAMENTO_PLANO_CONTRATADO = 'api/servicos/historico';

  public static readonly LISTAR_ADQUIRENTES_CONTRATADAS = 'api/servicos/listar-adquirentes-contratadas';

  public static readonly ALTERAR_PLANO_CONTRATADO = 'api/servicos/alterarplano';

  public static readonly CANCELAR_PLANO_CONTRATADO = 'api/servicos/cancelar';

  public static readonly ENVIAR_CODIGO_DE_AUTORIZAR_ADQUIRENTE = 'api/servicos/atualizar-codigo-autorizacao';

  public static readonly MUDAR_STATUS_PARA_INICIADA = 'api/servicos/liberar-envio';

  public static readonly UPLOAD_ARQUIVOS = 'api/arquivos/upload';

  public static readonly DOWNLOAD_ARQUIVOS = 'api/arquivos/download';

  public static readonly DOWNLOAD_REGULAMENTO_CONCILIADORA = 'api/arquivos/termo-geral';

  public static readonly EXTRATO_ORDENS_BANCARIAS = 'api/ordemBancaria';

  public static readonly LISTAR_TARIFAS = 'api/tarifa/consultarTarifa';

  public static readonly LISTAR_OPERACOES_CREDITO_PERIODO = 'api/opb/expenses/operacoesCredito';

  public static readonly LISTAR_TED_DOC_DESPESAS = 'api/ted-doc/pagamentos';

  public static readonly LISTAR_TED_DOC_RECEBIDOS = 'api/ted-doc/recebidos';
}
