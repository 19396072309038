import {
  TypeEnum,
  AlignEnum,
  FormatEnum,
} from './../../../../../../../shared/common/components/data-table/interfaces/column-detail';
import { ColumnDetail } from 'src/app/merge/shared/common/components/data-table/interfaces/column-detail';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FiltroAgendaCartoesDetalhamentoPipe } from '../../pipes/filtro-agenda-cartoes-detalhamento.pipe';
import { FiltroAgendaCartoesDetalhamento } from '../../models/filtro-agenda-cartoes-detalhamento.model';
import { IListaAgendaCreditoCliente } from '../../models/lancamento.model';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { RelatoriosService } from '../../../../../../../shared/services/relatorios.service';

@Component({
  selector: 'app-agenda-cartoes-detalhamento',
  templateUrl: './agenda-cartoes-detalhamento.component.html',
  styleUrls: ['./agenda-cartoes-detalhamento.component.css'],
})
export class AgendaCartoesDetalhamentoComponent implements OnInit, OnChanges {
  @Input() listaAgendaCreditoCliente: IListaAgendaCreditoCliente[];
  @Input() agencia: number;
  @Input() conta: number;

  mostrarDetalhamento: false;

  listaAgendaCreditoClienteFiltrada: IListaAgendaCreditoCliente[] = [];

  cnpjsFiltro: IListaAgendaCreditoCliente[] = [];
  adquirentesFiltro: IListaAgendaCreditoCliente[] = [];
  bandeirasFiltro: IListaAgendaCreditoCliente[] = [];

  filtroAgendaCartoesDetalhamentoPipe: FiltroAgendaCartoesDetalhamentoPipe =
    new FiltroAgendaCartoesDetalhamentoPipe();
  filtroAgendaCartoesDetalhamento: FiltroAgendaCartoesDetalhamento;

  @BlockUI('bui-agenda-cartoes')
  buiListaContasCorrentes: NgBlockUI;

  defaultDataSort = 'numeroPessoaReceitaUsuarioRecebedor';
  columnsDetails: ColumnDetail[] = [
    {
      dataKey: 'numeroPessoaReceitaUsuarioRecebedor',
      header: 'CNPJ',
      type: TypeEnum.CNPJ,
      align: AlignEnum.LEFT,
      format: FormatEnum.CNPJ,
    },
    {
      dataKey: 'nomeRedeAquisicao',
      header: 'ADQUIRENTE',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'nomeArranjoPagamento',
      header: 'BANDEIRA',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'dataAgenda',
      header: 'LIQUIDAÇÃO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'valorTotal',
      header: 'AGENDA (R$)',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'valorUtilizado',
      header: 'UTILIZADO (R$)',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'valorDisponivel',
      header: 'DISPONÍVEL (R$)',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.CURRENCYBR,
    },
  ];

  constructor(private relatorioService: RelatoriosService) {}

  ngOnInit(): void {
    this.carregarDadosDetalhamento();
  }

  ngOnChanges(): void {
    this.carregarDadosDetalhamento();
  }

  private carregarDadosDetalhamento() {
    if (!this.listaAgendaCreditoCliente) {
      this.listaAgendaCreditoCliente = [];
    }

    this.listaAgendaCreditoClienteFiltrada = Object.assign(
      [],
      this.listaAgendaCreditoCliente
    );
  }

  getlistaAgendaCreditoClienteFiltradas() {
    this.listaAgendaCreditoClienteFiltrada =
      this.filtroAgendaCartoesDetalhamentoPipe.transform(
        this.listaAgendaCreditoCliente,
        this.filtroAgendaCartoesDetalhamento
      );
  }
}
