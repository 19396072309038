import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

interface IDadosGraficoDonut {
  titulo: string;
  total: number;
  legendas: string[];
  percentuais: number[];
  valores: number[];
  cores: string[];
}

@Component({
  selector: 'app-agenda-cartoes-resumo-geral',
  templateUrl: './agenda-cartoes-resumo-geral.component.html',
  styleUrls: ['./agenda-cartoes-resumo-geral.component.css'],
})
export class AgendaCartoesResumoGeralComponent implements OnInit, OnChanges {
  @Input() valorTotalDisponivel: number;
  @Input() valorTotalUtilizado: number;
  @BlockUI('bui-agenda-cartoes')
  buiListaContasCorrentes: NgBlockUI;

  valorDisponivel: number;

  highcharts: any;
  chartOptions: any;
  dadosGrafico: IDadosGraficoDonut;

  constructor() {
    this.highcharts = Highcharts;
  }

  ngOnInit(): void {
    this.valorTotalDisponivel = 0.0;
    this.valorTotalUtilizado = 0.0;
    this.valorDisponivel = 0.0;
    this.montarGrafico();
  }

  ngOnChanges(): void {
    this.montarGrafico();
  }

  montarGrafico() {
    this.dadosGrafico = this.montarDadosGrafico();
    this.exibirGrafico();
  }

  montarDadosGrafico() {
    this.valorDisponivel = this.valorTotalDisponivel - this.valorTotalUtilizado;
    const percentualDisponivel =
      (this.valorDisponivel * 100) / this.valorTotalDisponivel;
    const percentualUtilizado =
      (this.valorTotalUtilizado * 100) / this.valorTotalDisponivel;

    return {
      titulo: 'Despesas',
      total: this.valorTotalDisponivel,
      legendas: ['Disponível', 'Utilizado'],
      percentuais: [percentualDisponivel, percentualUtilizado],
      valores: [this.valorDisponivel, this.valorTotalUtilizado],
      cores: ['#465eff', '#b3bdff'],
    };
  }

  private exibirGrafico() {
    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: { text: '' },
      exporting: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -75,
            y: -5,
            format: '{y}',
            style: { fontWeight: 'bold', color: 'black', fontSize: '14px' },
            filter: { property: 'name', operator: '===' },
          },
          borderWidth: 3,
        },
        series: { animation: true },
      },
      series: [
        {
          type: 'pie',
          name: '',
          innerSize: '50%',
          data: [
            {
              name: this.dadosGrafico.legendas[0],
              y: this.dadosGrafico.percentuais[0],
              color: this.dadosGrafico.cores[0],
              value: this.dadosGrafico.valores[0],
            },
            {
              name: this.dadosGrafico.legendas[1],
              y: this.dadosGrafico.percentuais[1],
              color: this.dadosGrafico.cores[1],
              value: this.dadosGrafico.valores[1],
            },
          ],
        },
      ],
      tooltip: {
        pointFormat: '<strong>{point.percentage:.2f} % / R$ {point.value:.2f}</strong>',
      },
    };
  }
}
