export enum TypeEnum {
  STRING,
  DATE,
  CURRENCY,
  NUMBER,
  AGENCIA_CONTA,
  OBJETO,
  CNPJ,
}

export enum AlignEnum {
  LEFT = 'w-auto text-left',
  RIGHT = 'w-auto text-right',
  CENTER = 'w-auto text-center',
}

export enum FormatEnum {
  NONE = '',
  DATEBR = 'dd/MM/yyyy',
  CURRENCYBR = 'R$',
  TIMESTAMPBR = 'dd/MM/yyyy HH:mm',
  CNPJ = '00.000.000/0000-00',
}

export interface ColumnDetail {
  dataKey: string;
  header: string;
  type: TypeEnum;
  align: AlignEnum;
  format: FormatEnum;
}
