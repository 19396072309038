import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { PagesService } from '../../../../../shared/services/pages.service';
import { HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { IFiltroDatas } from '../../../../../shared/common/interfaces/filtro-datas';
import { IVisaoGeral } from 'src/app/merge/pages/visao-geral/interfaces/visao-geral';

@Component({
  selector: 'app-grafico-geral',
  templateUrl: './grafico-geral.component.html',
  styleUrls: ['./grafico-geral.component.css'],
})
export class GraficoGeralComponent implements OnChanges {
  @Input() estabelecimento: Empresa;
  @Input() filtroDatas: IFiltroDatas;
  paramGraficoGeral = new HttpParams();
  graficoGeral: IVisaoGeral;
  errorConsultaGraficoGeral: boolean;

  @BlockUI('bui-grafico-fluxo-caixa') buiGraficoFluxoCaixa: NgBlockUI;

  constructor(
    private pagesService: PagesService,
    private mensagemService: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const dataInicial = moment(this.filtroDatas.dataInicial).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const dataFinal = moment(this.filtroDatas.dataFinal).set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    const params = new HttpParams()
      .set('dataInicial', dataInicial.format())
      .set('dataFinal', dataFinal.format());

    this.paramGraficoGeral = params;
    this.getConsultaGraficoGeral();
  }

  iniciarGraficoVazio() {
    this.graficoGeral = {
      valoresFluxoCaixaGrafico: {
        datas: ['dd/MM/aaaa'],
        receitas: [0],
        despesas: [0],
        diferencas: [0],
        receitasOPF: [0],
        despesasOPF: [0],
        diferencasOPF: [0],
        caixa: [0],
        caixaOPF: [0],
        temReceitasOPF: true,
        temDespesasOPF: true,
        temReceitas: true,
        temDespesas: true,
        caixaTotal: [0]
      },
      valoresRecebimentosGraficos: null,
      valoresDespesasGraficos: null,
      valoresCaixaDiarioGraficos: null
    };
  }

  getConsultaGraficoGeral() {
    this.errorConsultaGraficoGeral = false;
    this.pagesService
      .getConsultaGraficoFluxoCaixa(
        this.paramGraficoGeral,
        this.buiGraficoFluxoCaixa
      )
      .subscribe(
        (data: any) => {
          this.iniciarGraficoVazio();
          this.graficoGeral.valoresFluxoCaixaGrafico = data.dados;
        },
        (error) => {
          this.iniciarGraficoVazio();
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }
}
