import { Component, Input, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { IGraficosRecebimentosDespesas } from '../../interfaces/visao-geral';

interface IGraficoSaida {
  titulo: string;
  totalPrevisto: number | BigInteger;
  legendas: string[];
  percentuais: Array<number | BigInteger>;
  valores: Array<number | BigInteger>;
  cores: string[];
}

@Component({
  selector: 'app-bloco-despesas-dia',
  templateUrl: './bloco-despesas-dia.component.html',
  styleUrls: [
    './bloco-despesas-dia.component.css',
    '../../visao-geral.component.css',
  ],
})
export class BlocoDespesasDiaComponent implements OnChanges {
  @Input() data: IGraficosRecebimentosDespesas;
  erroConsultaGrafico: boolean;
  dadosGrafico: IGraficosRecebimentosDespesas;
  graficoSaida: IGraficoSaida;
  highcharts: any;
  chartOptions: any;
  dataAtual: Date = new Date();

  constructor() {
    this.highcharts = Highcharts;
    this.erroConsultaGrafico = false;
  }

  ngOnChanges() {
    this.dadosGrafico = this.data || null;
    this.dataAtual = this?.data?.data || new Date();

    this.erroConsultaGrafico = false;
    this.graficoSaida = this.montarGrafico();
    this.exibirGrafico();
  }

  montarGrafico(): IGraficoSaida {
    if (!this.dadosGrafico) {
      this.erroConsultaGrafico = true;
      return;
    }

    return {
      titulo: 'Despesas',
      totalPrevisto: this.dadosGrafico.totalPrevisto,
      legendas: ['Banco do Brasil', 'Outros Bancos'],
      percentuais: [
        this.dadosGrafico.percentualRealizado,
        this.dadosGrafico.percentualRealizadoOPF,
      ],
      valores: [this.dadosGrafico.realizado, this.dadosGrafico.realizadoOPF],
      cores: ['#f97a70', '#ffb5af'],
    };
  }

  exibirGrafico() {
    if (!this.graficoSaida) {
      this.erroConsultaGrafico = true;
      return;
    }

    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: { text: '' },
      exporting: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -75,
            y: -5,
            format: '{y}',
            style: { fontWeight: 'bold', color: 'black', fontSize: '14px' },
            filter: { property: 'name', operator: '===' },
          },
          borderWidth: 3,
        },
        series: { animation: true },
      },
      series: [
        {
          type: 'pie',
          name: '',
          innerSize: '50%',
          data: [
            {
              name: this.graficoSaida.legendas[0],
              y: this.graficoSaida.percentuais[0],
              color: this.graficoSaida.cores[0],
              value: this.graficoSaida.valores[0],
            },
            {
              name: this.graficoSaida.legendas[1],
              y: this.graficoSaida.percentuais[1],
              color: this.graficoSaida.cores[1],
              value: this.graficoSaida.valores[1],
            },
          ],
        },
      ],
      tooltip: {
        pointFormat: '<strong>{point.percentage:.2f} % / R$ {point.value:.2f}</strong>',
      },
    };
  }
}
