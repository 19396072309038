import {ApiService, Response} from 'merge-common-ng';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NgBlockUI} from 'ng-block-ui';
import {EndpointsMerge} from '../common/endpointsmerge';
import {
  ArquivoUploadModel
} from '../../pages/cartoes/meu-plano/components/painel-acompanhamento/components/model/arquivo-upload.model';
import {HttpParams} from '@angular/common/http';
import {ArquivoModel} from '../common/models/arquivo.model';

@Injectable({
    providedIn: 'root',
})

export class ArquivosService {
    constructor(public apiService: ApiService) { }

    enviarArquivos(body: ArquivoUploadModel[], ngBlockUI?: NgBlockUI): Observable<Response<void>> {
        return this.apiService.post(
            EndpointsMerge.UPLOAD_ARQUIVOS,
            body,
            null,
            ngBlockUI
        );
    }

    baixarArquivos(body: HttpParams, ngBlockUI?: NgBlockUI): Observable<Response<any>> {
        return this.apiService.get(
            EndpointsMerge.DOWNLOAD_ARQUIVOS,
            body,
            ngBlockUI
        );
    }

    baixarRegulamentoConciliadora(ngBlockUI?: NgBlockUI): Observable<Response<ArquivoModel>> {
        return this.apiService.get(
            EndpointsMerge.DOWNLOAD_REGULAMENTO_CONCILIADORA,
            null,
            ngBlockUI
        );
    }

    getCNPJs(ngBlockUI?: NgBlockUI): Observable<Response<string[]>> {
        return this.apiService.get(
            EndpointsMerge.CONSULTA_CNPJ_HABILITADO,
            null,
            ngBlockUI
          );
    }
}
