import { DataTableModule } from './../../../../../shared/common/components/data-table/data-table.module';
import { DataTableComponent } from './../../../../../shared/common/components/data-table/data-table.component';
import { FormsModule } from '@angular/forms';
import { DatepickerModuleEnum } from 'merge-common-ng';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlockUIModule } from 'ng-block-ui';

import { ComponentsCommonModule } from './../../../../../shared/common/components-commom.module';
import { NavbarDespesasModule } from './../navbar-despesas/navbar-despesas.module';
import { CartaoRoutingModule } from './cartao-routing.module';
import { CartaoComponent } from './cartao.component';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [CartaoComponent],
  exports: [CartaoComponent],
  imports: [
    CommonModule,
    CartaoRoutingModule,
    ComponentsCommonModule,
    FormsModule,
    NgxPaginationModule,
    BlockUIModule.forRoot(),
    NavbarDespesasModule,
    DatepickerModuleEnum,
    DataTableModule,
  ],
})
export class CartaoModule {}
