import { HighchartsChartModule } from 'highcharts-angular';
import { CommonModule } from '@angular/common';
import { AgendaCartoesResumoGeralComponent } from './agenda-cartoes-resumo-geral.component';
import { NgModule } from '@angular/core';
import { BlockUIModule } from 'ng-block-ui';

@NgModule({
  declarations: [AgendaCartoesResumoGeralComponent],
  imports: [CommonModule, HighchartsChartModule, BlockUIModule.forRoot()],
  exports: [AgendaCartoesResumoGeralComponent],
})
export class AgendaCartoesResumoGeralModule {}
