import { DadosTablePix } from './../../../../shared/common/components/extrato-contas/interfaces/dados-table-pix';

export class PixOutputDTO {
  public quantidadeRegistro: number;
  public valorTotal: number;
  public listaLancamento: DadosTablePix[];

  public constructor(init?: Partial<PixOutputDTO>) {
    Object.assign(this, init);
  }
}
