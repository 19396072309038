import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import {IGraficoFluxoCaixa, IVisaoGeral} from '../../../../pages/visao-geral/interfaces/visao-geral';
import { NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-grafico-fluxo-caixa',
  templateUrl: './grafico-fluxo-caixa.component.html',
  styleUrls: ['./grafico-fluxo-caixa.component.css'],
})
export class GraficoFluxoCaixaComponent implements OnChanges {
  @Input() errorConsultaGraficoGeral: boolean;
  @Input() data: IGraficoFluxoCaixa;
  @Input() buiVisaoGeralGrafico: NgBlockUI;

  hightChartsGraficoGeral: any;
  chartOptionsGraficoGeral: any;

  constructor() {
    this.hightChartsGraficoGeral = Highcharts;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data !== undefined) {
      this.createGraficoGeral();
    }
  }

  createGraficoGeral() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: '.',
        decimalPoint: ',',
      },
    });
    let valoresFluxoCaixaGrafico = this.data;
    if (valoresFluxoCaixaGrafico == null) {
      valoresFluxoCaixaGrafico = {
        datas: ['dd/MM/aaaa'],
        receitas: [0],
        despesas: [0],
        diferencas: [0],
        receitasOPF: [0],
        despesasOPF: [0],
        diferencasOPF: [0],
        caixa: [0],
        caixaOPF: [0],
        temReceitas: false,
        temDespesas: false,
        temReceitasOPF: false,
        temDespesasOPF: false,
        caixaTotal: [0]
      };
    }
    this.chartOptionsGraficoGeral = {
      title: { text: '' },
      credits: { enabled: false },
      xAxis: {
        categories: valoresFluxoCaixaGrafico.datas,
        tickWidth: 0,
        labels: {
          rotation: 0,
          formatter: (event) => {
            const dia = event.value.substr(0, 5);
            if (event.isFirst || event.isLast) {
              return `<span style="display: block; font-size="8px;">` + dia + `</span>`;
            } else {
              return `<span style="display: none">` + dia + ` </span>`;
            }
          },
          x: 0,
          style: {
            position: 'absolute',
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      plotOptions: {
        column: {
          pointWidth: 250 / valoresFluxoCaixaGrafico.datas.length,
          pointPadding: 0,
          borderWidth: 0,
        },
        series: {
          borderRadius: 40 / valoresFluxoCaixaGrafico.datas.length,
          grouping: true,
          borderWidth: 0
        }
      },
      series: [
        {
          id: 'receitasOPF',
          type: 'column',
          name: 'Receitas Outros bancos',
          data: valoresFluxoCaixaGrafico.receitasOPF,
          color: '#a3afff',
          tooltip: {
            valueDecimals: 2,
            valuePrefix: 'R$ ',
          },
          point: {
            events: {
              mouseOver: (event) => this.mouseOver(event),
              mouseOut: (event) => this.mouseOut(event),
            },
          },
        },
        {
          type: 'column',
          name: 'Receitas BB',
          data: valoresFluxoCaixaGrafico.receitas,
          color: '#465eff',
          tooltip: {
            valueDecimals: 2,
            valuePrefix: 'R$ ',
          },
          pointPlacement: -0.1,
          point: {
            events: {
              mouseOver: (event) => this.mouseOver(event),
              mouseOut: (event) => this.mouseOut(event),
            },
          },
        },
        {
          id: 'despesasOPF',
          type: 'column',
          name: 'Despesas Outros Bancos',
          data: valoresFluxoCaixaGrafico.despesasOPF,
          color: '#ffb5af',
          tooltip: {
            valueDecimals: 2,
            valuePrefix: 'R$ ',
          },
          point: {
            events: {
              mouseOver: (event) => this.mouseOver(event),
              mouseOut: (event) => this.mouseOut(event),
            },
          },
        },
        {
          type: 'column',
          name: 'Despesas BB',
          data: valoresFluxoCaixaGrafico.despesas,
          color: '#f97a70',
          tooltip: {
            valueDecimals: 2,
            valuePrefix: 'R$ ',
          },
          pointPlacement: -0.1,
          point: {
            events: {
              mouseOver: (event) => this.mouseOver(event),
              mouseOut: (event) => this.mouseOut(event),
            },
          },
        },
        {
          type: 'spline',
          name: 'Caixa',
          data: valoresFluxoCaixaGrafico.caixaTotal,
          marker: {
            lineWidth: 2,
            lineColor: Highcharts.getOptions().colors[3],
            fillColor: 'white',
          },
          tooltip: {
            valueDecimals: 2,
            valuePrefix: 'R$ ',
          },
        },
      ],
    };
  }

  mouseOver(event: any): void {
    $(
      event.target.series.xAxis.labelGroup.element.childNodes[
        event.target.index
      ].childNodes[0]
    ).css('display', 'block');
  }

  mouseOut(event: any): void {
    if (
      event.target.index &&
      event.target.index !== event.target.series.data.length - 1
    ) {
      $(
        event.target.series.xAxis.labelGroup.element.childNodes[
          event.target.index
        ].childNodes[0]
      ).css('display', 'none');
    }
  }
}
