import {
  TypeEnum,
  AlignEnum,
  FormatEnum,
  ColumnDetail,
} from './../../../../../shared/common/components/data-table/interfaces/column-detail';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DataInputComRegra } from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom-enum/data-input';
import { Empresa } from 'merge-common-ng';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { TemplateService } from 'src/app/merge/pages/template/template.service';
import { CartaoService } from 'src/app/merge/shared/services/cartao.service';
import { TimestampBehaviourService } from 'src/app/merge/shared/services/timestamp-behaviour.service';

import { IFiltroDatas } from './../../../../../shared/common/interfaces/filtro-datas';
import { ConsultaLancamentosCartaoDto } from './../../models/consulta-lancamentos-cartao-dto';
import { LancamentoDespesaCartaoDto } from './../../models/lancamento-despesa-cartao-dto';

@Component({
  selector: 'app-cartao',
  templateUrl: './cartao.component.html',
  styleUrls: ['./cartao.component.css'],
})
export class CartaoComponent implements OnInit {
  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  dataSelecionada: DataInputComRegra;
  listaLancamentosDespesaCartao: LancamentoDespesaCartaoDto[] = [];
  textoFiltro = '';

  @BlockUI('bui-despesas-cartao') buiDepesasCartao: NgBlockUI;

  defaultDataSort = 'dataRealizacao';
  columnsDetails: ColumnDetail[] = [
    {
      dataKey: 'dataRealizacao',
      header: 'DATA REALIZADO',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'descricao',
      header: 'DESCRIÇÃO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'valorLiquido',
      header: 'VALOR LÍQUIDO',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.CURRENCYBR,
    },
  ];

  constructor(
    private cartaoService: CartaoService,
    private mensagemService: ToastrService,
    private timestampService: TimestampBehaviourService,
    private templateService: TemplateService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this._setFiltroDatas(data));

    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));
    this.timestampService.updateRegraCalendario();
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);
    this.timestampService.getCalendarioSubject().subscribe((date) => {
      this.dataSelecionada = date;
    });
    this._carregarDespesasCartao();
  }

  private _setFiltroDatas(data: DataInputComRegra): void {
    this.dataSelecionada = data;
    this.filtroDatas = this.timestampService.translateDateForInput(data);
  }

  private _carregarDespesasCartao(): void {
    this.cartaoService
      .getListaDespesasCartaoCreditoITE(
        this._parametroPesquisaLancamentosCartao(),
        this.buiDepesasCartao
      )
      .subscribe(
        (respostaIte) => {
          this.listaLancamentosDespesaCartao.push(...respostaIte.dados);
          this._ordenarPorData();
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
    this.cartaoService
      .getListaDespesasCartaoCreditoOPF(
        this._parametroPesquisaLancamentosCartao(),
        this.buiDepesasCartao
      )
      .subscribe(
        (respostaOpf) => {
          this.listaLancamentosDespesaCartao.push(...respostaOpf.dados);
          this._ordenarPorData();
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  private _parametroPesquisaLancamentosCartao(): ConsultaLancamentosCartaoDto {
    const dto = new ConsultaLancamentosCartaoDto();
    dto.dataInicial = this.filtroDatas.dataInicial;
    dto.dataFinal = this.filtroDatas.dataFinal;
    return dto;
  }

  private _ordenarPorData(): void {
    this.listaLancamentosDespesaCartao.sort(
      (a, b) =>
        new Date(a.dataRealizacao).getTime() -
        new Date(b.dataRealizacao).getTime()
    );
  }
}
