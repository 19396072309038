import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FiltroAgendaCartoesDetalhamento } from '../../models/filtro-agenda-cartoes-detalhamento.model';
import { FiltroAgendaCartoesDetalhamentoPipe } from '../../pipes/filtro-agenda-cartoes-detalhamento.pipe';

import * as Highcharts from 'highcharts';
import { IListaAgendaCreditoCliente } from '../../models/lancamento.model';

interface IMovimentacaoMes {
  mes: number;
  ano: number;
  total: number;
  disponivel: number;
  utilizado: number;
  percentualDisponivel: number;
  percentualUtilizado: number;
}

interface IDadosGraficoDonut {
  titulo: string;
  total: number;
  legendas: string[];
  percentuais: number[];
  valores: number[];
  cores: string[];
}

@Component({
  selector: 'app-agenda-cartoes-movimentacao-mes',
  templateUrl: './agenda-cartoes-movimentacao-mes.component.html',
  styleUrls: ['./agenda-cartoes-movimentacao-mes.component.css'],
})
export class AgendaCartoesMovimentacaoMesComponent
  implements OnInit, OnChanges
{
  private static readonly dadosMovimentacaoMesZerado = {
    total: 0.0,
    disponivel: 0.0,
    utilizado: 0.0,
    percentualDisponivel: 0.0,
    percentualUtilizado: 0.0,
    mes: new Date().getUTCMonth() + 1,
    ano: new Date().getFullYear(),
  };

  @Input() listaAgendaCreditoCliente: Array<IListaAgendaCreditoCliente>;
  @BlockUI('bui-agenda-cartoes')
  buiListaContasCorrentes: NgBlockUI;

  filtroAgendaCartoesDetalhamentoPipe: FiltroAgendaCartoesDetalhamentoPipe =
    new FiltroAgendaCartoesDetalhamentoPipe();
  filtroAgendaCartoesDetalhamento: FiltroAgendaCartoesDetalhamento;

  dataAtual: Date;

  movimentacaoMes: IMovimentacaoMes;

  highcharts: any;
  chartOptions: any;
  dadosGrafico: IDadosGraficoDonut;

  constructor() {
    this.highcharts = Highcharts;
    this.dataAtual = new Date();
  }

  ngOnInit(): void {
    this.carregaDadosResumoGeral();
  }

  ngOnChanges(): void {
    this.carregaDadosResumoGeral();
  }

  carregarDadosMesAnterior() {
    this.incrementaMes(-1);
  }

  carregarDadosMesPosterior() {
    this.incrementaMes(1);
  }

  private incrementaMes(incremento: number): void {
    this.dataAtual = new Date(
      this.dataAtual.getFullYear(),
      this.dataAtual.getMonth() + incremento,
      this.dataAtual.getDate()
    );

    this.carregaDadosResumoGeral();
  }

  private carregaDadosResumoGeral() {
    if (!this.listaAgendaCreditoCliente) {
      this.listaAgendaCreditoCliente = [];
    }

    this.filtroAgendaCartoesDetalhamento = {
      mesDataEfetivoLiquidacao: this.dataAtual.getMonth() + 1,
      anoDataEfetivoLiquidacao: this.dataAtual.getFullYear(),
    };

    const filtroAgendaCartoesDetalhamentoFiltrada =
      this.filtroAgendaCartoesDetalhamentoPipe.transform(
        this.listaAgendaCreditoCliente,
        this.filtroAgendaCartoesDetalhamento
      );

    this.movimentacaoMes = Object.assign(
      {},
      AgendaCartoesMovimentacaoMesComponent.dadosMovimentacaoMesZerado
    );

    filtroAgendaCartoesDetalhamentoFiltrada.forEach((element) => {
      this.movimentacaoMes.total += element.valorTotal;
      this.movimentacaoMes.utilizado += element.valorUtilizado;
      this.movimentacaoMes.disponivel += element.valorDisponivel;
    });

    this.montarDadosGrafico();
  }

  montarDadosGrafico() {
    const percentualDisponivel =
      (this.movimentacaoMes.disponivel * 100) / this.movimentacaoMes.total;
    const percentualUtilizado =
      (this.movimentacaoMes.utilizado * 100) / this.movimentacaoMes.total;
    this.dadosGrafico = {
      titulo: 'Despesas',
      total: this.movimentacaoMes.total,
      legendas: ['Disponível', 'Utilizado'],
      percentuais: [percentualDisponivel, percentualUtilizado],
      valores: [
        this.movimentacaoMes.disponivel,
        this.movimentacaoMes.utilizado,
      ],
      cores: ['#f97a70', '#fee4e2'],
    };

    this.exibirGrafico();
  }

  private exibirGrafico() {
    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: { text: '' },
      exporting: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -75,
            y: -5,
            format: '{y}',
            style: { fontWeight: 'bold', color: 'black', fontSize: '14px' },
            filter: { property: 'name', operator: '===' },
          },
          borderWidth: 3,
        },
        series: { animation: true },
      },
      series: [
        {
          type: 'pie',
          name: '',
          innerSize: '50%',
          data: [
            {
              name: this.dadosGrafico.legendas[0],
              y: this.dadosGrafico.percentuais[0],
              color: this.dadosGrafico.cores[0],
              value: this.dadosGrafico.valores[0],
            },
            {
              name: this.dadosGrafico.legendas[1],
              y: this.dadosGrafico.percentuais[1],
              color: this.dadosGrafico.cores[1],
              value: this.dadosGrafico.valores[1],
            },
          ],
        },
      ],
      tooltip: {
        pointFormat: '<strong>{point.percentage:.2f} % / R$ {point.value:.2f}</strong>',
      },
    };
  }
}
