import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { PagesService } from 'src/app/merge/shared/services/pages.service';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Empresa, Response } from 'merge-common-ng';
import * as Highcharts from 'highcharts';
import { IGraficosRecebimentosDespesas } from '../../../../../shared/common/interfaces/graficos-recebimentos-despesas';
import moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IFiltroDatas } from '../../../../../shared/common/interfaces/filtro-datas';

interface IGraficoSaida {
    titulo: string;
    totalPrevisto: number | BigInteger;
    legendas: string[];
    percentuais: Array<number | BigInteger>;
    valores: Array<number | BigInteger>;
    cores: string[];
    erroConsultaGrafico: boolean;
}

@Component({
    selector: 'app-bloco-despesas',
    templateUrl: './bloco-despesas.component.html',
    styleUrls: ['./bloco-despesas.component.css'],
})
export class BlocoDespesasComponent implements OnChanges {

    @Input() estabelecimento: Empresa;
    @Input() filtroDatas: IFiltroDatas;

    @Output() despesasParaGraficoCaixa = new EventEmitter<number>();

    erroConsultaGrafico: boolean;
    dadosGrafico: IGraficosRecebimentosDespesas;
    graficoSaida: IGraficoSaida;

    highcharts: any;
    chartOptions: any;

    @BlockUI('bui-despesas') buiDespesas: NgBlockUI;

    constructor(
        private pagesService: PagesService,
        private mensagemService: ToastrService
    ) {
        this.highcharts = Highcharts;
        this.erroConsultaGrafico = false;

        this.dadosGrafico = this.iniciarGraficoVazio();
        this.graficoSaida = this.montarGrafico();
        this.exibirGrafico();
    }

    ngOnChanges() {
        this.getConsultaGraficoSaidaMensal(this.montarParamsGraficoSaidaMensal());
    }

    iniciarGraficoVazio() {
        return {
            totalPrevisto: 0,
            realizado: 0,
            pendente: 0,
            percentualRealizado: 0,
            percentualPendente: 0,
            totalPrevistoOPF: 0,
            realizadoOPF: 0,
            pendenteOPF: 0,
            percentualRealizadoOPF: 0,
            percentualPendenteOPF: 0,
            percentualBb: 0,
            percentualOPF: 0,
            totalRealizadoBBOPF: 0
        };
    }

    montarParamsGraficoSaidaMensal(): HttpParams {
        const dataInicial = moment(this.filtroDatas.dataInicial).set({
            hour: 0,
            minute: 0,
            second: 0,
        });
        const dataFinal = moment(this.filtroDatas.dataFinal).set({
            hour: 23,
            minute: 59,
            second: 59,
        });

        return new HttpParams()
            .set('dataInicial', dataInicial.format())
            .set('dataFinal', dataFinal.format());
    }

    getConsultaGraficoSaidaMensal(params: HttpParams) {

        this.pagesService
            .getConsultaGraficoDespesasPeriodo(params, this.buiDespesas)
            .subscribe(
                (data: Response<IGraficosRecebimentosDespesas>) => {
                    this.erroConsultaGrafico = false;
                    this.dadosGrafico = data.dados;

                    this.tratarRetornoConsulta();

                    this.graficoSaida = this.montarGrafico();
                    this.exibirGrafico();
                },
                (error) => {
                    this.erroConsultaGrafico = true;
                    this.mensagemService.error(error.erros[0].message);
                }
            );
    }

    tratarRetornoConsulta(): void {

        /*
        * Após a consulta dos recebimentos, envia para o grafico "Caixa",
        * pois este é a diferença dos recebimentos pelas despesas
        */
        this.despesasParaGraficoCaixa.emit(Number(parseFloat(this.dadosGrafico?.realizado.toString())
                                                + parseFloat(this.dadosGrafico?.realizadoOPF.toString())));

        this.graficoSaida = this.montarGrafico();
        this.exibirGrafico();
    }


    montarGrafico(): IGraficoSaida {

        if (!this.dadosGrafico) {
            this.erroConsultaGrafico = true;
            return null;
        }

        return {
            titulo: 'Despesas',
            totalPrevisto: this.dadosGrafico.totalRealizadoBBOPF,
            legendas: ['Banco do Brasil', 'Outros Bancos'],
            percentuais: [
                this.dadosGrafico.percentualBb,
                this.dadosGrafico.percentualOPF,
            ],
            valores: [this.dadosGrafico.realizado, this.dadosGrafico.realizadoOPF],
            cores: ['#f97a70', '#fee4e2'],
            erroConsultaGrafico: false,
        };
    }

    exibirGrafico() {

        if (!this.graficoSaida) {
            this.erroConsultaGrafico = true;
            return;
        }

        this.chartOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
            },
            title: { text: '' },
            exporting: { enabled: false },
            credits: { enabled: false },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -75,
                        y: -5,
                        format: '{y}',
                        style: { fontWeight: 'bold', color: 'black', fontSize: '14px' },
                        filter: { property: 'name', operator: '===' },
                    },
                    borderWidth: 3,
                },
                series: { animation: true },
            },
            series: [
                {
                    type: 'pie',
                    name: '',
                    innerSize: '50%',
                    data: [
                        {
                            name: this.graficoSaida.legendas[0],
                            y: this.graficoSaida.percentuais[0],
                            color: this.graficoSaida.cores[0],
                            value: this.graficoSaida.valores[0],
                        },
                        {
                            name: this.graficoSaida.legendas[1],
                            y: this.graficoSaida.percentuais[1],
                            color: this.graficoSaida.cores[1],
                            value: this.graficoSaida.valores[1],
                        },
                    ],
                },
            ],
            tooltip: {
                pointFormat: '<strong>{point.percentage:.2f} % / R$ {point.value:.2f}</strong>',
            },
        };
    }
}
