import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MarketplaceService } from './marketplace.service';
import { IParceria } from './interfaces/parceria';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.css'],
})
export class MarketplaceComponent implements OnInit {
  listaParceriasVigentes: IParceria[];
  consultaComErro: boolean;
  @BlockUI('bui-marketplace') buiMarketplace: NgBlockUI;

  constructor(
    private toastService: ToastrService,
    private marketplaceService: MarketplaceService
  ) {
    this.consultaComErro = false;
  }

  ngOnInit(): void {
    this.marketplaceService
      .listarParceriasVigentes(this.buiMarketplace)
      .subscribe(
        (response) => {
          this.listaParceriasVigentes = response.dados;
        },
        (error) => {
          console.error(error);
          this.consultaComErro = true;
          this.toastService.error(
            'Não foi possível carregar a lista de parcerias vigentes.'
          );
        }
      );
  }
}
