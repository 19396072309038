import { Component } from '@angular/core';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Component({
  selector: 'app-convite-open-banking',
  templateUrl: './convite-open-banking.component.html',
  styleUrls: ['./convite-open-banking.component.css'],
})
export class ConviteOpenBankingComponent {
  close = true;
  constructor(private matomoTracker: MatomoTracker) {}

  open(trackOPB: number) {
    this.matomoTracker.trackEvent(
      'Interacoes',
      'clicou',
      'botão OPB',
      trackOPB
    );
    this.close = !this.close;
  }
}
