import {Injectable} from '@angular/core';
import {ApiService, Hosts, Response} from 'merge-common-ng';
import {NgBlockUI} from 'ng-block-ui';
import {Observable} from 'rxjs';

import {DadosOperacaoCreditoDto} from './../../pages/meu-negocio/despesas/models/dados-operacao-credito-dto';

@Injectable({
  providedIn: 'root',
})
export class OperacaoCreditoService {
  LISTAR_OPERACOES_CREDITO_PERIODO = 'api/opb/expenses/operacoesCredito';

  constructor(private apiService: ApiService) {}

  getListaOperacoesCreditoNoPeriodo(
    params: any,
    ngBlockUI?: NgBlockUI
  ): Observable<Response<DadosOperacaoCreditoDto>> {
    return this.apiService.get(
      this.LISTAR_OPERACOES_CREDITO_PERIODO,
      params,
      ngBlockUI,
      true,
      Hosts.HOST_OPF
    );
  }
}
