import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DespesasComponent } from './despesas.component';

const routes: Routes = [
  {
    path: '',
    component: DespesasComponent,
    children: [
      {
        path: '',
        redirectTo: 'cartao',
        pathMatch: 'full',
      },
      {
        path: 'cartao',
        loadChildren: () =>
          import('./components/cartao/cartao.module').then(
            (m) => m.CartaoModule
          ),
      },
      {
        path: 'boleto',
        loadChildren: () =>
          import('./components/boleto/boleto.module').then(
            (m) => m.BoletoModule
          ),
      },
      {
        path: 'pix',
        loadChildren: () =>
          import('./components/pix/pix.module').then((m) => m.PixModule),
      },
      {
        path: 'ted-doc',
        loadChildren: () =>
          import('./components/ted-doc/ted-doc.module').then(
            (m) => m.TedDocModule
          ),
      },
      {
        path: 'operacoes-credito',
        loadChildren: () =>
          import('./components/operacao-credito/operacao-credito.module').then(
            (m) => m.OperacaoCreditoModule
          ),
      },
      {
        path: 'tarifas',
        loadChildren: () =>
          import('./components/tarifa/tarifa.module').then(
            (m) => m.TarifaModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DespesasRoutingModule {}
