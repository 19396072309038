import {
  ColumnDetail,
  TypeEnum,
  AlignEnum,
  FormatEnum,
} from './../../../../../shared/common/components/data-table/interfaces/column-detail';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Component, OnInit } from '@angular/core';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { DadosRespostaConsultarTitulosCobranca } from '../../models/DadosRespostaConsultarTitulosCobranca';
import { ListaRegistro } from '../../models/ListaRegistro';
import { FilterExtratoBoleto } from '../../filters/filtro-extrato-boleto.pipe';
import { IFiltroExtratoVendasBoletos } from '../../interfaces/filtro-extrato-vendas-boletos';
import { IVendaBoletos } from '../../models/extrato-vendas-boletos';
import { PagesService } from '../../../../../shared/services/pages.service';
import { DadosGraficosVendaBoleto } from '../../../../../shared/common/interfaces/dados-graficos-venda-boleto';
import { TicketsTitulo } from '../../models/TicketsTitulo';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../../../../shared/services/common.service';
import { IFiltroDatas } from '../../../../../shared/common/interfaces/filtro-datas';
import { TemplateService } from '../../../../template/template.service';
import { TimestampBehaviourService } from '../../../../../shared/services/timestamp-behaviour.service';
import { DataInputComRegra } from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom-enum/data-input';
import { RelatoriosService } from '../../../../../shared/services/relatorios.service';
import { HttpParams } from '@angular/common/http';
import { ITituloCobranca } from '../../../recebimentos/components/titulos-cobranca/interfaces/titulo-cobranca';

@Component({
  selector: 'app-conciliacao-vendas-boletos',
  templateUrl: './conciliacao-vendas-boletos.component.html',
  styleUrls: ['./conciliacao-vendas-boletos.component.css'],
})
export class ConciliacaoVendasBoletosComponent implements OnInit {
  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  date: DataInputComRegra;
  dadosRespostaConsultarTitulosCobranca: DadosRespostaConsultarTitulosCobranca;

  @BlockUI('bui-conciliacao-boleto-vendas') buiConciliacaoBoletoVendas: NgBlockUI;

  filterChangeExtratoVendas: FilterExtratoBoleto = new FilterExtratoBoleto();

  listaRegistro: ListaRegistro[];
  listaRegistroFiltrado: ListaRegistro[] = new Array<ListaRegistro>();

  situacoes = new Array<IVendaBoletos>();
  listaRegistroDetalhe: ListaRegistro;

  titulosEmAberto: ITituloCobranca[];
  qntdEmAberto: number;

  filtroExtratoVendasBoleto: IFiltroExtratoVendasBoletos;
  dadosGraficoValorUnitario: DadosGraficosVendaBoleto;
  dadosGraficoTicketMedio: DadosGraficosVendaBoleto;
  dadosGraficoMediaVendas: DadosGraficosVendaBoleto;
  totalVendas: number;
  ticketsTitulo: TicketsTitulo;

  itemPluralMapping = {
    venda: {
      '=0': '0 Venda',
      '=1': '1 Venda',
      other: '# Vendas',
    },
  };

  defaultDataSort = 'nomeSacadoCobranca';

  columnsDetails: ColumnDetail[] = [
    {
      dataKey: 'pagador',
      header: 'PAGADOR',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'dataEmissao',
      header: 'DATA EMISSÃO',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'dataVencimento',
      header: 'DATA VENCIMENTO',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'situacao',
      header: 'SITUAÇÃO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'valorLiquido',
      header: 'VALOR',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.CURRENCYBR,
    },
  ];

  constructor(
    public relatorioService: RelatoriosService,
    private pagesService: PagesService,
    private mensagemService: ToastrService,
    private commonService: CommonService,
    private templateService: TemplateService,
    private timestampService: TimestampBehaviourService
  ) {
    this.estabelecimento = null;
  }

  ngOnInit(): void {
    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this.setFiltroDatas(data));
    this.timestampService.updateRegraCalendario();
  }

  private setFiltroDatas(data: DataInputComRegra): void {
    this.filtroDatas = this.timestampService.translateDateForInput(data);
    this.date = data;
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);
    this.timestampService.getCalendarioSubject().subscribe((date) => {
      this.date = date;
    });
    this.listarTitulosCobrancaEmAberto();
  }

  construirGraficos() {
    if (this.ticketsTitulo !== undefined) {
      this.dadosGraficoValorUnitario = {
        title: 'Valor total',
        valorVenda: this.ticketsTitulo.valorLiquido.valorLiquido,
        dias: this.getDiasGrafico(this.ticketsTitulo.valorLiquido.valores),
        color: '#465EFF',
        dadosGrafico: [
          {
            name: 'Média',
            type: 'area',
            data: this.getValoresGrafico(
              this.ticketsTitulo.valorLiquido.valores
            ),
          },
        ],
      };

      this.dadosGraficoMediaVendas = {
        title: 'Média vendas',
        valorVenda: this.ticketsTitulo.mediaVendas.mediaVendas,
        dias: this.getDiasGrafico(this.ticketsTitulo.mediaVendas.valores),
        color: '#83FFEA',
        dadosGrafico: [
          {
            name: 'Média',
            type: 'area',
            data: this.getValoresGrafico(
              this.ticketsTitulo.mediaVendas.valores
            ),
          },
        ],
      };

      this.dadosGraficoTicketMedio = {
        title: 'Ticket médio',
        valorVenda: this.ticketsTitulo.ticketMedio.valorTicketMedio,
        dias: this.getDiasGrafico(this.ticketsTitulo.ticketMedio.valores),
        color: '#735CC6',
        dadosGrafico: [
          {
            name: 'Média',
            type: 'area',
            data: this.getValoresGrafico(
              this.ticketsTitulo.ticketMedio.valores
            ),
          },
        ],
      };
    }
  }

  detalhamento(listaRegistro) {
    this.listaRegistroDetalhe = listaRegistro;
  }

  fecharDetalhamento(event) {
    this.listaRegistroDetalhe = undefined;
  }

  private getParams() {
    return {
      codigoTipoInscricaoSacado: 2,
      dataInicio: this.filtroDatas.dataInicial,
      dataFim: this.filtroDatas.dataFinal,
    };
  }

  getDiasGrafico(dias: any[]): string[] {
    return dias.map((a) => moment(a.data).locale('pt-br').format('DD/MMMM'));
  }

  getValoresGrafico(valores: any[]): any[] {
    return valores.map((a) => {
      return a.valor !== undefined ? a.valor : a.quandidade;
    });
  }

  gerarRelatorioBoletosVendas() {
    this.relatorioService.gerarRelatorioBoletosVendas(this.getParams());
  }

  listarTitulosCobrancaEmAberto(): void {
    const dataFinal = moment(this.filtroDatas.dataFinal).set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    const params = new HttpParams()
      .set('dataFinal', dataFinal?.format());

    this.pagesService
      .getListarTitulosCobrancaEmAberto(params, this.buiConciliacaoBoletoVendas)
      .subscribe(
        (resposta) => {
          this.titulosEmAberto = resposta.dados;
          this.qntdEmAberto = this.titulosEmAberto.length;
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }
}
