import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpf',
})
export class CPFPipe implements PipeTransform {
  transform(value: number | string): string {
    try {
      if (value === undefined) {
        return '';
      }

      value = String(value);
      value = value.padStart(11, '0');
      value = value.replace(/[^\d]/g, '');

      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } catch (error) {
      console.error(error);
      return String(value);
    }
  }
}
