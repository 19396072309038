import { Pipe, PipeTransform } from '@angular/core';
import { IFiltroResumoRecebimentos } from '../interfaces/filtro-resumo-recebimentos';

@Pipe({
  name: 'filterResumoRecebimentos',
  pure: false,
})
export class FilterResumoRecebimentos implements PipeTransform {
  transform(items: any[], filter: IFiltroResumoRecebimentos): any {
    if (!items || !filter) {
      return items;
    }

    return items.filter((item) => {
      return (
        (!item.codigoStatusConciliacao ||
          !filter.codigoStatusConciliacao ||
          item.codigoStatusConciliacao.toString() ===
            filter.codigoStatusConciliacao.toString()) &&
        (!item.operadora ||
          !filter.operadora ||
          item.operadora.toString() === filter.operadora) &&
        (!item.banco ||
          !filter.banco ||
          Number(item.banco) === Number(filter.banco)) &&
        (!item.agencia ||
          !filter.agencia ||
          item.agencia.toString() === filter.agencia) &&
        (!item.conta ||
          !filter.conta ||
          item.conta.toString() === filter.conta) &&
        (!filter.valorLiquido ||
          !item.valorLiquido ||
          item.valorLiquido.toString().search(filter.valorLiquido) !== -1 ||
          !item.valorLiquidoNumerico ||
          item.valorLiquidoNumerico
            .toString()
            .search(filter.valorLiquido.replace(',', '.')) !== -1)
      );
    });
  }
}
