import { NaturezaPixEnum } from './natureza-pix-enum';

export class PixInputDTO {
  public dataInicial: Date;
  public dataFinal: Date;
  public naturezaPix: NaturezaPixEnum;

  public constructor(init?: Partial<PixInputDTO>) {
    Object.assign(this, init);
  }
}
