import {
  AlignEnum,
  ColumnDetail,
  FormatEnum,
  TypeEnum,
} from './../../../../../shared/common/components/data-table/interfaces/column-detail';
import { Component, OnInit } from '@angular/core';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { PagesService } from '../../../../../shared/services/pages.service';
import { ToastrService } from 'ngx-toastr';
import { IExtratoPix } from './interfaces/extrato-pix';
import { IPix } from './interfaces/pix';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { IFiltroDatas } from '../../../../../shared/common/interfaces/filtro-datas';
import { TimestampBehaviourService } from '../../../../../shared/services/timestamp-behaviour.service';
import { TemplateService } from '../../../../template/template.service';
import { DataInputComRegra } from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom-enum/data-input';

interface IFiltroPix {
  valor: string;
  codigoBancoCOMPE: string;
}

interface IResumoPaginacao {
  de: number;
  ate: number;
  total: number;
}

@Component({
  selector: 'app-pix',
  templateUrl: './pix.component.html',
  styleUrls: ['./pix.component.css'],
})
export class PixComponent implements OnInit {
  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  dataSelecionada: DataInputComRegra;
  @BlockUI('bui-bloco-pix') buiBlocoPix: NgBlockUI;

  extratoPix: IExtratoPix;

  filtroPix: IFiltroPix;
  resumoPaginacao: IResumoPaginacao;
  currentPage: number;
  pageSize: number;
  mostrarDetalhamento: boolean;
  pixSelecionado: IPix;

  defaultDataSort = 'data';

  columnsDetails: ColumnDetail[] = [
    {
      dataKey: 'data',
      header: 'DATA EMISSÃO',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'pagador',
      header: 'PAGADOR',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'descricao',
      header: 'DESCRIÇÃO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'valor',
      header: 'VALOR',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.CURRENCYBR,
    },
  ];

  constructor(
    private pagesService: PagesService,
    private mensagemService: ToastrService,
    private timestampService: TimestampBehaviourService,
    private templateService: TemplateService
  ) {
    this.currentPage = 1;
    this.pageSize = 10;
    this.estabelecimento = null;
    this.extratoPix = null;
    this.extratoPix = {
      listaPix: [],
      filtroInstituicoesFinanceiras: [],
      quantidadePix: 0,
      valorTotalPix: 0,
    };
  }

  ngOnInit() {
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this._setFiltroDatas(data));
    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));
    this.timestampService.updateRegraCalendario();
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);

    this._listarPix();
  }

  private _setFiltroDatas(data: DataInputComRegra): void {
    this.dataSelecionada = data;
    this.filtroDatas = this.timestampService.translateDateForInput(data);
  }

  private _listarPix(): void {
    const dataInicial = moment(this.filtroDatas.dataInicial).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const dataFinal = moment(this.filtroDatas.dataFinal).set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    const params = new HttpParams()
      .set('dataInicial', dataInicial?.format())
      .set('dataFinal', dataFinal?.format());

    this.pagesService.getExtratoPix(params, this.buiBlocoPix).subscribe(
      (resposta) => {
        this.extratoPix = resposta.dados;
      },
      (error) => {
        this.mensagemService.error(error.erros[0].message);
      }
    );
  }

  fecharDetalhamento(event) {
    this.mostrarDetalhamento = !this.mostrarDetalhamento;
  }

  detalhar(pixSelecionado) {
    this.mostrarDetalhamento = true;
    this.pixSelecionado = pixSelecionado;
  }
}
