import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { DatepickerModuleEnum } from 'merge-common-ng';
import { NavbarDespesasModule } from '../navbar-despesas/navbar-despesas.module';
import { BlockUIModule } from 'ng-block-ui';
import { BoletoRoutingModule } from './boleto-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoletoComponent } from './boleto.component';
import { DataTableModule } from 'src/app/merge/shared/common/components/data-table/data-table.module';

@NgModule({
  declarations: [BoletoComponent],
  exports: [BoletoComponent],
  imports: [
    CommonModule,
    BoletoRoutingModule,
    NavbarDespesasModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    BlockUIModule.forRoot(),
    DatepickerModuleEnum,
    DataTableModule,
  ],
})
export class BoletoModule {}
