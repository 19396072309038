import { ApiService, Response } from 'merge-common-ng';
import { Injectable } from '@angular/core';
import { IParceria } from './interfaces/parceria';
import { Observable } from 'rxjs';
import { NgBlockUI } from 'ng-block-ui';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceService {
  constructor(public apiService: ApiService) { }
  public static readonly ENDPOINT_PUBLICACAO_PARCERIAS =
    'api/publicacao-ofertas';

  listarParceriasVigentes(
    ngBlockUI?: NgBlockUI
  ): Observable<Response<IParceria[]>> {
    return this.apiService.get(
      `${MarketplaceService.ENDPOINT_PUBLICACAO_PARCERIAS}/vigentes`,
      null,
      ngBlockUI
    );
  }
}
