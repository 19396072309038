import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ListaRegistro } from '../../../models/ListaRegistro';
import { ConciliacaoVendaDetalheComponent } from '../../../../../template/components/modal-conciliacao-venda-detalhe/conciliacao-venda-detalhe.component';
import { ITituloCobranca } from '../../../../recebimentos/components/titulos-cobranca/interfaces/titulo-cobranca';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-conciliacao-venda-detalhe-boletos',
  templateUrl: './conciliacao-venda-detalhe-boletos.component.html',
  styleUrls: ['./conciliacao-venda-detalhe-boletos.component.css'],
})
export class ConciliacaoVendaDetalheBoletosComponent implements OnChanges {
  @Input() listaRegistroDetalhe: ITituloCobranca;

  @Output() fecharDetalhamento = new EventEmitter<any>();

  listaRegistroDetalheFormatada: ListaRegistro = new ListaRegistro();

  // Modal
  private modalRef: NgbModalRef;
  @ViewChild('modal')
  private modalContent: TemplateRef<ConciliacaoVendaDetalheComponent>;

  constructor(
    private modalService: NgbModal,
    private datePipe: DatePipe
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.listaRegistroDetalhe.currentValue) {
      this.open();
    }
  }

  formatarData() {
    this.listaRegistroDetalheFormatada.nomeSacadoCobranca = this.listaRegistroDetalhe.pagador;
    this.listaRegistroDetalheFormatada.textoEstadoTitulo = this.listaRegistroDetalhe.situacao;
    this.listaRegistroDetalheFormatada.textoEstadoTitulo = this.listaRegistroDetalhe.situacao;
    this.listaRegistroDetalheFormatada.dataRegistroTituloCobrancaFormatada =
    this.datePipe.transform(this.listaRegistroDetalhe.dataEmissao, 'dd/MM/yyyy HH:mm');
    this.listaRegistroDetalheFormatada.dataVencimentoTituloCobrancaFormatada =
    this.datePipe.transform(this.listaRegistroDetalhe.dataVencimento, 'dd/MM/yyyy HH:mm');
    this.listaRegistroDetalheFormatada.valorOriginalTituloNumerico = this.listaRegistroDetalhe.valorLiquido;
    this.listaRegistroDetalheFormatada.valorAtualTituloCobranca =
    this.listaRegistroDetalhe.valorLiquido - this.listaRegistroDetalhe.valorPago;
    this.listaRegistroDetalheFormatada.numeroCarteiraCobranca = this.listaRegistroDetalhe.carteiraCobranca;
    this.listaRegistroDetalheFormatada.numeroTituloCobrancaBancoBrasil =
    this.listaRegistroDetalhe.numeroTituloCliente;
  }

  open(): Promise<boolean> {
    this.formatarData();
    return new Promise<boolean>((resolve) => {
      this.modalRef = this.modalService.open(this.modalContent, {
        size: 'xl',
        backdrop: 'static',
        centered: true,
      });
      this.modalRef.result.then(resolve, resolve);
    });
  }

  async close(): Promise<void> {
    this.modalRef.close();
    this.fecharDetalhamento.emit();
  }
}
