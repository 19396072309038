import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar-recebimentos',
  templateUrl: './navbar-recebimentos.component.html',
  styleUrls: ['./navbar-recebimentos.component.css'],
})
export class NavbarRecebimentosComponent {
  constructor() {}
}
