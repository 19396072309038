import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'banco',
})
export class BancoPipe implements PipeTransform {
  transform(value: number | string): string {
    try {
      if (value === undefined) {
        return '';
      }

      value = String(value);
      return value.padStart(3, '0');
    } catch (error) {
      console.error(error);
      return String(value);
    }
  }
}
