import {
  ColumnDetail,
  TypeEnum,
  AlignEnum,
  FormatEnum,
} from './../../../../../shared/common/components/data-table/interfaces/column-detail';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Component, OnInit } from '@angular/core';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { PagesService } from '../../../../../shared/services/pages.service';
import { ToastrService } from 'ngx-toastr';
import { IExtratoTitulosCobranca } from './interfaces/extrato-titulos-cobranca';
import { ITituloCobranca } from './interfaces/titulo-cobranca';
import { IFiltroDatas } from '../../../../../shared/common/interfaces/filtro-datas';
import { TimestampBehaviourService } from '../../../../../shared/services/timestamp-behaviour.service';
import { TemplateService } from '../../../../template/template.service';
import { DataInputComRegra } from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom-enum/data-input';

@Component({
  selector: 'app-titulos-cobranca',
  templateUrl: './titulos-cobranca.component.html',
  styleUrls: ['./titulos-cobranca.component.css'],
})
export class TitulosCobrancaComponent implements OnInit {
  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  dataSelecionada: DataInputComRegra;
  @BlockUI('bui-titulos-cobranca') buiTitulosCobranca: NgBlockUI;

  extratoTitulosCobranca: IExtratoTitulosCobranca;
  titulosCobrancaFiltrados: ITituloCobranca[];
  situacoes: string[];

  defaultDataSort = 'pagador';
  columnsDetails: ColumnDetail[] = [
    {
      dataKey: 'dataEmissao',
      header: 'DATA EMISSÃO',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'numeroTituloCliente',
      header: 'TÍTULO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'pagador',
      header: 'PAGADOR',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'dataVencimento',
      header: 'DATA VENCIMENTO',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'dataMovimento',
      header: 'DATA PAGAMENTO',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'situacao',
      header: 'SITUAÇÃO *',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'valorLiquido',
      header: 'VALOR',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.CURRENCYBR,
    }
  ];

  constructor(
    private pagesService: PagesService,
    private mensagemService: ToastrService,
    private timestampService: TimestampBehaviourService,
    private templateService: TemplateService
  ) {
    this.estabelecimento = null;
    this.extratoTitulosCobranca = null;
    this.titulosCobrancaFiltrados = [];
    this.extratoTitulosCobranca = {
      quantidadeTitulosLiquidados: 0,
      quantidadeTitulosVencidos: 0,
      quantidadeTitulosBaixados: 0,
      valorTotalTitulosLiquidados: 0,
      valorTotalTitulosVencidos: 0,
      valorTotalTitulosBaixados: 0,
      listaRegistros: []
    };
  }

  ngOnInit() {
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this._setFiltroDatas(data));
    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));
    this.timestampService.updateRegraCalendario();
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);

    this.listarTitulosCobranca();
  }

  private _setFiltroDatas(data: DataInputComRegra): void {
    this.dataSelecionada = data;
    this.filtroDatas = this.timestampService.translateDateForInput(data);
  }

  listarTitulosCobranca(): void {
    const dataInicial = moment(this.filtroDatas.dataInicial).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const dataFinal = moment(this.filtroDatas.dataFinal).set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    const params = new HttpParams()
      .set('dataInicial', dataInicial?.format())
      .set('dataFinal', dataFinal?.format());

    this.pagesService
      .getListarTitulosCobranca(params, this.buiTitulosCobranca)
      .subscribe(
        (resposta) => {
          this.extratoTitulosCobranca = resposta.dados;
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }
}
