import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Empresa } from 'merge-common-ng';
import { ToastrService } from 'ngx-toastr';
import { DataInputComRegra } from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom-enum/data-input';
import { PagesService } from 'src/app/merge/shared/services/pages.service';
import { ContaCorrenteDto } from './interfaces/conta-corrente-dto';
import { DadosRespostaExtratoLancamentoContaCorrenteDTO } from './interfaces/lancamento-extrato-dto';
import { IFiltroDatas } from '../../../../shared/common/interfaces/filtro-datas';
import { TemplateService } from '../../../template/template.service';
import { TimestampBehaviourService } from '../../../../shared/services/timestamp-behaviour.service';
import moment from 'moment';

@Component({
  selector: 'app-extrato-conta-corrente',
  templateUrl: './extrato-conta-corrente.component.html',
  styleUrls: ['./extrato-conta-corrente.component.css'],
})
export class ExtratoContaCorrenteComponent implements OnInit {
  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  date: DataInputComRegra;

  contasCorrentes: ContaCorrenteDto[];
  contaCorrenteSelecionada: ContaCorrenteDto;

  dadosRespostaExtratos: DadosRespostaExtratoLancamentoContaCorrenteDTO;

  dataTotalizadores: string;

  @BlockUI('bui-lista-conta-corrente') buiListaContaCorrente: NgBlockUI;
  @BlockUI('bui-extrato-conta-corrente') buiExtratoContaCorrente: NgBlockUI;

  constructor(
    private pagesService: PagesService,
    private mensagemService: ToastrService,
    private templateService: TemplateService,
    private timestampService: TimestampBehaviourService
  ) {
    this._limparExtrato();
    this.contasCorrentes = [];
    this.estabelecimento = null;
  }

  ngOnInit(): void {
    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this._setFiltroDatas(data));
    if (this.estabelecimento) {
      this.listarContasCorrentesBB();
      this.listarContasCorrentesOPB();
      this.listarExtrato();
    }
    this.timestampService.updateRegraCalendario();
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);
    this.tratarDatasTotalizadores();
    this.listarExtrato();
  }

  private _setFiltroDatas(data: DataInputComRegra): void {
    this.filtroDatas = this.timestampService.translateDateForInput(data);
    this.date = data;
  }
  private _limparExtrato(): void {
    this.dadosRespostaExtratos = {
      recebimentos: 0,
      despesas: 0,
      saldo: 0,
      extratos: [],
    };
  }

  listarContasCorrentesBB(): void {
    this.pagesService
      .getListarContasCorrentesBB(this.buiListaContaCorrente)
      .subscribe(
        (resposta) => {
          this.contasCorrentes = this.contasCorrentes.concat([
            ...resposta.dados,
          ]);
          this.tratarRetornoContas();
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  listarContasCorrentesOPB(): void {
    this.pagesService
      .getListarContasCorrentesOPB(this.buiListaContaCorrente)
      .subscribe(
        (resposta) => {
          this.contasCorrentes = this.contasCorrentes.concat([
            ...resposta.dados,
          ]);
          this.tratarRetornoContas();
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  tratarRetornoContas(): void {
    if (this.contaCorrenteSelecionada) {
      return;
    } else {
      if (this.contasCorrentes) {
        this.contaCorrenteSelecionada = this.contasCorrentes[0];
      }

      if (this.contaCorrenteSelecionada && this.filtroDatas) {
        this.listarExtrato();
      }
    }
  }

  tratarDatasTotalizadores(): void {
    this.dataTotalizadores =
      this.filtroDatas.dataInicial.getDate() +
      ' ' +
      this.filtroDatas.dataInicial.toLocaleString('pt', { month: 'short' }) +
      ' ' +
      this.filtroDatas.dataInicial.getFullYear() +
      ' a ' +
      this.filtroDatas.dataFinal.getDate() +
      ' ' +
      this.filtroDatas.dataFinal.toLocaleString('pt', { month: 'short' }) +
      ' ' +
      this.filtroDatas.dataFinal.getFullYear();
  }

  listarExtrato(): void {
    this._limparExtrato();

    const dataInicial = moment(this.filtroDatas.dataInicial)
      .set({ hour: 0, minute: 0, second: 0 })
      .set({ hour: 0, minute: 0, second: 0 });

    const dataFinal = moment(this.filtroDatas.dataFinal).set({
      hour: 23,
      minute: 59,
      second: 59,
    });

    let params = new HttpParams()
      .set('dataInicial', dataInicial?.format())
      .set('dataFinal', dataFinal?.format());

    if (!!this.contaCorrenteSelecionada?.codigoConta) {
      params = params.set(
        'numeroMarca',
        String(this.contaCorrenteSelecionada.numeroMarca)
      )
      .set(
        'codigoConta',
        String(this.contaCorrenteSelecionada.codigoConta)
      )
      .set(
        'sequencial',
        String(this.contaCorrenteSelecionada.sequencialAutorizacao)
      );

      this.listarExtratoOPB(params);
    } else if (!!this.contaCorrenteSelecionada?.idInformacaoBancaria) {
      params = params.set(
        'idInformacaoBancaria',
        String(this.contaCorrenteSelecionada.idInformacaoBancaria)
      );

      this.listarExtratoBB(params);
    }
  }

  listarExtratoBB(params: HttpParams): void {
    this.pagesService
      .getListarExtratoPeriodoBB(params, this.buiExtratoContaCorrente)
      .subscribe(
        (resposta) => {
          this.dadosRespostaExtratos = resposta.dados;
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  listarExtratoOPB(params: HttpParams): void {
    this.pagesService
      .getListarExtratoPeriodoOPB(params, this.buiExtratoContaCorrente)
      .subscribe(
        (resposta) => {
          this.dadosRespostaExtratos = resposta.dados;
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }
}
