import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificacoesComponent } from './notificacoes.component';
import { BlockUIModule } from 'ng-block-ui';

@NgModule({
  declarations: [NotificacoesComponent],
  imports: [CommonModule, BlockUIModule],
  exports: [NotificacoesComponent],
})
export class NotificacoesModule {}
