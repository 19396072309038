import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { VendasAReceberComponent } from './vendas-a-receber.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { ComponentsCommonModule } from '../../../shared/common/components-commom.module';
import { VendasAReceberRoutingModule } from './vendas-a-receber-routing.module';
import { GraficoVolumeVendasModule } from '../../cartoes/minhas-vendas/components/grafico-volume-vendas/grafico-volume-vendas.module';
import { ConciliacaoVendasBoletosModule } from './components/conciliacao-vendas-boletos/conciliacao-vendas-boletos.module';
import { ConciliacaoVendasAtualizarModule } from '../../cartoes/minhas-vendas/components/conciliacao-vendas-atualizar/conciliacao-vendas-atualizar.module';
import { ConciliacaoVendaDetalheBoletosModule } from './components/conciliacao-vendas-boletos/modal-conciliacao-venda-detalhe-boletos/conciliacao-venda-detalhe-boletos.module';
import { ConciliacaoVendaDetalheModule } from '../../template/components/modal-conciliacao-venda-detalhe/conciliacao-venda-detalhe.module';
import {
  DatepickerModuleEnum,
  NgbdDatepickerRangeComponent,
} from 'merge-common-ng';
import { FilterExtratoVendas } from './filters/extrato-vendas.pipe';
import { FilterExtratoBoleto } from './filters/filtro-extrato-boleto.pipe';
import { HttpClientModule } from '@angular/common/http';
import { NavbarVendasAReceberModule } from './components/navbar-vendas-a-receber/navbar-vendas-a-receber.module';
import { InsightModule } from 'src/app/merge/shared/common/components/insights-notificacao/insight-notificacao.module';

@NgModule({
  declarations: [
    VendasAReceberComponent,
    FilterExtratoVendas,
    FilterExtratoBoleto,
  ],
  imports: [
    CommonModule,
    VendasAReceberRoutingModule,
    RouterModule,
    FormsModule,
    NgxPaginationModule,
    HighchartsChartModule,
    ComponentsCommonModule,
    DatepickerModuleEnum,
    HttpClientModule,
    GraficoVolumeVendasModule,
    ConciliacaoVendasBoletosModule,
    ConciliacaoVendasAtualizarModule,
    ConciliacaoVendaDetalheBoletosModule,
    ConciliacaoVendaDetalheModule,
    NavbarVendasAReceberModule,
    InsightModule
  ],
  exports: [VendasAReceberComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    NgbdDatepickerRangeComponent,
  ],
})
export class VendasAReceberModule {}
