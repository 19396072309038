import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import {
  DatepickerModule,
  NgbdDatepickerRangeComponent,
} from 'merge-common-ng';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BlockUIModule } from 'ng-block-ui';
import { ComponentsCommonModule } from '../../../../shared/common/components-commom.module';
import { ExtratoCartaoCreditoComponent } from './extrato-cartao-credito.component';
import { ExtratoCartaoCreditoRoutingModule } from './extrato-cartao-credito-routing.module';
import { ExtratoFaturaFechadasComponent } from './components/extrato-fatura-fechadas/extrato-fatura-fechadas.component';
import { ExtratoFaturaAbertaComponent } from './components/extrato-fatura-aberta/extrato-fatura-aberta.component';
import { ExtratoFaturaTabelaComponent } from './components/extrato-fatura-tabela/extrato-fatura-tabela.component';
import { OfertaCartaoUsuarioModule } from './components/oferta-cartao-usuario/oferta-cartao-usuario.module';

@NgModule({
  declarations: [
    ExtratoCartaoCreditoComponent,
    ExtratoFaturaFechadasComponent,
    ExtratoFaturaAbertaComponent,
    ExtratoFaturaTabelaComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ComponentsCommonModule,
    DatepickerModule,
    ExtratoCartaoCreditoRoutingModule,
    BlockUIModule.forRoot(),
    NgbNavModule,
    ScrollingModule,
    InfiniteScrollModule,
    OfertaCartaoUsuarioModule,
  ],
  providers: [NgbdDatepickerRangeComponent],
  exports: [ExtratoCartaoCreditoComponent],
})
export class ExtratoCartaoCreditoModule {}
