import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-banner-conciliadora',
  templateUrl: './banner-conciliadora.component.html',
  styleUrls: ['./banner-conciliadora.component.css']
})
export class BannerConciliadoraComponent implements OnInit {

  @Input() componentName: string;
  public readonly TRACK = {
    category: 'Interacoes',
    action: 'click',
    name: {
      button: `%componentName%||BannerConciliadora||Botao`,
      image: `%componentName%||BannerConciliadora||Imagem`
    },
    value: 1
  };

  constructor() { }

  ngOnInit(): void {
    this.TRACK.name.button = this.TRACK.name.button.replace('%componentName%', this.componentName);
    this.TRACK.name.image = this.TRACK.name.image.replace('%componentName%', this.componentName);
  }

}
