import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar-vendas-a-receber',
  templateUrl: './navbar-vendas-a-receber.component.html',
  styleUrls: ['./navbar-vendas-a-receber.component.css'],
})
export class NavbarVendasAReceberComponent {
  constructor() {}
}
