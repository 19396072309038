import {Pipe, PipeTransform} from '@angular/core';
import {IFiltroExtratoVendas} from '../interfaces/filtro-extrato-vendas';

@Pipe({
  name: 'filterExtratoVendas',
  pure: false,
})
export class FilterExtratoVendas implements PipeTransform {
  transform(items: any[], filter: IFiltroExtratoVendas): any {
    if (!filter || !this.hasFilterValue(filter)) {
      return items;
    }

    return items.filter((item) => {
      return (
        this.checkCodigoStatusConcilicao(item, filter) &&
        this.checkOperadora(item, filter) &&
        this.checkTipoModalidade(item, filter) &&
        this.checkValorBruto(item, filter)
      );
    });
  }

  hasFilterValue(filter): boolean {
    for (const filtro of Object.values(filter)) {
      if (Boolean(filtro)) {
        return true;
      }
    }

    return false;
  }

  checkCodigoStatusConcilicao(item: any, filter: IFiltroExtratoVendas): boolean {
    if (!filter.codigoStatusConciliacao) {
      return true;
    }

    return item.codigoStatusConciliacao &&
      item.codigoStatusConciliacao.toString() === filter.codigoStatusConciliacao.toString();
  }

  checkOperadora(item: any, filter: IFiltroExtratoVendas): boolean {
    if (!filter.operadora) {
      return true;
    }

    return item.operadora &&
      item.operadora.toString() === filter.operadora;
  }

  checkTipoModalidade(item: any, filter: IFiltroExtratoVendas): boolean {
    if (!filter.tipoModalidade) {
      return true;
    }

    return item.descricaoTipoModalidade &&
      item.descricaoTipoModalidade.toString() === filter.tipoModalidade.toString();
  }

  checkValorBruto(item: any, filter: IFiltroExtratoVendas): boolean {
    if (!filter.valorBruto) {
      return true;
    }

    return filter.valorBruto && item.valorBruto &&
      item.valorBruto.toString().search(filter.valorBruto.replace(',', '.')) !== -1;
  }
}
