import { EndpointsMerge } from '../common/endpointsmerge';
import { ApiService, Empresa } from 'merge-common-ng';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { FormatosColunasRelatorios } from '../../pages/relatorios/botao-relatorio/formatos-colunas-relatorios';
import { HttpParams } from '@angular/common/http';
import { ConsultaBoletoDto } from '../../pages/meu-negocio/despesas/models/consulta-boleto-dto';
import { ConsultaLancamentosCartaoDto } from '../../pages/meu-negocio/despesas/models/consulta-lancamentos-cartao-dto';
import { ConsultaPorPeriodoDTO } from '../../pages/meu-negocio/despesas/models/consulta-por-periodo-dto';
import { ConsultaTarifaDto } from '../../pages/meu-negocio/despesas/models/consulta-tarifa-dto';
import { DadosBoletoDto } from '../../pages/meu-negocio/despesas/models/dados-boleto-dto';
import { NaturezaPixEnum } from '../../pages/meu-negocio/despesas/models/natureza-pix-enum';
import { PixInputDTO } from '../../pages/meu-negocio/despesas/models/pix-input-dto';
import { ConsultaOrdensBancariasDto } from '../../pages/meu-negocio/recebimentos/components/ordens-bancarias/interfaces/requisicao-ordens-bancarias';
import { PagesService } from './pages.service';
import { ColumnDetail } from '../common/components/data-table/interfaces/column-detail';
import { ToastrService } from 'ngx-toastr';
import { IFiltroDatas } from '../common/interfaces/filtro-datas';
import { GeradorDeRelatorios } from './gerador-relatorios.service';
import { TemplateService } from '../../pages/template/template.service';

@Injectable({
  providedIn: 'root',
})
export class RelatoriosService {
  private estabelecimento: Empresa;

  geradorRelatorios: GeradorDeRelatorios = new GeradorDeRelatorios();
  constructor(
    public apiService: ApiService,
    private pagesService: PagesService,
    private mensagemService: ToastrService,
    private templateService: TemplateService,
    ) {
    }

  gerarRelatorioRecebimentosCartoes(params) {
    this.apiService.postDownloadFile(
      EndpointsMerge.RELATORIO_RECEBIMENTOS_CARTOES,
      params
    );
  }

  gerarRelatorioRecebimentosAntecipacoes(params) {
    this.apiService.postDownloadFile(
      EndpointsMerge.RELATORIO_RECEBIMENTOS_ANTECIPACOES,
      params
    );
  }

  gerarRelatorioRecebimentosPix(params) {
    this.apiService.postDownloadFile(
      EndpointsMerge.RELATORIO_RECEBIMENTOS_PIX,
      params
    );
  }

  gerarRelatorioBoletosVendas(params) {
    this.apiService.postDownloadFile(
      EndpointsMerge.RELATORIO_BOLETOS_VENDAS,
      params
    );
  }

  gerarRelatorioRecebimentosTedDoc(params) {
    this.apiService.postDownloadFile(
      EndpointsMerge.RELATORIO_RECEBIMENTOS_TEDDOC,
      params
    );
  }

  geracaoRelatorios(columnsDetails: ColumnDetail[], dados: any[], filtroDatas: IFiltroDatas, formato: string, nome: string) {
    this.templateService
            .getEstabelecimentoSubject()
            .subscribe((est) => (this.estabelecimento = est));

    this.geradorRelatorios.gerarRelatorio(
      formato,
      dados,
      columnsDetails,
      filtroDatas,
      this.estabelecimento.nomeFantasia,
      this.estabelecimento.cnpj,
      nome);
  }

  public gerarRelatorioTarifas(filtroDatas: IFiltroDatas, formato: string): void {
    const input: ConsultaTarifaDto = {
      dataInicioPesquisa: filtroDatas.dataInicial.toLocaleString('pt'),
      dataFimPesquisa: filtroDatas.dataFinal.toLocaleString('pt'),
    };
    this.pagesService.listarTarifas(input).subscribe(
      (resposta) => {
        this.geracaoRelatorios(
          FormatosColunasRelatorios.RELATORIO_TARIFAS,
          resposta.dados.listaCobranca,
          filtroDatas,
          formato,
          'Despesas Tarifas');
      },
      (error) => {
        this.mensagemService.error(error.erros[0].message);
      }
    );
  }

  public gerarRelatorioDespesasBoleto(filtroDatas: IFiltroDatas, formato: string): void {
    const input: ConsultaBoletoDto = new ConsultaBoletoDto();
    input.dataVencimentoTituloInicio = filtroDatas.dataInicial;
    input.dataVencimentoTituloFim = filtroDatas.dataFinal;
    let dadosBoleto: DadosBoletoDto;
    this.pagesService
      .getListaBoletos(input)
      .subscribe(
        (resposta) => {
          dadosBoleto = resposta.dados;
          this.geracaoRelatorios(
            FormatosColunasRelatorios.RELATORIO_DESPESAS_BOLETO,
            dadosBoleto.boletos,
            filtroDatas,
            formato,
            'Despesas Boleto');
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  public gerarRelatorioDespesasPix(filtroDatas: IFiltroDatas, formato: string): void {
    const dto = new PixInputDTO();
    dto.dataInicial = filtroDatas.dataInicial;
    dto.dataFinal = filtroDatas.dataFinal;
    dto.naturezaPix = NaturezaPixEnum.DEBITO;
    this.pagesService
      .getListaPixPorTipo(dto)
      .subscribe(
        (resposta) => {
          this.geracaoRelatorios(
            FormatosColunasRelatorios.RELATORIO_DESPESAS_PIX,
            resposta.dados.listaLancamento,
            filtroDatas,
            formato,
            'Despesas Pix');
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  public gerarRelatorioDespesasOperacoesCredito(filtroDatas: IFiltroDatas, formato: string): void {
    const consultaPorPeriodoDTO = new ConsultaPorPeriodoDTO();
    consultaPorPeriodoDTO.dataInicial = filtroDatas.dataInicial;
    consultaPorPeriodoDTO.dataFinal = filtroDatas.dataFinal;
    this.pagesService
      .getListaOperacoesCreditoNoPeriodo(
        consultaPorPeriodoDTO
      )
      .subscribe(
        (resposta) => {
          this.geracaoRelatorios(FormatosColunasRelatorios.RELATORIO_DESPESAS_OPERACAO_CREDITO,
            resposta.dados.operacoesCredito,
            filtroDatas,
            formato,
            'Despesas Crédito');
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  gerarHTTPparam(filtroDatas: IFiltroDatas): HttpParams{
    const dataInicial = moment(filtroDatas.dataInicial).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const dataFinal = moment(filtroDatas.dataFinal).set({
      hour: 23,
      minute: 59,
      second: 59,
    });
    return new HttpParams()
    .set('dataInicial', String(dataInicial.format()))
    .set('dataFinal', String(dataFinal.format()));
  }

  public gerarRelatorioDespesasTed(filtroDatas: IFiltroDatas, formato: string): void {
    const parametros = this.gerarHTTPparam(filtroDatas);
    this.pagesService
      .listarDespesasTedDoc(parametros)
      .subscribe(
        (resposta) => {
          this.geracaoRelatorios(FormatosColunasRelatorios.RELATORIO_DESPESAS_TED,
            resposta.dados.listaLancamento,
            filtroDatas,
            formato,
            'Despesas Ted');
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  public gerarRelatorioDespesasCartao(filtroDatas: IFiltroDatas, formato: string): void {
    const dto = new ConsultaLancamentosCartaoDto();
    dto.dataInicial = filtroDatas.dataInicial;
    dto.dataFinal = filtroDatas.dataFinal;
    const dados = [];
    this.pagesService
      .getListaDespesasCartaoCreditoITE(
        dto
      )
      .subscribe(
        (respostaIte) => {
          dados.push(...respostaIte.dados);
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
    this.pagesService
      .getListaDespesasCartaoCreditoOPF(
        dto
      )
      .subscribe(
        (respostaOpf) => {
          dados.push(...respostaOpf.dados);
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
    this.geracaoRelatorios(FormatosColunasRelatorios.RELATORIO_DESPESAS_CARTAO,
      dados,
      filtroDatas,
      formato,
      'Despesas Cartao');
  }

  public gerarRelatorioRecebimentoTituloCobranca(filtroDatas: IFiltroDatas, formato: string): void {
    const params = this.gerarHTTPparam(filtroDatas);

    this.pagesService
      .getListarTitulosCobranca(params)
      .subscribe(
        (resposta) => {
          this.geracaoRelatorios(FormatosColunasRelatorios.RELATORIO_RECEBIMENTOS_TITULO_COBRANCA,
            resposta.dados.listaTitulosCobranca,
            filtroDatas,
            formato,
            'Recebimetos Titulos');
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }


  public gerarRelatorioRecebimentoPix(filtroDatas: IFiltroDatas, formato: string){

    const params = this.gerarHTTPparam(filtroDatas);

    this.pagesService.getExtratoPix(params).subscribe(
      (resposta) => {
        this.geracaoRelatorios(FormatosColunasRelatorios.RELATORIO_RECEBIMENTOS_PIX,
          resposta.dados.listaPix,
          filtroDatas,
          formato,
          'Recebimentos Pix');
      },
      (error) => {
        this.mensagemService.error(error.erros[0].message);
      }
    );
  }

  public gerarRelatorioRecebimentoOrdensBancarias(filtroDatas: IFiltroDatas, formato: string){
    const body = new ConsultaOrdensBancariasDto();
    body.dataInicioPesquisa = filtroDatas.dataInicial;
    body.dataFimPesquisa = filtroDatas.dataFinal;
    this.pagesService.getExtratoOrdens(body).subscribe(
      (resposta) => {
        this.geracaoRelatorios(FormatosColunasRelatorios.RELATORIO_RECEBIMENTOS_ORDENS_BANCARIAS,
          resposta.dados.listaOrdemBancario,
          filtroDatas,
          formato,
          'Ordens Bancárias');
      },
      (error) => {
        this.mensagemService.error(error.erros[0].message);
      }
    );
  }

  public gerarRelatorioRecebimentoTed(filtroDatas: IFiltroDatas, formato: string){
    const params = this.gerarHTTPparam(filtroDatas);
    this.pagesService
      .listarTransferenciasRecebidas(params)
      .subscribe(
        (resposta) => {
          this.geracaoRelatorios(FormatosColunasRelatorios.RELATORIO_RECEBIMENTOS_TED,
            resposta.dados,
            filtroDatas,
            formato,
            'Recebimentos Ted');
        },
        (error) => {
          this.mensagemService.error(error.erros[0].message);
        }
      );
  }

  public gerarRelatorioConciliadoraPrevisoesRecebimentos(filtroDatas: IFiltroDatas, formato: string){
    const params =  {
      dataInicial: moment(filtroDatas.dataInicial).format('YYYY-MM-DD'),
      dataFinal: moment(filtroDatas.dataFinal).format('YYYY-MM-DD'),
    };
    this.pagesService
            .getListaPrevisaoRecebimentos(params)
            .subscribe(
                (data: any) => {
                    this.geracaoRelatorios(FormatosColunasRelatorios.RELATORIO_CONCILIADORA_PREVISOES_RECEBIMENTO,
                      data.dados,
                      filtroDatas,
                      formato,
                      'Conciliadora previsoes');
                },
                (error) => {
                    this.mensagemService.error(error.erros[0].message);
                }
            );
  }

  public gerarRelatorioConciliadoraRecebimentos(filtroDatas: IFiltroDatas, formato: string){
    const params =  {
      dataInicial: moment(filtroDatas.dataInicial).format('YYYY-MM-DD'),
      dataFinal: moment(filtroDatas.dataFinal).format('YYYY-MM-DD'),
    };
    this.pagesService
            .getListaRecebimentosCartao(params)
            .subscribe(
                (data: any) => {
                    this.geracaoRelatorios(FormatosColunasRelatorios.RELATORIO_CONCILIADORA_VENDAS_E_RECEBIMENTOS,
                      data.dados,
                      filtroDatas,
                      formato,
                      'Conciliadora Recebimentos');
                },
                (error) => {
                    this.mensagemService.error(error.erros[0].message);
                }
            );
  }

  public gerarRelatorioConciliadoraVendas(filtroDatas: IFiltroDatas, formato: string){
    const params =  {
      dataInicial: moment(filtroDatas.dataInicial).format('YYYY-MM-DD'),
      dataFinal: moment(filtroDatas.dataFinal).format('YYYY-MM-DD'),
    };
    this.pagesService
            .getListaVendasRealizadasCartao(params)
            .subscribe(
                (data: any) => {
                    this.geracaoRelatorios(FormatosColunasRelatorios.RELATORIO_CONCILIADORA_VENDAS_E_RECEBIMENTOS,
                      data.dados,
                      filtroDatas,
                      formato,
                      'Conciliadora Vendas');
                },
                (error) => {
                    this.mensagemService.error(error.erros[0].message);
                }
            );
  }

}

