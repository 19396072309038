import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatepickerModule, NgbdDatepickerRangeComponent} from 'merge-common-ng';

import {RelatoriosRoutingModule} from './relatorios-routing.module';
import {RelatoriosComponent} from './relatorios.component';
import { BotaoRelatorioModule } from './botao-relatorio/botao-relatorio.module';

@NgModule({
  declarations: [RelatoriosComponent],
  imports: [CommonModule, RelatoriosRoutingModule, DatepickerModule, BotaoRelatorioModule],
  providers: [NgbdDatepickerRangeComponent],
  exports: [RelatoriosComponent],
})
export class RelatoriosModule {}
