import { BoletoDto } from './boleto-dto';

export class DadosBoletoDto {
  public quantidadeVencidos: number;

  public valorTotalVencidos: number;

  public quantidade: number;

  public valorTotal: number;

  public boletos: BoletoDto[];
}
