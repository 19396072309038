import { DataTableModule } from './../../../../../shared/common/components/data-table/data-table.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PixComponent } from './pix.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ComponentsCommonModule } from '../../../../../shared/common/components-commom.module';
import { FormsModule } from '@angular/forms';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { BlockUIModule } from 'ng-block-ui';
import { DetalhamentoPixModule } from '../detalhamento-pix/detalhamento-pix.module';
import { PixRoutingModule } from './pix-routing.module';
import { DatepickerModuleEnum } from 'merge-common-ng';
import { NavbarRecebimentosModule } from '../navbar-recebimentos/navbar-recebimentos.module';

@NgModule({
  declarations: [PixComponent],
  exports: [PixComponent],
  imports: [
    CommonModule,
    PixRoutingModule,
    NgxPaginationModule,
    ComponentsCommonModule,
    FormsModule,
    CurrencyMaskModule,
    BlockUIModule.forRoot(),
    DetalhamentoPixModule,
    NavbarRecebimentosModule,
    DatepickerModuleEnum,
    DataTableModule,
  ],
})
export class PixModule {}
