import { Component, OnInit, DoCheck, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { DataInput } from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom/data-input';
import { AuthService, Empresa, User, UtilsApp } from 'merge-common-ng';
import { CookieService } from 'ngx-cookie-service';
import { IFiltroDatas } from '../../shared/common/interfaces/filtro-datas';
import { StatusDoContratoModel } from '../../shared/common/models/status-do-contrato';
import { MatDialog } from '@angular/material/dialog';
import { PesquisaNpsComponent } from './components/pesquisa-nps/pesquisa-nps.component';
import { ServicoService } from '../../shared/services/servico.service';
import { TimestampBehaviourService } from '../../shared/services/timestamp-behaviour.service';
import { UpdateMenuService } from '../../shared/services/update-menu.service';
import { TemplateService } from './template.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css'],
})
export class TemplateComponent implements OnInit, DoCheck {
  visao: string;

  TRACK = {
    category: 'Interacoes',
    action: 'click',
    name: {
      menu: `Home||Menu||Conciliadora`,
      subMenus: {
        meuPlano: `Home||Menu||Conciliadora||MeuPlano`,
        minhasVendas: `Home||Menu||Conciliadora||MinhasVendas`,
      },
      recomendacoes: `Home||Recomendacoes||Conciliadora||Recomendacoes`
    },
    value: 1
  };

  date: DataInput;

  user: any;
  closedPesquisa = 0;
  estabelecimentos: Array<Empresa>;
  empresa: Empresa;
  filtroDatas: IFiltroDatas;
  mostrarItemMeuNegocio: boolean;
  mostrarItemFinanceiro: boolean;
  mostrarItemExtrato: boolean;
  private showDisclaimer: boolean;
  mostrarItemCartoes: boolean;
  showAvaliacao: boolean;
  showPlus = false;
  planoAtivo = true;
  temPlano = false;
  mostrarMsgNaoLidas: boolean;
  qntMsgNaoLidas: number;

  constructor(
    private authService: AuthService,
    private timestampService: TimestampBehaviourService,
    private templateService: TemplateService,
    private router: Router,
    private readonly matomoTracker: MatomoTracker,
    private cookieService: CookieService,
    private servicoService: ServicoService,
    private updateMenuService: UpdateMenuService,
    public dialog: MatDialog,
    private detectChanges: ChangeDetectorRef
  ) {
    this.empresa = new Empresa();
    this.user = {
      nome: '',
    };
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.setView();
      }
    });
  }
  ngOnInit() {
    this.templateService.updateNotificacaoMsgNaoLidas();
    this.qntMsgNaoLidas = this.templateService.getQntMsgNaoLidas();
    this.setView();
    this.initFiltroTemplate(UtilsApp.getCurrentUser());
    this.timestampService.getCalendarioSubject().subscribe((date) => {
      this.date = date;
      this.filtroDatas = this.timestampService.translateDateForInput(date);
    });
    this.updateMenuService
      .atualizarMenuObservable()
      .subscribe(() => this.atualizarChecagemPlano());
    this.atualizarChecagemPlano();
    const frase = this.cookieService.get('bb-painel-pj');
    const index = frase.indexOf(',');
    const mciPj = frase.substring(0, index);
    const chaveJ = frase.substring(index + 1);
    this.matomoTracker.setUserId(mciPj);
    this.matomoTracker.setCustomVariable(1, 'mci', mciPj, 'visit');
    this.matomoTracker.setCustomVariable(2, 'chavej', chaveJ, 'visit');
    this.matomoTracker.enableCrossDomainLinking();
    this.matomoTracker.trackAllContentImpressions();
    this.matomoTracker.trackPageView();
    this.templateService.setEstabelecimentoSubject(this.empresa);
    this.showDisclaimer = true;
  }

  ngDoCheck() {
    let newValue: boolean;
    this.templateService.getMsgNaoLidas().subscribe(val => newValue = val);
    if (this.mostrarMsgNaoLidas !== newValue) {
      this.mostrarMsgNaoLidas = newValue;
    }
    this.qntMsgNaoLidas = this.templateService.getQntMsgNaoLidas();
    this.detectChanges.detectChanges();
  }

  atualizarChecagemPlano(): void {
    this.servicoService.verificarContrato().subscribe((resposta) => {
      this.validatePlano(resposta.dados);
    });
  }

  openDialog() {
    this.dialog.open(PesquisaNpsComponent, {position: {top: '90px'}, width: '600px', autoFocus: false});
  }


  setView(): void {
    const activeUrl = this.router.url.split(';')[0];
    switch (activeUrl) {
      case '/pages/visao-geral':
        this.visao = 'Visão Geral';
        this.ajusteTrackingName('Home');
        this.showPlus = false;
        break;
      case '/pages/meu-negocio/vendas-a-receber/cartao':
        this.visao = 'Vendas a receber';
        this.ajusteTrackingName('VendasReceber');
        this.showPlus = false;
        break;
      case '/pages/meu-negocio/vendas-a-receber/boleto':
        this.visao = 'Vendas a receber';
        this.ajusteTrackingName('VendasReceber');
        this.showPlus = false;
        break;
      case '/pages/meu-negocio/recebimentos/cartao':
        this.visao = 'Recebimentos';
        this.ajusteTrackingName('Recebimentos');
        this.showPlus = false;
        break;
      case '/pages/meu-negocio/recebimentos/boleto':
        this.visao = 'Recebimentos';
        this.ajusteTrackingName('Recebimentos');
        this.showPlus = false;
        break;
      case '/pages/meu-negocio/recebimentos/pix':
        this.visao = 'Recebimentos';
        this.ajusteTrackingName('Recebimentos');
        this.showPlus = false;
        break;
        case '/pages/meu-negocio/recebimentos/ordens-bancarias':
          this.visao = 'Recebimentos';
          this.ajusteTrackingName('Recebimentos');
          this.showPlus = false;
          break;
      case '/pages/meu-negocio/recebimentos/ted-doc':
        this.visao = 'Recebimentos';
        this.ajusteTrackingName('Recebimentos');
        this.showPlus = false;
        break;
      case '/pages/meu-negocio/despesas/cartao':
        this.visao = 'Despesas';
        this.ajusteTrackingName('Despesas');
        this.showPlus = false;
        break;
      case '/pages/meu-negocio/despesas/boleto':
        this.visao = 'Despesas';
        this.ajusteTrackingName('Despesas');
        this.showPlus = false;
        break;
      case '/pages/meu-negocio/despesas/pix':
        this.visao = 'Despesas';
        this.ajusteTrackingName('Despesas');
        this.showPlus = false;
        break;
      case '/pages/meu-negocio/despesas/ted-doc':
        this.visao = 'Despesas';
        this.ajusteTrackingName('Despesas');
        this.showPlus = false;
        break;
      case '/pages/meu-negocio/despesas/operacoes-credito':
        this.visao = 'Despesas';
        this.ajusteTrackingName('Despesas');
        this.showPlus = false;
        break;
      case '/pages/meu-negocio/despesas/tarifas':
        this.visao = 'Despesas';
        this.ajusteTrackingName('Despesas');
        this.showPlus = false;
        break;
      case '/pages/meu-negocio/fluxo-caixa':
        this.visao = 'Fluxo de Caixa';
        this.showPlus = false;
        break;
      case '/pages/cartoes/minhas-vendas':
        this.visao = 'Conciliadora de cartões';
        this.showPlus = true;
        break;
      case '/pages/cartoes/minhas-vendas/pix':
        this.visao = 'Conciliadora de cartões';
        this.showPlus = true;
        break;
      case '/pages/cartoes/minhas-vendas/cartao':
        this.visao = 'Conciliadora de cartões';
        this.showPlus = true;
        break;
      case '/pages/cartoes/meu-plano':
        this.visao = 'Conciliadora de cartões';
        this.showPlus = true;
        break;
      case '/pages/cartoes/meu-plano/formulario-contratacao':
        this.visao = 'Conciliadora de cartões';
        this.showPlus = true;
        break;
      case '/pages/cartoes/meu-plano/contrato-sucesso':
        this.visao = 'Conciliadora de cartões';
        this.showPlus = true;
        break;
      case '/pages/cartoes/meu-plano/alterar-plano':
        this.visao = 'Conciliadora de cartões';
        this.showPlus = true;
        break;
      case '/pages/cartoes/meu-plano/cancelar-plano':
        this.visao = 'Conciliadora de cartões';
        this.showPlus = true;
        break;
      case '/pages/cartoes/meu-plano/duvidas-frequentes':
        this.visao = 'Conciliadora de cartões';
        this.showPlus = true;
        break;
      case '/pages/cartoes/meu-plano/painel-acompanhamento':
        this.visao = 'Conciliadora de cartões';
        this.showPlus = true;
        break;
      case '/pages/cartoes/meu-plano/painel-acompanhamento/incluir-adquirentes':
        this.visao = 'Conciliadora de cartões';
        this.showPlus = true;
        break;
      case '/pages/cartoes/meu-plano/painel-acompanhamento/autorizar-adquirente':
        this.visao = 'Conciliadora de cartões';
        this.showPlus = true;
        break;
      case '/pages/recomendacoes':
        this.visao = 'Recomendações da ARI';
        this.ajusteTrackingName('Recomendacoes');
        this.showPlus = false;
        break;
      case '/pages/extrato/conta-corrente':
        this.visao = 'Conta Corrente';
        this.ajusteTrackingName('ExtratoCorrente');
        this.showPlus = false;
        break;
      case '/pages/extrato/cartao-credito':
        this.visao = 'Cartão de Crédito';
        this.ajusteTrackingName('ExtratoCartao');
        this.showPlus = false;
        break;
      case '/pages/servicos':
        this.visao = 'Serviços';
        this.ajusteTrackingName('Services');
        this.showPlus = false;
        break;
      case '/pages/marketplace':
        this.visao = 'Marketplace';
        this.showPlus = false;
        break;
      case '/pages/relatorios':
        this.visao = 'Relatórios';
        this.showPlus = false;
        break;
      case '/pages/autorizacoes':
        this.visao = 'Open Finance';
        this.ajusteTrackingName('open-finance');
        this.showPlus = false;
        break;
      default:
        this.visao = '';
        this.ajusteTrackingName('Home');
        this.showPlus = false;
        break;
    }
  }

  ajusteTrackingName(page: string) {
    this.TRACK = {
      category: 'Interacoes',
      action: 'click',
      name: {
        menu: `${page}||Menu||Conciliadora`,
        subMenus: {
          meuPlano: `${page}||Menu||Conciliadora||MeuPlano`,
          minhasVendas: `${page}||Menu||Conciliadora||MinhasVendas`,
        },
        recomendacoes: `${page}||Recomendacoes||Conciliadora||Recomendacoes`
      },
      value: 1
    };
  }

  toogleShowDisclaimer(e) {
    this.showDisclaimer = e;
  }


  initFiltroTemplate(user: User): void {
    this.user = user;
    this.setSymbolUser();
    user.empresas.sort(this.orderFn);
    this.estabelecimentos = user.empresas;
    this.empresa = Object.assign({}, this.estabelecimentos[0]);
  }

  orderFn(a: any, b: any): number {
    if (a.nomeFantasia > b.nomeFantasia) {
      return 1;
    }
    return b.nomeFantasia > a.nomeFantasia ? -1 : 0;
  }

  setSymbolUser() {
    const splitNome = this.user.nome.split(' ');
    this.user.logo = '';
    splitNome.forEach((element) => {
      this.user.logo += element.substr(0, 1);
    });
  }

  logout() {
    this.authService.logout();
  }

  fecharPesquisa(): void {
    this.closedPesquisa++;
  }

  validatePlano(statusPlano: StatusDoContratoModel): void {
    this.planoAtivo = statusPlano.existeContratoVigente;
    this.temPlano =
      statusPlano.existeContratoVigente ||
      statusPlano.existeContratoCancelado ||
      statusPlano.existePagamentoEmAtraso;
  }
}
