import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-info-agenda-cartao',
  templateUrl: './info-agenda-cartao.component.html',
  styleUrls: ['./info-agenda-cartao.component.css'],
})
export class InfoAgendaCartaoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  abrirApj() {
    window
      .open(environment.aapjBaseUrl.concat('/apf-apj-acesso'), '_blank')
      .focus();
  }
}
