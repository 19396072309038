import { Component, Input, OnChanges } from '@angular/core';
import { IGraficoCaixa, IVisaoGeral } from '../../interfaces/visao-geral';
import * as Highcharts from 'highcharts';

interface IGraficoCaixaDia {
  tutilo: string;
  totalRecebimentos: number | BigInteger;
  totalDespesas: number | BigInteger;
  legendas: string[];
  percentuais: Array<number | BigInteger>;
  valores: Array<number | BigInteger>;
  cores: string[];
}

@Component({
  selector: 'app-bloco-caixa-diario',
  templateUrl: './bloco-caixa-diario.component.html',
  styleUrls: [
    './bloco-caixa-diario.component.css',
    '../../visao-geral.component.css',
  ],
})
export class BlocoCaixaDiarioComponent implements OnChanges {
  @Input() data: IGraficoCaixa;
  erroConsultaGrafico: boolean;
  dadosGrafico: IGraficoCaixa;
  graficoCaixa: IGraficoCaixaDia;
  highcharts: any;
  chartOptions: any;
  dataAtual: Date = new Date();

  constructor() {
    this.highcharts = Highcharts;
    this.erroConsultaGrafico = false;
  }

  ngOnChanges(): void {
    this.dadosGrafico = this.data || null;
    this.dataAtual = this?.data?.data || new Date();

    this.erroConsultaGrafico = false;
    this.graficoCaixa = this.montarGrafico();
    this.exibirGrafico();
  }

  private montarGrafico(): any {
    if (!this.dadosGrafico) {
      this.erroConsultaGrafico = true;
      return;
    }

    return {
      titulo: 'Caixa do dia',
      totalRecebimentos: this.dadosGrafico.totalRecebimentos,
      totalDespesas: this.dadosGrafico.totalDespesas,
      legendas: ['Recebimentos', 'Despesas'],
      percentuais: [
        this.dadosGrafico.pecentualRecebimentosBbOpf,
        this.dadosGrafico.pecentualDespesasBbOpf,
      ],
      valores: [
        this.dadosGrafico.totalRecebimentos,
        this.dadosGrafico.totalDespesas,
      ],
      cores: ['#465eff', '#f97a70'],
    };
  }

  exibirGrafico() {
    if (!this.graficoCaixa) {
      this.erroConsultaGrafico = true;
      return;
    }

    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: { text: '' },
      exporting: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -75,
            y: -5,
            format: '{y}',
            style: { fontWeight: 'bold', color: 'black', fontSize: '14px' },
            filter: { property: 'name', operator: '===' },
          },
          borderWidth: 3,
        },
        series: { animation: true },
      },
      series: [
        {
          type: 'pie',
          name: '',
          innerSize: '50%',
          data: [
            {
              name: this.graficoCaixa.legendas[0],
              y: this.graficoCaixa.percentuais[0],
              color: this.graficoCaixa.cores[0],
              value: this.graficoCaixa.valores[0],
            },
            {
              name: this.graficoCaixa.legendas[1],
              y: this.graficoCaixa.percentuais[1],
              color: this.graficoCaixa.cores[1],
              value: this.graficoCaixa.valores[1],
            },
          ],
        },
      ],
      tooltip: {
        pointFormat: '<strong>{point.percentage:.2f} % / R$ {point.value:.2f}</strong>',
      },
    };
  }

  verificaSeDeveExibirGrafico() {
    return (
      this.dadosGrafico &&
      (this.dadosGrafico?.totalRecebimentos !== 0 ||
        this.dadosGrafico?.totalDespesas !== 0)
    );
  }
}
