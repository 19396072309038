import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-arrecadacao-doacoes',
  templateUrl: './modal-arrecadacao-doacoes.component.html',
  styleUrls: ['./modal-arrecadacao-doacoes.component.css']
})
export class ModalArrecadacaoDoacoesComponent implements OnInit {

  @Input() show: boolean;
  @Output() closedModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }
  isModalDoacoesEnabled: boolean;
  ngOnInit(): void {
    this.isModalDoacoesEnabled = true;
    this.closedModal.emit(true);
  }

  closed() {
    this.closedModal.emit(false);
  }

}
