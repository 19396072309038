import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Saldo } from './../../models/saldo';
import { PagesService } from './../../../../shared/services/pages.service';
import { Component, OnChanges, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Banco } from '../../models/banco';

interface IContasArmazenadasOPF {
  codigoBanco: number;
  nomeBanco: string;
  numeroAgencia: number;
  numeroConta: number;
  digitoConta: number;
  saldo: number;
}

@Component({
  selector: 'app-bloco-saldo-bancario',
  templateUrl: './bloco-saldo-bancario.component.html',
  styleUrls: ['./bloco-saldo-bancario.component.css'],
})
export class BlocoSaldoBancarioComponent implements OnChanges, OnInit {
  static readonly porcentagemPorConta = 8;

  @BlockUI('bui-bloco-saldo-bancario') buiBlocoSaldoBancario: NgBlockUI;

  listaSaldos: Saldo[] = new Array<Saldo>();
  params: any;
  listaDados: any[] = new Array<any>();

  hightChartsGraficoSaldo: any;
  chartOptionsGraficoSaldo: any;
  apresentaGrafico: boolean;
  saldoTotal = 0.0;
  updateFlag = false;
  coresOriginais: any;
  corOriginal: string;
  contaSelecionada = '';
  porcentagemGrafico: number;

  constructor(
    private pagesService: PagesService,
    private mensagemService: ToastrService
  ) {
    this.hightChartsGraficoSaldo = Highcharts;
    this.prepararDadosGrafico(this.iniciarGraficoVazio());
  }

  ngOnChanges(): void {
    this.getListaSaldos();
  }

  iniciarGraficoVazio() {
    return [
      {
        banco: '',
        nomeBanco: '',
        agencia: '',
        conta: '',
        saldo: 0,
      },
    ];
  }

  getListaSaldos() {
    this.apresentaGrafico = false;
    this.listaDados = [];
    this.listaSaldos = [];

    this.prepararDadosGrafico(this.iniciarGraficoVazio());

    this.pagesService.getListaSaldos(this.buiBlocoSaldoBancario).subscribe(
      (result) => {
        if (
          result.dados !== undefined &&
          result.dados !== null &&
          result?.dados.length > 0
        ) {
          result.dados.forEach((contaBB) => {
            contaBB.nomeBanco = 'Banco do Brasil';
          });

          this.prepararDadosGrafico(result.dados);

          this.getSaldosBancoExterno(result.dados);
        } else {
          this.getSaldosBancoExterno([]);
        }
      },
      (error) => {
        this.mensagemService.error(error.erros[0].message);
        this.getSaldosBancoExterno([]);
      }
    );
  }

  getSaldosBancoExterno(saldosContasBB: Saldo[]) {
    this.pagesService
      .getListaSaldoContasExternas(this.buiBlocoSaldoBancario)
      .subscribe((result) => {
        if (
          result !== undefined &&
          result != null &&
          result.dados !== undefined &&
          result.dados != null &&
          result.dados.length > 0
        ) {
          result.dados.forEach((conta: IContasArmazenadasOPF) => {
            saldosContasBB.push({
              banco: conta.codigoBanco.toString(),
              nomeBanco: conta.nomeBanco,
              agencia: conta.numeroAgencia.toString(),
              conta: conta.numeroConta + '-' + conta.digitoConta,
              saldo: conta.saldo,
            });
          });
          this.prepararDadosGrafico(saldosContasBB);
        }
      });
  }

  prepararDadosGrafico(listaSaldos: Saldo[]) {
    this.listaSaldos = listaSaldos;
    this.saldoTotal = 0;
    this.listaDados = [];
    this.listaSaldos.forEach((element, index) => {
      const data = {
        name: `${element.agencia} / ${element.conta}`,
        y: element.saldo,
      };

      this.saldoTotal += Number(element.saldo);
      this.listaDados.push(data);
    });

    this.definirPorcentagemGrafico(this.listaSaldos.length);
    this.createGraficoSaldo();
  }

  createGraficoSaldo() {
    this.hightChartsGraficoSaldo = Highcharts;

    this.chartOptionsGraficoSaldo = {
      chart: {
        type: 'column',
        height: this.porcentagemGrafico + '%',
      },
      title: false,
      credits: { enabled: false },
      subtitle: false,
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: 'category',
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        title: false,
        opposite: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:.1f}%',
          },
          point: {
            events: {
              mouseOver() {
                this.contaSelecionada = this.options.name;
                this.coresOriginais = new Array();
                this.corOriginal = this.color;
                this.series.data.map((element) => {
                  this.coresOriginais.push(element.color);
                  element.color = '#E4EEF6';
                });

                const contasListadas =
                  document.getElementsByClassName('account-color-icon');

                for (let i = 0; i < contasListadas.length; i++) {
                  const conta = contasListadas[i];

                  if (
                    conta.id === this.contaSelecionada.replace(' / ', '').trim()
                  ) {
                    conta.classList.add('account-active');
                  } else {
                    conta.classList.add('account-inactive');
                  }
                }

                this.update({
                  color: '#2673B3',
                });
              },
              mouseOut() {
                this.contaSelecionada = '';
                this.series.data.map((element, index) => {
                  element.color = this.coresOriginais[index];
                });

                const contasListadas =
                  document.getElementsByClassName('account-color-icon');

                for (let i = 0; i < contasListadas.length; i++) {
                  const conta = contasListadas[i];

                  conta.classList.remove('account-active');
                  conta.classList.remove('account-inactive');
                }

                this.update({
                  color: this.corOriginal,
                });
              },
            },
          },
        },
      },
      colors: ['#004D9E', '#2673B3', '#9FC2DF', '#E4EEF6'],
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color: #444444">{point.name}</span>',
      },

      series: [
        {
          name: '<span style="color:  #2673b3"></span>',
          colorByPoint: true,
          data: this.listaDados,
        },
      ],
    };
  }

  isContaSelecionada(agencia: string, conta: string) {
    return this.contaSelecionada === agencia + ' / ' + conta;
  }

  isContaNaoSelecionada(agencia: string, conta: string) {
    const dadosConta = agencia + ' / ' + conta;
    return this.contaSelecionada !== dadosConta && this.contaSelecionada !== '';
  }

  selectGraphicByAccount(agencia: string, conta: string) {
    this.ajustarCoresApresentacaoBarras(agencia, conta);
  }

  ajustarCoresApresentacaoBarras(agencia: string, conta: string) {
    this.contaSelecionada = agencia + ' / ' + conta;
    const dadosGrafico = this.chartOptionsGraficoSaldo.series[0].data;
    this.coresOriginais = new Array();

    dadosGrafico.map((element) => {
      if (element.name.trim() === this.contaSelecionada.trim()) {
        element.color = '#2673B3';
      } else {
        element.color = '#E4EEF6';
      }
      this.coresOriginais.push(element.color);
    });
    this.updateFlag = true;
  }

  retornarPadraoGrafico() {
    const dadosGrafico = this.chartOptionsGraficoSaldo.series[0].data;
    this.coresOriginais = new Array();
    dadosGrafico.map((element, index) => {
      element.color = this.coresOriginais[index];
    });
    this.contaSelecionada = '';
    this.updateFlag = true;
  }

  private definirPorcentagemGrafico(quantidadeContas: number) {
    this.porcentagemGrafico = 0;

    this.porcentagemGrafico =
      BlocoSaldoBancarioComponent.porcentagemPorConta * (quantidadeContas + 1);
    if (this.porcentagemGrafico > 100) {
      this.porcentagemGrafico = 100;
    }
  }

  ngOnInit(): void {
    this.getListaSaldos();
  }
}
