import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/merge/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((ref) => {
    const ngRef = 'ngRef';
    // Ensure Angular destroys itself on hot reloads.
    if (window[ngRef]) {
      window[ngRef].destroy();
    }
    window[ngRef] = ref;

    // Otherwise, log the boot error
  })
  .catch((err) => console.error(err));
