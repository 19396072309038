import { Component, OnInit } from '@angular/core';
import { Empresa } from 'gnc-merge-common-ng/dist/merge-common-ng';
import { DataInputComRegra } from 'gnc-merge-common-ng/projects/merge-common-ng/src/lib/components/datepicker-custom-enum/data-input';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';

import { IFiltroDatas } from '../../../../../shared/common/interfaces/filtro-datas';
import { TimestampBehaviourService } from '../../../../../shared/services/timestamp-behaviour.service';
import { TemplateService } from '../../../../template/template.service';
import {
  AlignEnum,
  ColumnDetail,
  FormatEnum,
  TypeEnum,
} from './../../../../../shared/common/components/data-table/interfaces/column-detail';
import { TarifaService } from './../../../../../shared/services/tarifa.service';
import { ConsultaTarifaDto } from './../../models/consulta-tarifa-dto';
import { DadosTarifaDto } from './../../models/dados-tarifa-dto';
import { Tarifa } from './../../models/tarifa';

interface IResumoPaginacao {
  de: number;
  ate: number;
  total: number;
}

@Component({
  selector: 'app-tarifa',
  templateUrl: './tarifa.component.html',
  styleUrls: ['./tarifa.component.css'],
})
export class TarifaComponent implements OnInit {
  estabelecimento: Empresa;
  filtroDatas: IFiltroDatas;
  dataSelecionada: DataInputComRegra;
  resumoPaginacao: IResumoPaginacao;
  @BlockUI('bui-tarifas') buiBlocoPix: NgBlockUI;

  dadosTarifa: DadosTarifaDto;
  listaCobrancaFiltrada: Tarifa[];

  defaultDataSort = 'dataEfetivacaoCobrancaTarifa';
  columnsDetails: ColumnDetail[] = [
    {
      dataKey: 'dataEfetivacaoCobrancaTarifa',
      header: 'DATA',
      type: TypeEnum.DATE,
      align: AlignEnum.LEFT,
      format: FormatEnum.DATEBR,
    },
    {
      dataKey: 'numeroContaCorrenteDebito',
      header: 'No. CONTA',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'textoDescricaoTarifa',
      header: 'DESCRIÇÃO',
      type: TypeEnum.STRING,
      align: AlignEnum.LEFT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'quantidadeTarifaCobrado',
      header: 'QUANTIDADE',
      type: TypeEnum.NUMBER,
      align: AlignEnum.RIGHT,
      format: FormatEnum.NONE,
    },
    {
      dataKey: 'valorOperacaoCobrancaTarifa',
      header: 'VALOR',
      type: TypeEnum.CURRENCY,
      align: AlignEnum.RIGHT,
      format: FormatEnum.CURRENCYBR,
    },
  ];

  constructor(
    private tarifaService: TarifaService,
    private mensagemService: ToastrService,
    private timestampService: TimestampBehaviourService,
    private templateService: TemplateService
  ) {
    this.estabelecimento = null;

    this.dadosTarifa = {
      listaCobranca: [],
      quantidadeOcorrencia: 0,
      valorTotal: 0,
    };
  }

  ngOnInit() {
    this.timestampService
      .getCalendarioSubject()
      .subscribe((data) => this._setFiltroDatas(data));
    this.templateService
      .getEstabelecimentoSubject()
      .subscribe((est) => (this.estabelecimento = est));
    if (this.estabelecimento) {
      this.listarTarifas();
    }
    this.timestampService.updateRegraCalendario();
  }

  datesSelectedToViews(event) {
    this.filtroDatas = this.timestampService.translateDateForEvent(event);
    this.timestampService.tratarDatapicker(this.filtroDatas);

    this.listarTarifas();
  }

  private _setFiltroDatas(data: DataInputComRegra): void {
    this.dataSelecionada = data;
    this.filtroDatas = this.timestampService.translateDateForInput(data);
  }

  listarTarifas(): void {
    const input: ConsultaTarifaDto = {
      dataInicioPesquisa: this.filtroDatas.dataInicial.toLocaleString('pt'),
      dataFimPesquisa: this.filtroDatas.dataFinal.toLocaleString('pt'),
    };

    this.tarifaService.listar(input, this.buiBlocoPix).subscribe(
      (resposta) => {
        this.dadosTarifa = resposta.dados;
      },
      (error) => {
        this.mensagemService.error(error.erros[0].message);
      }
    );
  }
}
