import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AutorizacoesOpfService } from './autorizacoes-opf.service';

import { AutorizacoesOpf } from './dto/autorizacoes-opf';
import { AutorizacaoOpf } from './dto/autorizacao-opf';

@Component({
  selector: 'app-autorizacoes-opf',
  templateUrl: './autorizacoes-opf.component.html',
  styleUrls: ['./autorizacoes-opf.component.css'],
})
export class AutorizacoesOpfComponent implements OnInit {
  listaAutorizacoes: AutorizacaoOpf[] = [];

  constructor(
    private autorizacoesOpfService: AutorizacoesOpfService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.autorizacoesOpfService.listarAutorizacoesOpf().subscribe(
      (response) => {
        if (response != null) {
          this.listaAutorizacoes = response.dados;
        }
      },
      (error) => {
        this.toastService.error(
          'Não foi possível carregar a lista de autorizações.'
        );
      }
    );
  }
}
