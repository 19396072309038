import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BannerConciliadoraComponent} from './banner-conciliadora.component';
import {RouterModule} from '@angular/router';
import {NgxMatomoTrackerModule} from '@ngx-matomo/tracker';

@NgModule({
  declarations: [BannerConciliadoraComponent],
  imports: [CommonModule, RouterModule, NgxMatomoTrackerModule],
  exports: [BannerConciliadoraComponent]
})

export class BannerConciliadoraModule {
}
