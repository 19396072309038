import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TemplateRoutingModule } from './template-routing.module';
import { TemplateComponent } from './template.component';
import { RouterModule } from '@angular/router';
import { TemplateService } from './template.service';
import { AceiteTermosModule } from './components/aceite-termos/aceite-termos.module';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { NotificacoesModule } from './components/notificacoes/notificacoes.module';
import { ConviteOpenBankingModule } from './components/convite-open-banking/convite-open-banking.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PesquisaNpsComponent } from './components/pesquisa-nps/pesquisa-nps.component';
import { ModalArrecadacaoDoacoesComponent } from './components/modal-arrecadacao-doacoes/modal-arrecadacao-doacoes.component';
import { FormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';
import {NgxMatomoTrackerModule} from '@ngx-matomo/tracker';

@NgModule({
  declarations: [TemplateComponent, BottomSheetComponent, PesquisaNpsComponent, ModalArrecadacaoDoacoesComponent],
  imports: [
    CommonModule,
    TemplateRoutingModule,
    RouterModule,
    AceiteTermosModule,
    NotificacoesModule,
    ConviteOpenBankingModule,
    MatDialogModule,
    BlockUIModule.forRoot(),
    FormsModule,
    NgxMatomoTrackerModule

  ],
  providers: [TemplateService],
  exports: [TemplateComponent, BottomSheetComponent],
  entryComponents: [PesquisaNpsComponent]
})
export class TemplateModule {}
